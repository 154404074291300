import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DebounceCall } from '@rxap/utilities';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorMessageComponent } from './http-error-message/http-error-message.component';
import { ConfigService } from '@rxap/config';

@Injectable()
export class ErrorDialogInterceptor implements HttpInterceptor {

  private readonly baseUrlList: string[] = [];

  constructor(
    @Inject(MatDialog)
    private readonly dialog: MatDialog,
    private readonly config: ConfigService,
  ) {
    const api = this.config.get<Record<string, { baseUrl?: string }> & { baseUrl: string }>('api', {} as any);
    if (api.baseUrl) {
      this.baseUrlList.push(api.baseUrl);
    }
    for (const [ serverId, config ] of Object.entries(api)) {
      if (config && typeof config === 'object') {
        const baseUrl = config.baseUrl;
        if (baseUrl) {
          this.baseUrlList.push(baseUrl);
        }
      }
    }
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(req).pipe(
      tap({
        error: async (error) => {
          if (error instanceof HttpErrorResponse) {
            if (this.baseUrlList.some(url => error.url?.includes(url))) {
              await this.showDialog(req, error);
            }
          }
        }
      }),
    );
  }

  @DebounceCall(500)
  private async showDialog(req: HttpRequest<unknown>, event: HttpErrorResponse) {

    let error = event.error;
    if (event.error instanceof Blob) {
      error = await event.error.text()
      try {
        error = JSON.parse(error);
      } catch (e) {
        console.warn('error response object is not a json');
      }
    }

    const message: string | undefined = error?.message ?? event.message;

    const match = message?.match(/e_(\d+)#/);

    if (message && match) {
      const fragment = message.replace(/e_\d+#/, '');
      let userMessage = fragment;
      try {
        const userMessageObject = JSON.parse(fragment);
        userMessage = userMessageObject.message ?? userMessage;
      } catch (e) {}
      this.dialog.open(ErrorMessageComponent, {
        data: {
          message: userMessage,
          code: match[1],
        },
      });
    } else if (isDevMode() && event.error && typeof event.error !== 'string') {
      this.dialog.open(HttpErrorMessageComponent, {
        data: {
          method: req.method,
          body: req.body,
          status: event.status,
          url: event.url,
          headers: event.headers,
          message,
          ...event.error,
        },
      });
    } else {
      this.dialog.open(ErrorMessageComponent, { data: { message: (message ?? 'Unknown').toString(), code: '-1' } })
    }

  }

}
