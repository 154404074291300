import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchalterWidgetComponent } from './schalter-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { ToBooleanPipeModule } from '../utilities/to-boolean.pipe';
import { SetWidgetDataDirectiveModule } from '../set-widget-data.directive';
import { BgColorPipe } from './bg-color.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SchalterWidgetComponent,
    BgColorPipe,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    ToBooleanPipeModule,
    SetWidgetDataDirectiveModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  exports: [
    SchalterWidgetComponent,
  ],
})
export class SchalterWidgetComponentModule {
}
