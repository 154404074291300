<div class="segment" fxLayout="column">
  <div (click)="toggleExpand()" [ngClass]="{ expanded: expanded }" class="header" fxFlex="nogrow" fxLayout="row"
       fxLayoutAlign="start center" fxLayoutGap="24px">
    <mat-icon *ngIf="expanded">expand_less</mat-icon>
    <mat-icon *ngIf="!expanded">expand_more</mat-icon>
    <span class="title">{{segment.title || 'Untitled segment'}}</span>
  </div>
  <div [fxShow]="expanded" fxFlex="nogrow">
    <table>
      <tr *ngFor="let row of segment.body.rowList; trackBy: rowListTrackBy">
        <td *ngFor="let cell of row.cellList; trackBy: cellListTrackBy"
            [colSpan]="cell.colspan"
            [ngStyle]="{ width: cell | cellWidth:segment.columns }"
            [rowSpan]="cell.rowspan">
          <eurogard-cell-content [cell]="cell" class="content"></eurogard-cell-content>
        </td>
        <td class="empty"></td>
      </tr>
    </table>
  </div>
</div>
