export interface ErrorConstructor {
  captureStackTrace(thisArg: any, func: any): void;
}

export class XmlParserError extends Error {
  constructor(message: string) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if ((Error as any)['captureStackTrace']) {
      (Error as any)['captureStackTrace'](this, XmlParserError);
    }

    this.name = 'XmlParserError';
  }
}

export class XmlParserValidateError extends XmlParserError {

  constructor(message: string, elementTag: string, attribute?: string) {
    super(message);

    if ((Error as any)['captureStackTrace']) {
      (Error as any)['captureStackTrace'](this, XmlParserValidateError);
    }

    this.name = 'XmlParserValidateError';
  }

}
