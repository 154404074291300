import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { UserControllerUpdateUserLanguageRequestBody } from '../request-bodies/user-controller-update-user-language.request-body';
import { UserControllerUpdateUserLanguageResponse } from '../responses/user-controller-update-user-language.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'user-controller-updateUserLanguage',
    operation: '{"operationId":"user-controller-updateUserLanguage","requestBody":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/Update"}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/User"}}}}},"method":"put","path":"/user-controller/user-language"}'
  })
export class UserControllerUpdateUserLanguageRemoteMethod extends OpenApiRemoteMethod<UserControllerUpdateUserLanguageResponse, void, UserControllerUpdateUserLanguageRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, UserControllerUpdateUserLanguageRequestBody>): Promise<UserControllerUpdateUserLanguageResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardUserControllerUpdateUserLanguageRemoteMethod]',
    exportAs: 'eurogardUserControllerUpdateUserLanguageRemoteMethod'
  })
export class UserControllerUpdateUserLanguageRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<UserControllerUpdateUserLanguageResponse, OpenApiRemoteMethodParameter<void, UserControllerUpdateUserLanguageRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerUpdateUserLanguageRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, UserControllerUpdateUserLanguageRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerUpdateUserLanguageRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerUpdateUserLanguageRemoteMethod) remoteMethod: UserControllerUpdateUserLanguageRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<UserControllerUpdateUserLanguageResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ UserControllerUpdateUserLanguageRemoteMethodTemplateDirective ],
    exports: [ UserControllerUpdateUserLanguageRemoteMethodTemplateDirective ]
  })
export class UserControllerUpdateUserLanguageRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardUserControllerUpdateUserLanguageRemoteMethod]',
    exportAs: 'eurogardUserControllerUpdateUserLanguageRemoteMethod'
  })
export class UserControllerUpdateUserLanguageRemoteMethodDirective extends RemoteMethodDirective<UserControllerUpdateUserLanguageResponse, OpenApiRemoteMethodParameter<void, UserControllerUpdateUserLanguageRequestBody>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerUpdateUserLanguageRemoteMethod) remoteMethod: UserControllerUpdateUserLanguageRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ UserControllerUpdateUserLanguageRemoteMethodDirective ],
    exports: [ UserControllerUpdateUserLanguageRemoteMethodDirective ]
  })
export class UserControllerUpdateUserLanguageRemoteMethodDirectiveModule {
}
