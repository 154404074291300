import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  EingabefeldSetpointsWidget,
  ISetWidgetDataMethod,
  WidgetComponent,
  WidgetData,
  WidgetRef,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isDefined } from '@rxap/utilities/rxjs';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-eingabefeld-setpoints-widget',
  templateUrl: './eingabefeld-setpoints-widget.component.html',
  styleUrls: [ './eingabefeld-setpoints-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-eingabefeld-setpoints-widget'},
})
export class EingabefeldSetpointsWidgetComponent implements WidgetComponent, OnInit, OnDestroy {

  public value: string | null = null;
  public color: string | null = null;
  public ref?: WidgetRef;
  @Input()
  public name: string | null = null;
  private _subscription?: Subscription;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: EingabefeldSetpointsWidget;

  @Input()
  public set content(content: EingabefeldSetpointsWidget) {
    this._content = content;
    this.color = this._content.color;
    this.ref = this._content.ref;
  }

  public ngOnInit() {
    this._subscription = this.dataSource.connect({id: 'on-init'}).pipe(
      isDefined(),
      tap(data => this.value = data.value),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

}
