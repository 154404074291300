import { Injectable } from '@angular/core';
import { OpenApiDataSource, RxapOpenApiDataSource } from '@rxap/open-api/data-source';
import { CompanyControllerGetAllParameter } from '../parameters/company-controller-get-all.parameter';
import { CompanyControllerGetAllResponse } from '../responses/company-controller-get-all.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiDataSource('company-controller-getAll')
export class CompanyControllerGetAllDataSource extends OpenApiDataSource<CompanyControllerGetAllResponse, CompanyControllerGetAllParameter> {
}
