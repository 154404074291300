import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  serviceWorker: true,
  sentry: {
    enabled: true,
    debug: false,
  },
};
