import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextListWidgetComponent } from './text-list-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { DetermineTextPipe } from './determine-text.pipe';
import { AutoFontSizeDirectiveModule } from '../utilities/auto-font-size.directive';

@NgModule({
  declarations: [
    TextListWidgetComponent,
    DetermineTextPipe,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    AutoFontSizeDirectiveModule,
  ],
  exports: [
    TextListWidgetComponent,
  ],
})
export class TextListWidgetComponentModule {
}
