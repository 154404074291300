import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-user-settings',
  operationId: 'UserSettingsController_toggleHideRouter',
  operation: '{"operationId":"UserSettingsController_toggleHideRouter","parameters":[],"responses":{"201":{}},"method":"post","path":"/user/hide-router/toggle"}',
})
export class UserSettingsControllerToggleHideRouterRemoteMethod extends OpenApiRemoteMethod<void, void, void> {
  public override call(): Promise<void> {
    return super.call();
  }
}
