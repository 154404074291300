<button [matMenuTriggerFor]="menu"
        [name]="(userName$ | async) ?? ''"
        class="profile-icon"
        fxLayout="row"
        fxLayoutAlign="center center">
  <mat-icon class="avatar-icon">account_circle</mat-icon>
</button>

<mat-menu #menu="matMenu" [yPosition]="'below'">
  <ng-template [ngIf]="user$ | async" let-user>
    <button mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span>{{user.username}}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>login</mat-icon>
      <span i18n>Ausloggen</span>
    </button>
  </ng-template>
</mat-menu>
