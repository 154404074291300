import { NgModule } from '@angular/core';
import { GaugeWidgetComponent } from './gauge-widget.component';
import { GaugeModule } from './gauge/gauge.module';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { ToNumberPipeModule } from '../utilities/to-number.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToFixedPipeModule } from '../utilities/to-fixed.pipe';

@NgModule({
  declarations: [
    GaugeWidgetComponent,
  ],
  imports: [
    GaugeModule,
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    ToNumberPipeModule,
    FlexLayoutModule,
    ToFixedPipeModule,
  ],
  exports: [
    GaugeWidgetComponent,
  ],
})
export class GaugeWidgetComponentModule {
}
