import { ComponentFactoryResolver, Injectable, Injector, isDevMode, ViewContainerRef } from '@angular/core';
import { DashboardWidgetConfig, Widget, WidgetComponent, WidgetData, WidgetRef } from '@digitaix/eurogard-utilities';
import { pipeDataSource, staticDataSource } from '@rxap/data-source';
import { distinctUntilChanged, filter, map, pairwise, shareReplay, startWith } from 'rxjs/operators';
import {
  CellInfo,
  WIDGET_CELL,
  WIDGET_CELL_INFO,
  WIDGET_DATA_LAST_CHANGE,
  WIDGET_DATA_SOURCE,
} from '@digitaix/eurogard-dashboard-widget';
import { ComponentType } from '@angular/cdk/overlay';
import { ConfigService } from '@rxap/config';
import { WidgetDataDataSource } from '@digitaix/eurogard-dashboard-render-engine';
import { CellElement } from '@digitaix/eurogard-dashboard-xml-parser';

/**
 * Wi
 */
@Injectable({ providedIn: 'root' })
export class WidgetDisplayService {

  constructor(
    private readonly config: ConfigService,
  ) {
  }

  public getWidgetConfig(type: string): DashboardWidgetConfig | null {
    const widgetList = this.config.get<DashboardWidgetConfig[]>('dashboard.widgets');
    const widget = widgetList?.find(widget => widget.type === type);
    if (isDevMode()) {
      console.log('widget config: ', widget);
    }
    return widget ?? null;
  }

  public async updateContentComponent(
    widget: Widget,
    vcr: ViewContainerRef,
    widgetData: WidgetDataDataSource,
    parentInjector: Injector,
    cfr: ComponentFactoryResolver,
    cellInfo: CellInfo | null = null,
    cell: CellElement | null  = null,
  ) {
    vcr.clear();
    let componentCtor = await this.getComponentCtor(widget.type);

    let widgetDataSource: WidgetComponent['dataSource'];

    const ref: WidgetRef | WidgetRef[] | undefined = widget.content.ref;

    let widgetName = widget.name;

    if (ref) {
      if (Array.isArray(ref)) {
        widgetDataSource = pipeDataSource<WidgetData[], WidgetData[]>(
          widgetData,
          map(widgetDataList => ref.map(r => widgetDataList.find(widgetData => widgetData.uuid === r.dataDefinition && widgetData.machine === r.machine)).filter(Boolean)),
          distinctUntilChanged((a: WidgetData[], b: WidgetData[]) => {
            if (a.length !== b.length) {
              return false;
            }
            return a.every((value, index) => value.value === b[index].value);
          }),
          shareReplay(1),
        );
      } else {
        widgetDataSource = pipeDataSource<WidgetData[], WidgetData>(
          widgetData,
          map(widgetDataList => widgetDataList.find(widgetData => widgetData.uuid === ref.dataDefinition && widgetData.machine === ref.machine)),
          filter(widgetData => widgetData !== undefined),
          distinctUntilChanged((a: WidgetData, b: WidgetData) => a.value === b.value),
          startWith(null),
          pairwise(),
          map(([ last, current ]) => ({
            ...current,
            lastValue: last?.value ?? null,
          })),
          shareReplay(1),
        );
      }
    } else {
      widgetDataSource = staticDataSource<null>(null);
      if (!this.getWidgetConfig(widget.type)?.allowEmptyRef) {
        componentCtor = await this.getComponentCtor('error-message');
        widgetName = $localize`The widget ref is not defined! Select a widget ref in the editor.!`;
      }
    }

    const componentFactory = cfr.resolveComponentFactory(componentCtor);

    const injector = Injector.create({
      parent: parentInjector,
      providers: [
        {
          provide: WIDGET_DATA_SOURCE,
          useValue: widgetDataSource,
        },
        {
          provide: WIDGET_DATA_LAST_CHANGE,
          useValue: widgetData.lastChange$,
        },
        {
          provide: WIDGET_CELL_INFO,
          useValue: cellInfo,
        },
        {
          provide: WIDGET_CELL,
          useValue: cell,
        },
      ],
    });

    const componentRef = vcr.createComponent(componentFactory, undefined, injector);
    componentRef.instance.content = widget.content;
    componentRef.instance.name = widgetName;
  }

  private async getComponentCtor(type: string): Promise<ComponentType<WidgetComponent>> {
    const widget = this.getWidgetConfig(type);

    if (!widget) {
      throw new Error(`A component with type '${ type }' is not available`);
    }

    return await import('@digitaix/eurogard-dashboard-widget').then((m: any) => {
      if (!m[widget.component]) {
        throw new Error(`A component with name '${ widget.component }' is not available`);
      }
      return m[widget.component];
    });
  }

}
