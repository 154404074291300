import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import {
  TranslationControllerHasTranslationParameter,
} from '../parameters/translation-controller-has-translation.parameter';
import {
  TranslationControllerHasTranslationResponse,
} from '../responses/translation-controller-has-translation.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-i18n',
  operationId: 'TranslationController_hasTranslation',
  operation: '{"operationId":"TranslationController_hasTranslation","parameters":[{"name":"identifier","required":true,"in":"path","schema":{"type":"string"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"value":{"type":"boolean"}}}}}}},"method":"get","path":"/translation/{identifier}/exists"}',
})
export class TranslationControllerHasTranslationRemoteMethod
  extends OpenApiRemoteMethod<TranslationControllerHasTranslationResponse, TranslationControllerHasTranslationParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<TranslationControllerHasTranslationParameter, void>): Promise<TranslationControllerHasTranslationResponse> {
    return super.call(parameters);
  }
}
