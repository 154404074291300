import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { EurogardAuthenticationService } from '@digitaix/eurogard-authentication';
import { RxapAuthenticationService } from '@rxap/authentication';
import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { isDefined } from '@rxap/utilities/rxjs';
import { UserControllerMeRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/user-controller-me.remote-method';
import { UserControllerMeResponse } from '@eurogard/open-api-legacy/responses/user-controller-me.response';

@Component({
  selector: 'rxap-user-profile-icon',
  templateUrl: './user-profile-icon.component.html',
  styleUrls: [ './user-profile-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'rxap-user-profile-icon'},
})
export class UserProfileIconComponent {
  public userName$: Observable<string>;

  public user$: Observable<UserControllerMeResponse>;

  constructor(
    public getUserProfile: UserControllerMeRemoteMethod,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
    this.user$ = from(this.getUserProfile.call()).pipe(shareReplay(1));
    this.userName$ = this.user$.pipe(
      isDefined(),
      map((user) => user.username!),
    );
  }

  public async logout() {
    await this.authenticationService.signOut();
  }


}
