<div class="container">

  <mat-card class="card">
    <mat-card-title i18n>Insufficient Permissions</mat-card-title>
    <mat-card-content>

      <div class="inner">

        <p i18n>You do not have the required permissions to access this page:</p>

        <ul>

          <li *ngFor="let permission of requiredPermissions$ | async">{{permission}}</li>

        </ul>

      </div>

    </mat-card-content>
    <mat-card-actions>
      <button [routerLink]="['/']" color="primary" mat-raised-button i18n>Back to Home</button>
      <button (click)="logout()" mat-stroked-button i18n>Logout</button>
    </mat-card-actions>
  </mat-card>

</div>
