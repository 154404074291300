import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  NumberButtonWidget,
  WidgetComponent,
  WidgetData,
  WidgetRef,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';

@Component({
  selector: 'eurogard-number-button-widget',
  templateUrl: './number-button-widget.component.html',
  styleUrls: [ './number-button-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-number-button-widget'},
})
export class NumberButtonWidgetComponent implements WidgetComponent {

  @Input()
  public name: string | null = null;

  public value: number | null = null;

  public color: string | null = null;

  public showResponse         = false;
  public label: string | null = null;

  public ref?: WidgetRef;

  private _content!: NumberButtonWidget;

  @Input()
  public set content(content: NumberButtonWidget) {
    this._content     = content;
    this.value        = content.value ?? this.value;
    this.color        = content.color ?? this.color;
    this.label        = content.label ?? this.value?.toFixed(0) ?? this.label;
    this.ref          = content.ref;
    this.showResponse = content.showResponse ?? false;
  }

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo
  ) {}

}
