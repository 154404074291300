import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { BildanzeigeWidget, ISetWidgetDataMethod, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import { BaseDataSource } from '@rxap/data-source';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'eurogard-bildanzeige-widget',
  templateUrl: './bildanzeige-widget.component.html',
  styleUrls: [ './bildanzeige-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-bildanzeige-widget'},
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeAnimation', [
      transition(
        ':enter',
        [
          style({opacity: 0}),
          animate('3s ease-out',
            style({opacity: 1})),
        ],
      ),
      transition(
        ':leave',
        [
          style({opacity: 1}),
          animate('2s ease-in',
            style({opacity: 0})),
        ],
      ),
    ]),
  ],
})
export class BildanzeigeWidgetComponent implements WidgetComponent<BildanzeigeWidget>, OnInit, OnDestroy {

  @Input()
  public content!: BildanzeigeWidget;

  @Input()
  public name!: string | null;

  public data?: WidgetData;

  private _subscription?: Subscription;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  public ngOnInit() {
    this._subscription = this.dataSource.connect({id: 'eurogard-bildanzeige-widget'}).pipe(
      tap(data => {
        this.data ??= {} as any;
        Object.assign(this.data, data);
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

}

