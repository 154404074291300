import { Injectable } from '@angular/core';
import { Method } from '@rxap/utilities/rxjs';
import {
  DarkModeControllerToggleRemoteMethod,
} from '@eurogard/open-api-service-user-settings/remote-methods/dark-mode-controller-toggle.remote-method';
import { BaseDataSourceViewer } from '@rxap/data-source';
import { UserSettingsDataSource } from '@de/shared/user-settings.data-source';

@Injectable({ providedIn: 'root' })
export class ToggleDarkModeMethod implements Method<void> {



  private viewer: BaseDataSourceViewer = { id: 'toggle-dark-mode-method' };
  constructor(
    private readonly darkModeControllerToggle: DarkModeControllerToggleRemoteMethod,
    private readonly userSettingsDataSource: UserSettingsDataSource,
  ) {
  }

  async call() {
    await this.darkModeControllerToggle.call();
    this.userSettingsDataSource.refresh();
  }

}
