import { NgModule } from '@angular/core';
import { StaticTextWidgetComponent } from './static-text-widget.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AutoFontSizeDirectiveModule } from '../utilities/auto-font-size.directive';


@NgModule({
  declarations: [
    StaticTextWidgetComponent,
  ],
  imports: [
    FlexLayoutModule,
    AutoFontSizeDirectiveModule,
  ],
  exports: [
    StaticTextWidgetComponent,
  ],
})
export class StaticTextWidgetComponentModule {
}
