import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DashboardControllerGetByUuidParameter } from '../parameters/dashboard-controller-get-by-uuid.parameter';
import { DashboardControllerGetByUuidResponse } from '../responses/dashboard-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'dashboard-controller-getByUuid',
    operation: '{"operationId":"dashboard-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/DashboardDetails"}}}}},"method":"get","path":"/dashboard-controller/get/{uuid}"}'
  })
export class DashboardControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<DashboardControllerGetByUuidResponse, DashboardControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DashboardControllerGetByUuidParameter, void>): Promise<DashboardControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDashboardControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardDashboardControllerGetByUuidRemoteMethod'
  })
export class DashboardControllerGetByUuidRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DashboardControllerGetByUuidResponse, OpenApiRemoteMethodParameter<DashboardControllerGetByUuidParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardControllerGetByUuidRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<DashboardControllerGetByUuidParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardControllerGetByUuidRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardControllerGetByUuidRemoteMethod) remoteMethod: DashboardControllerGetByUuidRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DashboardControllerGetByUuidResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardControllerGetByUuidRemoteMethodTemplateDirective ],
    exports: [ DashboardControllerGetByUuidRemoteMethodTemplateDirective ]
  })
export class DashboardControllerGetByUuidRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDashboardControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardDashboardControllerGetByUuidRemoteMethod'
  })
export class DashboardControllerGetByUuidRemoteMethodDirective extends RemoteMethodDirective<DashboardControllerGetByUuidResponse, OpenApiRemoteMethodParameter<DashboardControllerGetByUuidParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardControllerGetByUuidRemoteMethod) remoteMethod: DashboardControllerGetByUuidRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardControllerGetByUuidRemoteMethodDirective ],
    exports: [ DashboardControllerGetByUuidRemoteMethodDirective ]
  })
export class DashboardControllerGetByUuidRemoteMethodDirectiveModule {
}
