import { Inject, Injectable, isDevMode } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { KeycloakService } from '@rxap/keycloak';
import { EurogardAuthenticationService } from './eurogard-authentication.service';

@Injectable()
export class EurogardAuthorizationService {

  constructor(@Inject(KeycloakService)
              private readonly keycloakService: KeycloakService,
              @Inject(RxapAuthenticationService)
              private readonly authenticationService: EurogardAuthenticationService,
  ) {
  }

  setUserRoles(userRoles: string[]): Promise<string[]> {
    throw new Error('The user roles is set by the KeycloakService');
  }

  checkPermission(identifier: string, permissions: string[], scope?: string | null): boolean {
    if (isDevMode()) {
      console.debug('Checking permissions: ', {identifier, permissions});
    }
    return permissions.includes(identifier);
  }

  hasPermission(identifier: string, scope?: string | null): Observable<boolean> {
    return of(this.getRoles()).pipe(
      map((permissions) =>
        this.checkPermission(identifier, permissions, scope),
      ),
      distinctUntilChanged(),
    );
  }

  private getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }


}
