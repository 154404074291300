<div [ngClass]="cellInfo.classList" class="container">
  <div class="inner" fxLayout="row" fxLayoutAlign="center start">
    <div class="content" fxFlex="100" fxLayout="column" fxLayoutGap="1px">
<!--      <span *ngIf="name" class="name" fxFlex="nogrow">{{name}}</span>-->
      <div *ngIf="data" fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="overflow" fxFlex="nogrow">
          <span [ngStyle]="{ color: color }"
                [countUp]="data.value | toNumber"
                [options]="{ decimalPlaces: fractionDigits }"
                class="value">
          </span>
        </div>
        <div *ngIf="data.unit?.symbol" class="overflow" fxFlex="nogrow">
          <span class="value unit">
            {{ data.unit?.symbol }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
