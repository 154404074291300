import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBoolean',
})
export class ToBooleanPipe implements PipeTransform {

  public transform(value: any): boolean | null {

    if (typeof value === 'boolean') {
      return value;
    }

    switch (value) {

      case '1':
        return true;

      case '0':
        return false;

    }

    return null;

  }

}

@NgModule({
  declarations: [ ToBooleanPipe ],
  exports: [ ToBooleanPipe ],
})
export class ToBooleanPipeModule {
}
