import * as Joi from 'joi';

export const DashboardConfigSchema = Joi.object({
  widgets: Joi.array().items(Joi.object({
    type: Joi.string().required(),
    component: Joi.string().required(),
    content: Joi.object(),
    noShowTimestamp: Joi.boolean(),
    allowEmptyRef: Joi.boolean(),
    hideHeader: Joi.boolean(),
    hidden: Joi.boolean(),
    i18n: Joi.array().items(Joi.string())
  })).required()
});
