import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { ThingControllerGetByUuidParameter } from '../parameters/thing-controller-get-by-uuid.parameter';
import { ThingControllerGetByUuidResponse } from '../responses/thing-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'thing-controller-getByUuid',
    operation: '{"operationId":"thing-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/Thing"}}}}},"method":"get","path":"/thing-controller/get/{uuid}"}'
  })
export class ThingControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<ThingControllerGetByUuidResponse, ThingControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<ThingControllerGetByUuidParameter, void>): Promise<ThingControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardThingControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardThingControllerGetByUuidRemoteMethod'
  })
export class ThingControllerGetByUuidRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<ThingControllerGetByUuidResponse, OpenApiRemoteMethodParameter<ThingControllerGetByUuidParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardThingControllerGetByUuidRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<ThingControllerGetByUuidParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardThingControllerGetByUuidRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(ThingControllerGetByUuidRemoteMethod) remoteMethod: ThingControllerGetByUuidRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<ThingControllerGetByUuidResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ ThingControllerGetByUuidRemoteMethodTemplateDirective ],
    exports: [ ThingControllerGetByUuidRemoteMethodTemplateDirective ]
  })
export class ThingControllerGetByUuidRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardThingControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardThingControllerGetByUuidRemoteMethod'
  })
export class ThingControllerGetByUuidRemoteMethodDirective extends RemoteMethodDirective<ThingControllerGetByUuidResponse, OpenApiRemoteMethodParameter<ThingControllerGetByUuidParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(ThingControllerGetByUuidRemoteMethod) remoteMethod: ThingControllerGetByUuidRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ ThingControllerGetByUuidRemoteMethodDirective ],
    exports: [ ThingControllerGetByUuidRemoteMethodDirective ]
  })
export class ThingControllerGetByUuidRemoteMethodDirectiveModule {
}
