import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DigitalwertAnzeigeWidget,
  ISetWidgetDataMethod,
  WidgetComponent,
  WidgetData,
  WidgetFontSize,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { Subscription } from 'rxjs';
import { filter, map, pairwise, tap } from 'rxjs/operators';
import { isDefined } from '@rxap/utilities/rxjs';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-digitalwert-anzeige-widget',
  templateUrl: './digitalwert-anzeige-widget.component.html',
  styleUrls: [ './digitalwert-anzeige-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-digitalwert-anzeige-widget'},
})
export class DigitalwertAnzeigeWidgetComponent implements WidgetComponent, OnInit, OnDestroy {

  public color: string | null = null;

  public fractionDigits: number = 0;

  public fontSize?: WidgetFontSize;

  @Input()
  public name: string | null = null;
  @Input()
  public change: number | null = null;
  public data?: any;
  private _subscription?: Subscription;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  private _content!: DigitalwertAnzeigeWidget;

  @Input()
  public set content(content: DigitalwertAnzeigeWidget) {
    this._content = content;
    this.color = this._content.color;
    this.fontSize = this._content.fontSize;
    this.fractionDigits = this._content.fractionDigits ?? this._content.fractionDigits;
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  public ngOnInit() {
    this._subscription = this.dataSource.connect({id: 'value-change-check'}).pipe(
      tap(data => this.data = data),
      tap(() => this.cdr.detectChanges()),
      map(data => data?.value),
      isDefined(),
      map(value => Number(value)),
      filter(value => !isNaN(value)),
      pairwise(),
      map(([ last, current ]) => current - last),
      tap(change => this.change = change),
    ).subscribe();
  }
}
