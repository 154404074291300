import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { CompanyControllerGetByUuidParameter } from '../parameters/company-controller-get-by-uuid.parameter';
import { CompanyControllerGetByUuidResponse } from '../responses/company-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'company-controller-getByUuid',
    operation: '{"operationId":"company-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/Company"}}}}},"method":"get","path":"/company-controller/get/{uuid}"}'
  })
export class CompanyControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<CompanyControllerGetByUuidResponse, CompanyControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<CompanyControllerGetByUuidParameter, void>): Promise<CompanyControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardCompanyControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardCompanyControllerGetByUuidRemoteMethod'
  })
export class CompanyControllerGetByUuidRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<CompanyControllerGetByUuidResponse, OpenApiRemoteMethodParameter<CompanyControllerGetByUuidParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardCompanyControllerGetByUuidRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<CompanyControllerGetByUuidParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardCompanyControllerGetByUuidRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(CompanyControllerGetByUuidRemoteMethod) remoteMethod: CompanyControllerGetByUuidRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<CompanyControllerGetByUuidResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ CompanyControllerGetByUuidRemoteMethodTemplateDirective ],
    exports: [ CompanyControllerGetByUuidRemoteMethodTemplateDirective ]
  })
export class CompanyControllerGetByUuidRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardCompanyControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardCompanyControllerGetByUuidRemoteMethod'
  })
export class CompanyControllerGetByUuidRemoteMethodDirective extends RemoteMethodDirective<CompanyControllerGetByUuidResponse, OpenApiRemoteMethodParameter<CompanyControllerGetByUuidParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(CompanyControllerGetByUuidRemoteMethod) remoteMethod: CompanyControllerGetByUuidRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ CompanyControllerGetByUuidRemoteMethodDirective ],
    exports: [ CompanyControllerGetByUuidRemoteMethodDirective ]
  })
export class CompanyControllerGetByUuidRemoteMethodDirectiveModule {
}
