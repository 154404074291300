import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {

  public transform(value: any): number {
    if (typeof value === 'number') {
      return value;
    }
    const num = Number(value);
    return isNaN(num) ? num : num;
  }

}

@NgModule({
  declarations: [ ToNumberPipe ],
  exports: [ ToNumberPipe ],
})
export class ToNumberPipeModule {

}
