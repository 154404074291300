<a [routerLink]="item.routerLink" routerLinkActive="link-active" style="display: none"></a>

<ng-template [ngIfElse]="withoutChildren" [ngIfThen]="withChildren" [ngIf]="children"></ng-template>

<ng-template #withoutChildren>

  <a [routerLink]="item.routerLink"
     class="navigation-link mat-body-2 mat-body-strong navigation-level-{{level}}"
     matRipple routerLinkActive="link-active">
    <span class="label">{{ item.label }}</span>
    <mat-icon *ngIf="item.icon" [rxapIcon]="item.icon" class="icon"></mat-icon>
  </a>

</ng-template>

<ng-template #withChildren>

  <a (click)="open = !open"
     [routerLink]="item.routerLink"
          class="navigation-link mat-body-2 mat-body-strong navigation-level-{{level}}"
     matRipple>
    <span class="label">{{ item.label }}</span>
    <mat-icon *ngIf="item.icon" [rxapIcon]="item.icon" class="icon"></mat-icon>
  </a>

  <ng-container *ngIf="routerLinkActive.isActive || open">

    <mat-divider></mat-divider>

    <ul [@sub-nav]
        [items]="children ?? []"
        [level]="level + 1"
        class="sub-items"
        fxFlex="nogrow"
        rxap-navigation
    >
    </ul>

    <mat-divider></mat-divider>

  </ng-container>

</ng-template>
