import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  UserControllerUpdateUserLanguageRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/user-controller-update-user-language.remote-method';

@Injectable({providedIn: 'root'})
export class I18nService {

  public readonly currentLanguage: string;

  constructor(
    @Inject(LOCALE_ID)
    private readonly localId: string,
    private readonly updateUserLanguage: UserControllerUpdateUserLanguageRemoteMethod,
  ) {
    this.currentLanguage = this.localId.replace(/-[A-Z]+$/, '');
  }

  public async setLanguage(language: string) {
    let code: string;

    switch (language) {

      case 'de':
        code = '0';
        break;

      case 'en':
        code = '1';
        break;

      default:
        throw new Error(`The language '${ language } is not supported'`);

    }

    await this.updateUserLanguage.call({
      requestBody: {
        updateMap: {
          userLanguage: code,
        },
      },
    });
    this.redirect(language);
  }

  public redirect(next: string, current: string = this.currentLanguage) {
    if (current === next) {
      console.warn('[I18nService] redirect not required - language unchanged');
      return;
    }
    const redirectUrl =
      location.origin +
      location.pathname.replace(new RegExp(`^/${ current }`), `/${ next }`) +
      location.search;
    console.log('[I18nService] redirect to: ' + redirectUrl);
    location.replace(redirectUrl);
  }

}
