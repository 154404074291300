import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  isDevMode,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Constructor, ControlOptions, ToOptions } from '@rxap/utilities';
import { from, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderService, ThemeService } from '@rxap/services';
import { ThemePalette } from '@angular/material/core';
import { RXAP_HEADER_COMPONENT } from '@rxap/layout';
import { CompanySelectService } from '@digitaix/eurogard-utilities';
import { PipeDataSource, pipeDataSource } from '@rxap/data-source';
import {
  CompanyControllerGetAllDataSource,
} from '@eurogard/open-api-legacy/data-sources/company-controller-get-all.data-source';
import {
  UserControllerMeRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/user-controller-me.remote-method';
import {
  UserSettingsControllerToggleHideRouterRemoteMethod,
} from '@eurogard/open-api-service-user-settings/remote-methods/user-settings-controller-toggle-hide-router.remote-method';
import { UserSettingsDataSource } from '@de/shared/user-settings.data-source';

@Component({
  selector: 'rxap-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'rxap-layout-header',
  },
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input()
  public sidenav?: MatSidenav;

  public components: Array<Constructor<any>> = [];

  public subscriptions = new Subscription();

  public hasUser$: Observable<boolean>;

  @Input()
  public color: ThemePalette = 'primary';

  public companyOptionsDataSource: PipeDataSource<any, ControlOptions>;

  public isDevMode = isDevMode();

  public hideRouter$ = this.userSettingsDataSource.select<boolean>('hideRouter');

  constructor(
    @Inject(HeaderService)
    public readonly headerComponentService: HeaderService,
    private readonly getUserProfile: UserControllerMeRemoteMethod,
    @Optional() @Inject(RXAP_HEADER_COMPONENT) public headerComponent: any,
    public readonly theme: ThemeService,
    public companySelectService: CompanySelectService,
    private companyControllerGetAll: CompanyControllerGetAllDataSource,
    private readonly toggleHideRouterMethod: UserSettingsControllerToggleHideRouterRemoteMethod,
    private readonly userSettingsDataSource: UserSettingsDataSource,
  ) {
    this.hasUser$ = from(this.getUserProfile.call()).pipe(map(Boolean));
    this.companyOptionsDataSource = pipeDataSource(
      this.companyControllerGetAll,
      map(companies => ToOptions(companies, v => v.uuid, v => v.name)),
    );
  }

  public ngOnInit() {
    this.updateComponents();
    this.subscriptions.add(
      this.headerComponentService.update$
      .pipe(tap(() => this.updateComponents()))
      .subscribe(),
    );
  }

  public updateComponents(): void {
    this.components = this.headerComponentService.getComponents();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async toggleHideRouter() {
    await this.toggleHideRouterMethod.call();
    await this.userSettingsDataSource.refresh();
  }

}
