import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DashboardWidgetControllerSocketConnectRequestBody } from '../request-bodies/dashboard-widget-controller-socket-connect.request-body';
import { DashboardWidgetControllerSocketConnectResponse } from '../responses/dashboard-widget-controller-socket-connect.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'dashboard-widget-controller-socketConnect',
    operation: '{"operationId":"dashboard-widget-controller-socketConnect","requestBody":{"content":{"application/json":{"schema":{"type":"array","items":{"$ref":"#/components/schemas/WidgetDataRequest"}}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"type":"string"}}}}},"method":"post","path":"/dashboard-widget-controller/socket-connect"}'
  })
export class DashboardWidgetControllerSocketConnectRemoteMethod extends OpenApiRemoteMethod<DashboardWidgetControllerSocketConnectResponse, void, DashboardWidgetControllerSocketConnectRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, DashboardWidgetControllerSocketConnectRequestBody>): Promise<DashboardWidgetControllerSocketConnectResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerSocketConnectRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerSocketConnectRemoteMethod'
  })
export class DashboardWidgetControllerSocketConnectRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DashboardWidgetControllerSocketConnectResponse, OpenApiRemoteMethodParameter<void, DashboardWidgetControllerSocketConnectRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerSocketConnectRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, DashboardWidgetControllerSocketConnectRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerSocketConnectRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerSocketConnectRemoteMethod) remoteMethod: DashboardWidgetControllerSocketConnectRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DashboardWidgetControllerSocketConnectResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerSocketConnectRemoteMethodTemplateDirective ],
    exports: [ DashboardWidgetControllerSocketConnectRemoteMethodTemplateDirective ]
  })
export class DashboardWidgetControllerSocketConnectRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerSocketConnectRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerSocketConnectRemoteMethod'
  })
export class DashboardWidgetControllerSocketConnectRemoteMethodDirective extends RemoteMethodDirective<DashboardWidgetControllerSocketConnectResponse, OpenApiRemoteMethodParameter<void, DashboardWidgetControllerSocketConnectRequestBody>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerSocketConnectRemoteMethod) remoteMethod: DashboardWidgetControllerSocketConnectRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerSocketConnectRemoteMethodDirective ],
    exports: [ DashboardWidgetControllerSocketConnectRemoteMethodDirective ]
  })
export class DashboardWidgetControllerSocketConnectRemoteMethodDirectiveModule {
}
