import {
  ChangeDetectorRef,
  Directive,
  Inject,
  Injectable,
  INJECTOR,
  Injector,
  Input,
  NgModule,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import {
  RemoteMethodDirective,
  RemoteMethodTemplateDirective,
  RemoteMethodTemplateDirectiveContext,
  RemoteMethodTemplateDirectiveErrorContext,
} from '@rxap/remote-method/directive';
import { VpnRouterControllerAccessParameter } from '../parameters/vpn-router-controller-access.parameter';
import { VpnRouterControllerAccessResponse } from '../responses/vpn-router-controller-access.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: undefined,
  operationId: 'vpn-router-controller-access',
  operation: '{"operationId":"vpn-router-controller-access","parameters":[{"name":"thing-uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}},{"name":"tun","in":"query","required":false,"schema":{"type":"boolean"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object"}}}}},"method":"get","path":"/vpn-router-controller/{thing-uuid}/access"}',
})
export class VpnRouterControllerAccessRemoteMethod extends OpenApiRemoteMethod<VpnRouterControllerAccessResponse, VpnRouterControllerAccessParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<VpnRouterControllerAccessParameter, void>): Promise<VpnRouterControllerAccessResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardVpnRouterControllerAccessRemoteMethod]',
    exportAs: 'eurogardVpnRouterControllerAccessRemoteMethod'
  })
export class VpnRouterControllerAccessRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<VpnRouterControllerAccessResponse, OpenApiRemoteMethodParameter<VpnRouterControllerAccessParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardVpnRouterControllerAccessRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<VpnRouterControllerAccessParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardVpnRouterControllerAccessRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(VpnRouterControllerAccessRemoteMethod) remoteMethod: VpnRouterControllerAccessRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<VpnRouterControllerAccessResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ VpnRouterControllerAccessRemoteMethodTemplateDirective ],
    exports: [ VpnRouterControllerAccessRemoteMethodTemplateDirective ]
  })
export class VpnRouterControllerAccessRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardVpnRouterControllerAccessRemoteMethod]',
    exportAs: 'eurogardVpnRouterControllerAccessRemoteMethod'
  })
export class VpnRouterControllerAccessRemoteMethodDirective extends RemoteMethodDirective<VpnRouterControllerAccessResponse, OpenApiRemoteMethodParameter<VpnRouterControllerAccessParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(VpnRouterControllerAccessRemoteMethod) remoteMethod: VpnRouterControllerAccessRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ VpnRouterControllerAccessRemoteMethodDirective ],
    exports: [ VpnRouterControllerAccessRemoteMethodDirective ]
  })
export class VpnRouterControllerAccessRemoteMethodDirectiveModule {
}
