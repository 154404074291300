import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thingTyp',
})
export class ThingTypPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 0:
        return 'ServiceRouter V3';

      case 1:
        return 'SRcompact';

      case 2:
        return 'Ewon';

      default:
        return 'unknown';
    }
  }
}

@NgModule({
  exports: [ ThingTypPipe ],
  declarations: [ ThingTypPipe ],
})
export class ThingTypPipeModule {
}
