import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetMessageTypesResponse } from '../responses/options-controller-get-message-types.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getMessageTypes',
    operation: '{"operationId":"options-controller-getMessageTypes","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/message-types"}'
  })
export class OptionsControllerGetMessageTypesRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetMessageTypesResponse, void, void> {
  public override call(): Promise<OptionsControllerGetMessageTypesResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetMessageTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetMessageTypesRemoteMethod'
  })
export class OptionsControllerGetMessageTypesRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetMessageTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetMessageTypesRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetMessageTypesRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetMessageTypesRemoteMethod) remoteMethod: OptionsControllerGetMessageTypesRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetMessageTypesResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetMessageTypesRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetMessageTypesRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetMessageTypesRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetMessageTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetMessageTypesRemoteMethod'
  })
export class OptionsControllerGetMessageTypesRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetMessageTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetMessageTypesRemoteMethod) remoteMethod: OptionsControllerGetMessageTypesRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetMessageTypesRemoteMethodDirective ],
    exports: [ OptionsControllerGetMessageTypesRemoteMethodDirective ]
  })
export class OptionsControllerGetMessageTypesRemoteMethodDirectiveModule {
}
