import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'eurogard-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: [ './error-message.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-error-message'},
})
export class ErrorMessageComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: any,
  ) {
  }

}
