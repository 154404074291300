import { Pipe, PipeTransform } from '@angular/core';
import { BildanzeigeEntry, BildanzeigeWidget, WidgetData } from '@digitaix/eurogard-utilities';

@Pipe({
  name: 'determineImage',
  pure: false,
})
export class DetermineImagePipe implements PipeTransform {

  transform(data: WidgetData | null, content: BildanzeigeWidget): BildanzeigeEntry | null {
    if (content.images.length == 1) return content.images[0]; //Return only entry if only entry
    let found = null;
    let value: number = Number(data?.value);
    const images = content.images.filter(image => !isNaN(Number(image.value))).sort((a, b) => a.value - b.value);
    if (isNaN(value)) {
      value = 0;
    }
    if (value > Math.max(...images.map(image => image.value))) {
      return images.sort((a, b) => b.value - a.value)[0];
    }
    if (value < Math.min(...images.map(image => image.value))) {
      return images.sort((a, b) => a.value - b.value)[0];
    }
    for (const image of images) {
      if (image.value > value) {
        return found;
      }
      if (image.value <= value) {
        found = image;
      }
    }
    return found;
  }

}
