import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  INJECTOR,
  Injector,
  Input,
  isDevMode,
  LOCALE_ID,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Widget } from '@digitaix/eurogard-utilities';
import { ConfigService } from '@rxap/config';
import '@digitaix/eurogard-dashboard-widget';
import { CellInfo } from '@digitaix/eurogard-dashboard-widget';
import { WidgetDataDataSource } from '../widget-data.data-source';
import { take, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CellElement } from '@digitaix/eurogard-dashboard-xml-parser';
import { WidgetDisplayService } from '../widget-display.service';
import { WidgetContainerDirective } from '../widget-container.directive';

@Component({
  selector: 'eurogard-cell-content,td[eurogard-cell-content]',
  templateUrl: './cell-content.component.html',
  styleUrls: [ './cell-content.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {class: 'eurogard-cell-content'},
})
export class CellContentComponent implements OnChanges, OnInit {

  @Input()
  public cell?: CellElement;

  @Input()
  public cellInfo: CellInfo | null = null;

  @ViewChild(WidgetContainerDirective, {static: true})
  public content!: WidgetContainerDirective;

  /**
   * true - the header with the icon component is hidden. The widget content covers the complete cell
   */
  public hideHeader?: boolean;

  constructor(
    @Inject(ComponentFactoryResolver)
    private readonly cfr: ComponentFactoryResolver,
    @Inject(ChangeDetectorRef)
    private readonly cdr: ChangeDetectorRef,
    @Inject(ConfigService)
    private readonly config: ConfigService,
    @Inject(WidgetDataDataSource)
    private readonly widgetData: WidgetDataDataSource,
    @Inject(INJECTOR)
    private readonly injector: Injector,
    @Inject(MatSnackBar)
    private readonly snackBar: MatSnackBar,
    @Inject(LOCALE_ID)
    private readonly localeId: string,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef,
    @Inject(WidgetDisplayService)
    private readonly widgetDisplayService: WidgetDisplayService,
  ) {
  }

  public get widget(): Widget | undefined {
    return this.cell?.widget;
  }

  public ngOnInit() {
    if (this.widget) {
      this.hideHeader = this.widgetDisplayService.getWidgetConfig(this.widget.type)?.hideHeader ?? this.hideHeader;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    const cellChange = changes.cell;
    if (cellChange) {
      this.cellInfo ??= new CellInfo(
        this.cell?.colspan ?? 0,
        this.cell?.rowspan ?? 0,
        this.cell?.x ?? 0,
        this.cell?.y ?? 0,
        this.elementRef.nativeElement.clientWidth,
        this.elementRef.nativeElement.clientHeight,
      );
      if (cellChange.currentValue?.widget) {
        this.widgetDisplayService.updateContentComponent(
          cellChange.currentValue.widget,
          this.content.vcr,
          this.widgetData,
          this.injector,
          this.cfr,
          this.cellInfo,
          this.cell,
        ).then(() => this.cdr.detectChanges()).catch(e => {
          console.error(`Update component content failed: ${ e.message }`);
          if (isDevMode()) {
            throw e;
          }
        });
      }
    }
  }

  public showTimestamp() {

    if (!this.widget) {
      return;
    }

    const configWidget = this.widgetDisplayService.getWidgetConfig(this.widget.type);

    if (configWidget?.noShowTimestamp) {
      return;
    }

    this.widgetData.dataChangeDistanceToNow$.pipe(
      take(1),
      tap(distanceToNow => this.snackBar.open(
        `${ distanceToNow }`,
        undefined,
        {duration: 1024}),
      ),
    ).subscribe();
  }

}
