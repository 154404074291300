<div [ngClass]="cellInfo.classList" class="container">
  <div *rxapDataSource="let data from dataSource; trackBy: trackBy" class="inner" fxLayout="column"
       fxLayoutAlign="center center"
       fxLayoutGap="5px">
<!--    <span class="label" fxFlex="nogrow">{{name}}</span>-->
    <span [fxShow]="!cellInfo.size75 && cellInfo.aspectRatio <= 1" class="legend max"
          fxFlex="nogrow" fxFlexAlign="end">{{max | toFixed:fractionDigits}}</span>
    <div class="beam-container" fxFlex="nogrow">
      <div [ngStyle]="{ backgroundColor: bgColor }" class="beam">

        <div class="beam-inner">
          <div class="legend-container" fxLayout="row" fxLayoutAlign="space-between center">
            <span [fxHide]="cellInfo.size75 || cellInfo.aspectRatio <= 1" class="legend min"
                  fxFlex="nogrow">{{min | toFixed:fractionDigits}}</span>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
              <span class="legend value"
                    fxFlex="nogrow">
                {{data?.value | toNumber | toFixed:fractionDigits}}
              </span>
              <span *ngIf="data?.unit?.symbol"
                    class="legend unit"
                    fxFlex="nogrow">
                {{data?.unit?.symbol}}
              </span>
            </div>
            <span [fxHide]="cellInfo.size75 || cellInfo.aspectRatio <= 1" class="legend max"
                  fxFlex="nogrow">{{max | toFixed:fractionDigits}}</span>
          </div>
        </div>

        <div
          [@grow]="{ value: data?.value, params: { last: (data?.lastValue | toNumber | percentageOfRange:min:max), current: (data?.value | toNumber | percentageOfRange:min:max) } }"
          [ngStyle]="{ backgroundColor: color }"
          class="value-beam"></div>

      </div>
    </div>
    <span [fxShow]="!cellInfo.size75 && cellInfo.aspectRatio <= 1" class="legend min"
          fxFlex="nogrow" fxFlexAlign="start">{{min | toFixed:fractionDigits}}</span>

  </div>
</div>
