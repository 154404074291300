import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor {

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(`${location.origin}/api`)) {
      const params = request.params.set('timestamp', Date.now());
      return next.handle(request.clone({ params }));
    }
    return next.handle(request);
  }
}
