import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { WidgetFontSize } from '@digitaix/eurogard-utilities';

@Directive({
  selector: '[eurogardAutoFontSize]',
})
export class AutoFontSizeDirective implements AfterViewInit, AfterContentInit, AfterContentChecked, OnChanges {

  @Input()
  public min: number = 16;

  @Input()
  public factor: number = 9;

  @Input()
  public target?: WidgetFontSize;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
  }

  public get cellContainerElement() {
    return this.elementRef.nativeElement.closest('.eurogard-cell-content');
  }

  public get clientHeight(): number {
    if (this.cellContainerElement.clientHeight === 0) {
      return this.cellContainerElement.parentElement.clientHeight;
    }
    return this.cellContainerElement.clientHeight;
  }

  public get clientWidth(): number {
    if (this.cellContainerElement.clientWidth === 0) {
      return this.cellContainerElement.parentElement.clientWidth;
    }
    return this.cellContainerElement.clientWidth;
  }

  public ngOnChanges() {
    this.setFontSize();
  }

  public ngAfterViewInit() {
    this.setFontSize();
  }

  public ngAfterViewChecked() {
    this.setFontSize();
  }

  public ngAfterContentInit() {
    this.setFontSize();
  }

  public ngAfterContentChecked() {
    this.setFontSize();
  }

  private setFontSize() {
    let factor = this.factor;
    let min = this.min;
    switch (this.target) {
      case WidgetFontSize.SMALLER:
        factor += 4;
        min -= 6;
        break;
      case WidgetFontSize.SMALL:
        factor += 2;
        min -= 4;
        break;
      case WidgetFontSize.MEDIUM:
        factor += 0;
        min += 0;
        break;
      case WidgetFontSize.LARGE:
        factor -= 2;
        min += 4;
        break;
      case WidgetFontSize.LARGER:
        factor -= 4;
        min += 6;
        break;
    }


    const size = min;

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'font-size',
      size + 'px',
    );
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'line-height',
      (size + 2) + 'px',
    );
  }

}

@NgModule({
  declarations: [ AutoFontSizeDirective ],
  exports: [ AutoFontSizeDirective ],
})
export class AutoFontSizeDirectiveModule {
}
