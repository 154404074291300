<div *ngIf="appList && appList.length" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">

  <div *ngIf="isOpen" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">

    <div *ngFor="let app of appList" class="app">
      <a [href]="app.href" mat-stroked-button>
        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <img *ngIf="app.image" [src]="app.image" fxFlex="40px" height="40px">
          <span class="label" fxFlex="nogrow">{{app.label}}</span>
        </span>
      </a>
    </div>

  </div>

  <button (click)="isOpen = !isOpen" mat-icon-button>
    <mat-icon>apps</mat-icon>
  </button>

</div>
