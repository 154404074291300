import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutElement, LayoutXmlParserService } from '@digitaix/eurogard-dashboard-xml-parser';
import { Observable, Subscription } from 'rxjs';
import { Dashboard } from '@digitaix/eurogard-utilities';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { isDefined } from '@rxap/utilities/rxjs';

@Component({
  selector: 'mfd-cloud-dashboard',
  templateUrl: './cloud-dashboard.component.html',
  styleUrls: [ './cloud-dashboard.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'mfd-cloud-dashboard'},
})
export class CloudDashboardComponent implements OnInit, OnDestroy {

  public layout!: LayoutElement;
  public dashboard!: Dashboard;
  public isInline$: Observable<boolean>;
  private _subscription?: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly layoutXmlParser: LayoutXmlParserService,
  ) {
    this.isInline$ = this.route.queryParamMap.pipe(map(queryParamMap => queryParamMap.has('inline')));
  }

  public ngOnInit() {
    this._subscription = this.route.data.pipe(
      map(data => data.dashboard),
      isDefined(),
      tap((dashboard: Dashboard) => {
        this.layout = this.layoutXmlParser.parseFromXml(dashboard.layoutList[0].xml, dashboard.widgetList);
        this.dashboard = dashboard;
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

}
