import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartWidgetComponent } from './chart-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    ChartWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    MatSelectModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule,
  ],
  exports: [
    ChartWidgetComponent,
  ],
})
export class ChartWidgetComponentModule {
}
