import { NgModule } from '@angular/core';
import { CellEmulatorComponent } from './cell-emulator.component';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WidgetIconComponentModule } from '../widget-icon/widget-icon.component.module';


@NgModule({
  declarations: [
    CellEmulatorComponent,
  ],
  imports: [
    MatDividerModule,
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    WidgetIconComponentModule,
  ],
  exports: [
    CellEmulatorComponent,
  ],
})
export class CellEmulatorComponentModule {
}
