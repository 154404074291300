<div [ngClass]="cellInfo.classList" class="container">
  <div class="inner" fxLayout="column">
    <div class="overflow">
      <span [ngStyle]="{ color: color }"
            [min]="cellInfo.fontSize"
            [target]="fontSize"
            eurogardAutoFontSize
            class="value date">
        {{lastChange$ | async | date: 'dd.MM.yyyy'}}
      </span>
    </div>
    <div class="overflow">
      <span [ngStyle]="{ color: color }"
            [min]="cellInfo.fontSize"
            [target]="fontSize"
            eurogardAutoFontSize
            class="value time">
        {{lastChange$ | async | date: 'HH:mm:ss'}}
      </span>
    </div>
  </div>
</div>
