import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetConnectionTypesDetailsResponse } from '../responses/options-controller-get-connection-types-details.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getConnectionTypesDetails',
    operation: '{"operationId":"options-controller-getConnectionTypesDetails","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/connection-types-details"}'
  })
export class OptionsControllerGetConnectionTypesDetailsRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetConnectionTypesDetailsResponse, void, void> {
  public override call(): Promise<OptionsControllerGetConnectionTypesDetailsResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethod'
  })
export class OptionsControllerGetConnectionTypesDetailsRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetConnectionTypesDetailsResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetConnectionTypesDetailsRemoteMethod) remoteMethod: OptionsControllerGetConnectionTypesDetailsRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetConnectionTypesDetailsResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetConnectionTypesDetailsRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetConnectionTypesDetailsRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetConnectionTypesDetailsRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetConnectionTypesDetailsRemoteMethod'
  })
export class OptionsControllerGetConnectionTypesDetailsRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetConnectionTypesDetailsResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetConnectionTypesDetailsRemoteMethod) remoteMethod: OptionsControllerGetConnectionTypesDetailsRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetConnectionTypesDetailsRemoteMethodDirective ],
    exports: [ OptionsControllerGetConnectionTypesDetailsRemoteMethodDirective ]
  })
export class OptionsControllerGetConnectionTypesDetailsRemoteMethodDirectiveModule {
}
