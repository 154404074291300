import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@rxap/config';

@Injectable()
export class CorsInterceptor {
  constructor(
    private readonly config: ConfigService<any>,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const baseUrl = this.config.get('api.legacy.baseUrl');
    if (baseUrl && request.url.includes(baseUrl)) {
      return next.handle(request.clone({
        withCredentials: true,
      }));
    }
    return next.handle(request);
  }
}
