import * as Joi from 'joi';

export const NavigationAppsConfigSchema = Joi.array().items(Joi.object({
  image: Joi.string().uri({ allowRelative: true }).required(),
  label: Joi.string().required(),
  href: Joi.string().uri().required(),
  id: Joi.string().optional(),
  permissions: Joi.array().items(Joi.string()),
  hidden: Joi.boolean(),
}));
