import { ChangeDetectorRef, Directive, HostListener, Inject, Input, NgModule, Optional } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ISetWidgetDataMethod, WidgetRef } from '@digitaix/eurogard-utilities';
import { ConfirmDialogComponentModule, ConfirmDialogService } from '@rxap/dialog';
import { ToggleSubject } from '@rxap/utilities/rxjs';
import { WIDGET_SET_DATA_VALUE_METHOD } from './tokens';

@Directive({
  selector: '[eurogardSetWidgetData]',
  exportAs: 'eurogardSetWidgetData',
})
export class SetWidgetDataDirective {
  /**
   * A widget data object
   */
  @Input()
  public ref?: WidgetRef;

  /**
   * The new value for the widget data
   */
  @Input()
  public value?: any;

  /**
   * The message shown in the confirm dialog
   */
  @Input()
  public message!: string;

  public executing$ = new ToggleSubject(false);

  constructor(
    @Inject(WIDGET_SET_DATA_VALUE_METHOD)
    public readonly method: ISetWidgetDataMethod,
    @Inject(ConfirmDialogService)
    private readonly confirmDialogService: ConfirmDialogService,
    @Inject(ChangeDetectorRef)
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(MatButton)
    private readonly matButton: MatButton | null,
  ) {
  }

  @HostListener('click')
  public async onClick() {
    if (!this.ref) {
      throw new Error('The ref input is not defined');
    }
    const result = await this.confirmDialogService.open(this.message, 'ok');
    if (result) {
      this.setDisabled();
      await this.method.call({
        ref: this.ref,
        value: this.convertValue(this.value),
      });
      // TODO check if value is updated
      this.setEnabled();
    }
  }

  private convertValue(value: any): string {
    switch (typeof value) {
      case 'boolean':
        return value ? '1' : '0';
      case 'number':
      case 'bigint':
        return value + '';
      case 'string':
        return value;
      default:
      case 'function':
      case 'symbol':
      case 'undefined':
      case 'object':
        throw new Error(
          `The widget data type '${ typeof value }' is not supported!`,
        );
    }
  }

  private setDisabled() {
    this.executing$.enable();
    if (this.matButton) {
      this.matButton.disabled = true;
    }
    this.cdr.detectChanges();
  }

  private setEnabled() {
    this.executing$.disable();
    if (this.matButton) {
      this.matButton.disabled = false;
    }
    this.cdr.detectChanges();
  }
}

@NgModule({
  declarations: [ SetWidgetDataDirective ],
  exports: [ SetWidgetDataDirective, ConfirmDialogComponentModule ],
})
export class SetWidgetDataDirectiveModule {
}
