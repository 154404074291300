<div class="container">
  <div class="inner" fxLayout="column">
    <div *ngIf="hasError" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px">
        <mat-icon>error</mat-icon>
        <span>Chart Data could not be loaded</span>
      </div>
      <div>
        <button (click)="retry()" mat-stroked-button>Retry</button>
      </div>
    </div>
    <div [fxHide]="hasError" class="chart-container" fxFlex="grow">
      <canvas #chart></canvas>
      <button #trigger="cdkOverlayOrigin" (click)="isOpen = !isOpen" cdkOverlayOrigin class="tune" mat-icon-button>
        <mat-icon>more_horiz</mat-icon>
      </button>
    </div>
  </div>
</div>

<ng-template
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPositions]="connectedPosition"
  cdkConnectedOverlay
>
  <div class="eurogard-chart-widget-overlay overlay mat-elevation-z2">
    <div class="control" fxLayout="column">
      <mat-form-field fxFlex="nogrow">
        <mat-label i18n>Time Range</mat-label>
        <mat-select (ngModelChange)="loadChartData({ condition: $event })" [(ngModel)]="condition">
          <mat-option i18n value="30m">Last 30 minutes</mat-option>
          <mat-option i18n value="60m">Last 60 minutes</mat-option>
          <mat-option i18n value="2h">Last 2 hours</mat-option>
          <mat-option i18n value="5h">Last 5 hours</mat-option>
          <mat-option i18n value="1d">Last day</mat-option>
          <mat-option i18n value="2d">Last 2 days</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="nogrow">
        <mat-label i18n>Time Interval</mat-label>
        <mat-select (ngModelChange)="loadChartData({ interval: $event })" [(ngModel)]="interval">
          <mat-option [value]="10" i18n>10 Sek</mat-option>
          <mat-option [value]="30" i18n>30 Sek</mat-option>
          <mat-option [value]="60" i18n>1 min</mat-option>
          <mat-option [value]="300" i18n>5 min</mat-option>
          <mat-option [value]="900" i18n>15 min</mat-option>
          <mat-option [value]="1800" i18n>30 min</mat-option>
          <mat-option [value]="3200" i18n>60 min</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-template>
