import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataDefinitionControllerPostSetPointRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/data-definition-controller-post-set-point.remote-method';
import { ISetWidgetDataMethod, SetWidgetDataMethodParameters } from '@digitaix/eurogard-utilities';
import { WidgetDataDataSource } from './widget-data.data-source';

declare var $localize: any;

@Injectable()
export class SetWidgetDataMethod implements ISetWidgetDataMethod {
  constructor(
    @Inject(MatSnackBar)
    private readonly snackBar: MatSnackBar,
    @Inject(DataDefinitionControllerPostSetPointRemoteMethod)
    private readonly postSetPoint: DataDefinitionControllerPostSetPointRemoteMethod,
    @Inject(WidgetDataDataSource)
    private readonly dataSource: WidgetDataDataSource,
  ) {
  }

  public async call({
                      ref,
                      value,
                    }: SetWidgetDataMethodParameters): Promise<void> {
    try {
      await this.postSetPoint.call({
        parameters: {
          uuid: ref.dataDefinition,
        },
        requestBody: {
          setPointValue: value,
          machineUuid: ref.machine,
        },
      });
      this.snackBar.open($localize`Data point is successfully updated.`, 'ok', {
        duration: 2560,
      });
      this.dataSource.refresh();
    } catch (e: any) {
      this.snackBar.open($localize`Data point cloud not be updated.`, 'ok', {
        duration: 5120,
      });
    }
  }
}
