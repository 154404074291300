import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserControllerMeRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/user-controller-me.remote-method';
import { isDefined, log } from '@rxap/utilities/rxjs';
import {
  UserControllerFirebaseWebAppTokenRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/user-controller-firebase-web-app-token.remote-method';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable()
export class FirebaseMessageService implements OnDestroy {

  private _subscription?: Subscription;

  constructor(
    private readonly getProfile: UserControllerMeRemoteMethod,
    private afMessaging: AngularFireMessaging,
    private readonly updateToken: UserControllerFirebaseWebAppTokenRemoteMethod,
  ) {
    this.afMessaging.messages
    .pipe(
      tap((message: any) =>
        this.showNotification(
          message.notification.title,
          message.notification.body
        )
      )
    )
    .subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  public async requestToken() {
    this._subscription = this.afMessaging.requestToken
    .pipe(
      isDefined(),
      tap(async token => {

        const profile = await this.getProfile.call();

        if (profile) {

          const profileUuid = profile.uuid;

          if (!profileUuid || typeof profileUuid !== 'string') {
            console.error('Failed to set the firebase message token, bc the profile id is not valid');
            return;
          }

          await this.updateToken.call({
            parameters: { uuid: profileUuid },
            requestBody: {
              updateMap: {
                firebaseWebAppMessageToken: token,
              },
            },
          });

          console.log('firebase message token is set');

        } else {
          console.error('Failed to set the firebase message token, bc could not load the profile');
        }

      }),
    )
    .subscribe();
  }

  public showNotification(title: string, body: string): void {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(title, { body });
    } else {
      console.warn('Notification permission is not granted');
    }
  }

}
