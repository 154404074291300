import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { OptionsControllerGetDataTypesForProtocolRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/options-controller-get-data-types-for-protocol.remote-method';

@Pipe({
  name: 'dataType',
})
export class DataTypePipe implements PipeTransform {
  constructor(private readonly getDataTypesForProtocol: OptionsControllerGetDataTypesForProtocolRemoteMethod) {
  }

  public async transform(value: number, type?: number | string): Promise<string> {
    if (type !== undefined) {
      const optionsMap = await this.getDataTypesForProtocol.call({parameters: {abc: Number(type)}});
      return optionsMap[value] ?? 'unknown';
    }
    return 'unknown';
  }
}

@NgModule({
  declarations: [ DataTypePipe ],
  exports: [ DataTypePipe ],
})
export class DataTypePipeModule {
}
