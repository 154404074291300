import { XmlParserService } from '@rxap/xml-parser';
import { Injectable } from '@angular/core';
import { LayoutElement } from './elements/layout.element';
import { Widget } from '@digitaix/eurogard-utilities';
import { ParsedElement } from '@rxap/xml-parser/lib/elements/parsed-element';

@Injectable({providedIn: 'root'})
export class LayoutXmlParserService extends XmlParserService {

  constructor() {
    super();
    this.register(LayoutElement);
    this._rootElement = 'layout';
  }

  public parseFromXml<D extends ParsedElement>(xml: string, widgetList: Widget[], ...args: any[]): D {
    const layout: LayoutElement = super.parseFromXml(xml);

    layout.setWidgetObject(widgetList);

    return layout as any;
  }

}
