import type { ElementRef } from '@angular/core';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DeleteEmptyProperties, Required } from '@rxap/utilities';
import { isObservable, Observable, Subscription } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { Gauge, GaugeOptions } from './gauge';

@Component({
  selector: 'rxap-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: [ './gauge.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class GaugeComponent
  implements AfterViewInit, OnDestroy, OnChanges, Omit<GaugeOptions, 'value'> {
  @ViewChild('gaugeContainer', {static: true})
  public gaugeContainer!: ElementRef;

  @Input()
  gaugeContainerClass: string = '';

  /**
   * The angle in degrees to start the dial
   */
  @Input() dialStartAngle?: number;

  /**
   * The angle in degrees to end the dial. This MUST be less than dialStartAngle
   */
  @Input() dialEndAngle?: number;

  /**
   * The radius of the gauge
   */
  @Input() dialRadius?: number;

  /**
   * The minimum value for the gauge
   */
  @Input() min?: number;

  /**
   * The maximum value for the gauge
   */
  @Input() max?: number;

  /**
   * Function that returns a string label that will be rendered in the center. This function will be passed the current
   * value
   */
  @Input() label?: (value: number) => string | null;

  /**
   * Function that returns a string color value for the gauge''s fill (value dial)
   */
  @Input() color?: ((value: number) => string) | null | string;

  /**
   * Whether to show the value at the center of the gauge
   */
  @Input() showValue?: boolean;

  /**
   * The CSS class of the gauge
   */
  @Input() gaugeClass?: string;

  /**
   * The CSS class of the gauge's dial
   */
  @Input() dialClass?: string;

  /**
   * The CSS class of the gauge's fill (value dial)
   */
  @Input() valueDialClass?: string;

  /**
   *  The CSS class of the gauge's text
   */
  @Input() valueClass?: string;

  /**
   * The value of the gauge
   */
  @Input()
  @Required
  value!: number | Observable<number>;

  /**
   * Whether to animate changing the gauge
   */
  @Input() animated: boolean = true;

  /**
   * Animation duration in seconds
   */
  @Input() animationDuration?: number;

  /**
   * Called when the gauge is created
   */
  @Output() gaugeCreated: EventEmitter<{ gauge: any }> = new EventEmitter();

  @Input() unit?: string | null;

  @Input()
  options?: GaugeOptions;

  private gauge: any;

  private _subscription?: Subscription;

  ngAfterViewInit(): void {
    const options: GaugeOptions = Object.assign(
      {},
      DeleteEmptyProperties(this.options ?? {}),
      DeleteEmptyProperties({
        dialStartAngle: this.dialStartAngle,
        dialEndAngle: this.dialEndAngle,
        dialRadius: this.dialRadius,
        min: this.min,
        max: this.max,
        label: this.label,
        showValue: this.showValue,
        gaugeClass: this.gaugeClass,
        dialClass: this.dialClass,
        valueDialClass: this.valueDialClass,
        valueClass: this.valueClass,
        value: 0,
        color: this.color,
        unit: this.unit,
      }),
    );

    this.gauge = new Gauge(this.gaugeContainer.nativeElement, options);

    this.gaugeCreated.emit({gauge: this.gauge});

    this.updateValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.updateValue();
    }
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  private updateValue(): void {
    if (this.gauge) {
      if (isObservable(this.value)) {
        this._subscription?.unsubscribe();
        this._subscription = this.value
        .pipe(
          throttleTime(100),
          tap((value) => this.setValue(value)),
        )
        .subscribe();
      } else {
        this.setValue(this.value);
      }
    }
  }

  private setValue(value: number) {
    if (this.animated) {
      this.gauge.setValueAnimated(value, this.animationDuration);
    } else {
      this.gauge.setValue(value);
    }
  }
}
