import { NgModule } from '@angular/core';
import { InsufficientPermissionsComponent } from './insufficient-permissions.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    InsufficientPermissionsComponent,
  ],
  imports: [
    MatCardModule,
    CommonModule,
    RouterModule,
    MatButtonModule,
  ],
  exports: [
    InsufficientPermissionsComponent,
  ],
})
export class InsufficientPermissionsComponentModule {
}
