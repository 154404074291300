<form
  (matEditLensPreservedFormValueChange)="preservedValues.set(context, $event)"
  [matEditLensClickOutBehavior]="clickOutBehavior"
  [matEditLensPreservedFormValue]="preservedValues.get(context)"
  [submitMethod]="submitMethod"
  matEditLens
  novalidate
  rxapForm>
  <div mat-edit-content>
    <ng-template [cdkPortalOutlet]="portal"></ng-template>
  </div>
  <div mat-edit-actions>
    <button (click)="onSubmit($event)" color="primary" mat-raised-button type="button">
      <ng-container i18n>Confirm</ng-container>
    </button>
    <button mat-stroked-button matEditRevert type="button">
      <ng-container i18n>Reset</ng-container>
    </button>
    <button mat-button matEditClose type="button">
      <ng-container i18n>Close</ng-container>
    </button>
  </div>

</form>
