import { Injectable } from '@angular/core';
import {
  combineLatest,
  defer,
  interval,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs';
import { ToggleSubject } from '@rxap/utilities/rxjs';
import { map, tap } from 'rxjs/operators';
import {
  StatusControllerHealthCheckRemoteMethod,
} from '@eurogard/open-api-service-status/remote-methods/status-controller-health-check.remote-method';
import {
  StatusControllerHealthCheckResponse,
} from '@eurogard/open-api-service-status/responses/status-controller-health-check.response';
import * as Sentry from '@sentry/angular-ivy';
import { OpenApiHttpResponseError } from '@rxap/open-api';

export type ApiStatus = StatusControllerHealthCheckResponse;

@Injectable({providedIn: 'root'})
export class ApiStatusService {

  public readonly isChecking$ = new ToggleSubject(false);
  public readonly isHealthy$: Observable<boolean>;
  private readonly recheck$ = new Subject<void>();
  status$: Observable<ApiStatus> = defer(() => combineLatest(
    [
      interval(60 * 1000),
      this.recheck$,
    ]).pipe(
    tap(() => this.isChecking$.enable()),
    throttleTime(1000 * 10),
    startWith(0),
    switchMap(() => this.getStatus()),
    shareReplay(1),
  ));

  constructor(
    private readonly getApiStatusRemoteMethod: StatusControllerHealthCheckRemoteMethod,
  ) {
    this.isHealthy$ = this.status$.pipe(map(status => status.status === 'ok'));
  }

  public recheck() {
    console.log('recheck');
    this.recheck$.next();
  }

  private async getStatus(): Promise<ApiStatus> {
    let status: ApiStatus = {status: 'panic'};
    try {
      status = await this.getApiStatusRemoteMethod.call();
    } catch (error) {
      if (error instanceof OpenApiHttpResponseError) {
        if (error.status === 503) {
          Sentry.captureMessage('API is unhealthy and has http status 503', {
            level: 'error',
            contexts: {
              status: error.httpErrorResponse.error,
            },
          });
          status = error.httpErrorResponse.error;
        } else {
          Sentry.captureMessage('API is unavailable: ' + error.message, {
            level: 'fatal',
          });
          status = {status: 'unavailable'};
        }
      } else {
        Sentry.captureMessage('API status request failed: ' + error.message, {
          level: 'fatal',
        });
      }
    }
    this.isChecking$.disable();
    return status;
  }

}
