import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDrawerMode } from '@angular/material/sidenav/drawer';
import { FirebaseMessageService } from '@digitaix/eurogard-authentication';
import { StatusControllerVersionRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/status-controller-version.remote-method';
import { Environment, RXAP_ENVIRONMENT } from '@rxap/environment';
import { LayoutComponentService } from '@rxap/layout';
import { ThemeService } from '@rxap/services';
import { DetermineProductionEnvironmentName, DetermineReleaseName } from '@eurogard/setup';

@Component({
  selector: 'rxap-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements AfterViewInit {

  public sidenavMode: MatDrawerMode = 'over';

  @ViewChild(MatSidenav, {static: true}) public sidenav!: MatSidenav;

  constructor(
    public readonly layoutComponentService: LayoutComponentService,
    public readonly theme: ThemeService,
    public readonly versionRemoteMethod: StatusControllerVersionRemoteMethod,
    @Optional()
    @Inject(FirebaseMessageService)
    private readonly fcm: FirebaseMessageService | null,
    @Inject(RXAP_ENVIRONMENT)
    private readonly environment: Environment,
  ) {
    if (this.layoutComponentService.logo?.src) {
      this.layoutComponentService.logo.src += '?timestamp=' + Date.now();
    }
  }

  public get release() {
    return DetermineReleaseName(this.environment);
  }

  public ngAfterViewInit() {
    return this.fcm?.requestToken();
  }

  public toggleSidenavMode() {
    if (this.sidenavMode === 'over') {
      this.sidenavMode = 'side';
      this.sidenav.open();
    } else {
      this.sidenavMode = 'over';
    }
  }
}
