import { Inject, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentComponentModule, RXAP_ENVIRONMENT } from '@rxap/environment';
import { TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompanySelectModule, IconModule, LOCAL_ID_PROVIDER } from '@digitaix/eurogard-utilities';
import { AuthenticationModule, FirebaseMessageService } from '@digitaix/eurogard-authentication';
import { RXAP_WINDOW_DEFAULT_SETTINGS } from '@rxap/window-system';
import { DISABLE_SCHEMA_VALIDATION, DISABLE_VALIDATION, OpenApiConfigService } from '@rxap/open-api';
import { ThemeService } from '@rxap/services';
import { ConfigService } from '@rxap/config';
import { MatNativeDateModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ServiceWorkerSnackBarUpdateModule } from '@rxap/service-worker';
import { strings as germanStrings } from 'ngx-timeago/language-strings/de';
import { ErrorDialogModule } from '@digitaix/eurogard-error-dialog';
import { SOCKET_CONFIG_TOKEN, SocketIoConfig, WrappedSocket } from '@rxap/socket-io';
import { EurogardThemeService } from '@digitaix/eurogard-layout';
import { KeycloakModule, KeycloakService } from '@rxap/keycloak';
import { OpenApiInit } from '@eurogard/setup';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
    this.itemsPerPageLabel = $localize`Einträge pro Seite`;
  }
}

export class GermanTimeagoIntl extends TimeagoIntl {
  strings = germanStrings;
}

export function SocketFactory(config: SocketIoConfig) {
  return new WrappedSocket(config);
}

export function SocketConfigFactory(config: ConfigService) {
  return {
    url: config.get('socket.url'),
    options: {autoConnect: true},
  };
}

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    ErrorDialogModule.forRoot(),

    IconModule,

    MatNativeDateModule,

    AngularFireModule.initializeApp({
      apiKey: "AIzaSyCaaBujshxKsZ_vGNcds6X_FjxuSeg-5MY",
      authDomain: "eurogard-cloud-app.firebaseapp.com",
      databaseURL: "https://eurogard-cloud-app.firebaseio.com",
      projectId: "eurogard-cloud-app",
      storageBucket: "eurogard-cloud-app.appspot.com",
      messagingSenderId: "938611462624",
      appId: "1:938611462624:web:e026037d800080d3531b85",
      measurementId: "G-8Y7LQ17TSX"
    }),
    AngularFireMessagingModule,

    TimeagoModule.forRoot({
      intl: {provide: TimeagoIntl, useClass: GermanTimeagoIntl},
    }),

    CompanySelectModule.forRoot(),

    MatNativeDateModule,

    ServiceWorkerSnackBarUpdateModule,

    EnvironmentComponentModule.set(environment),

    KeycloakModule,
    AuthenticationModule.forRoot({serviceServer: true}),
    ServiceWorkerModule.register('sw-init.js', {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    // FIREBASE_PROVIDERS,
    LOCAL_ID_PROVIDER,
    {
      provide: SOCKET_CONFIG_TOKEN,
      useFactory: SocketConfigFactory,
      deps: [ ConfigService ],
    },
    {
      provide: WrappedSocket,
      useFactory: SocketFactory,
      deps: [ SOCKET_CONFIG_TOKEN ],
    },
    {
      provide: RXAP_ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: RXAP_WINDOW_DEFAULT_SETTINGS,
      useValue: {
        maxWidth: '80vw',
        maxHeight: '80vh',
        minimizable: false,
      },
    },
    {
      provide: ThemeService,
      useClass: EurogardThemeService,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: DISABLE_SCHEMA_VALIDATION,
      useValue: true,
    },
    {
      provide: DISABLE_VALIDATION,
      useValue: true,
    },
    FirebaseMessageService
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {
  constructor(
    @Inject(LOCALE_ID) localId: string,
    config: ConfigService<any>,
    openApiConfig: OpenApiConfigService,
    titleService: Title,
    public readonly theme: ThemeService,
    @Inject(KeycloakService)
    protected readonly keycloak: KeycloakService,
  ) {
    console.log('local', localId);
    if (config.get('app.name', null)) {
      titleService.setTitle(config.getOrThrow('app.name'));
    }
    OpenApiInit(openApiConfig, config);
  }
}
