import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Company } from '@eurogard/open-api-legacy/components/company';
import {
  CompanyControllerGetByUuidRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/company-controller-get-by-uuid.remote-method';

@Pipe({
  name: 'company',
})
export class CompanyPipe implements PipeTransform {

  constructor(private readonly getCompany: CompanyControllerGetByUuidRemoteMethod) {
  }

  public async transform(uuid: string | null): Promise<Company | null> {
    if (uuid) {
      return this.getCompany.call({parameters: {uuid}});
    }
    return null;
  }

}

@NgModule({
  declarations: [ CompanyPipe ],
  exports: [ CompanyPipe ],
})
export class CompanyPipeModule {
}
