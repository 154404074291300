import { NgModule } from '@angular/core';
import { CellContentComponent } from './cell-content.component';
import { WidgetContainerDirectiveModule } from '../widget-container.directive';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WidgetIconComponentModule } from '@digitaix/eurogard-dashboard-widget';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    CellContentComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    WidgetIconComponentModule,
    FlexLayoutModule,
    WidgetContainerDirectiveModule,
  ],
  exports: [
    CellContentComponent,
  ],
})
export class CellContentComponentModule {
}
