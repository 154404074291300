<mat-dialog-content>
  <rxap-data-grid [data]="data" id="http-error-message">
    <ng-container *ngIf="data.status" rxapDataGridRowDef="status"></ng-container>
    <ng-container *ngIf="data.message" rxapDataGridRowDef="message"></ng-container>
    <ng-container *ngIf="data.url" rxapDataGridRowDef="url"></ng-container>
    <ng-container *ngIf="data.method" rxapDataGridRowDef="method"></ng-container>
    <ng-container *ngIf="data.operationId" rxapDataGridRowDef="operationId"></ng-container>
    <ng-container *ngIf="data.upstreamStatusCode" rxapDataGridRowDef="upstreamStatusCode"></ng-container>
    <ng-template [ngIf]="!isProduction">
      <ng-container *ngIf="data.body" rxapDataGridRowDef="body">
        <td *rxapDataGridCellDef="let value">
          <rxap-copy-to-clipboard [value]="value | json">Copy Request Body</rxap-copy-to-clipboard>
          <div class="body">
            <rxap-json-viewer [expanded]="false" [json]="value"></rxap-json-viewer>
          </div>
        </td>
      </ng-container>
    </ng-template>
    <ng-template [ngIf]="!isProduction">
      <ng-container *ngIf="data.config" rxapDataGridRowDef="config">
        <td *rxapDataGridCellDef="let value">
          <rxap-copy-to-clipboard [value]="value | json">Copy Request Config</rxap-copy-to-clipboard>
          <div class="body">
            <rxap-json-viewer [expanded]="false" [json]="value"></rxap-json-viewer>
          </div>
        </td>
      </ng-container>
    </ng-template>
  </rxap-data-grid>
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="true" mat-button>OK</button>
</mat-dialog-actions>
