import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextAnzeigeWidgetComponent } from './text-anzeige-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AutoFontSizeDirectiveModule } from '../utilities/auto-font-size.directive';

@NgModule({
  declarations: [
    TextAnzeigeWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    FlexLayoutModule,
    AutoFontSizeDirectiveModule,
  ],
  exports: [
    TextAnzeigeWidgetComponent,
  ],
})
export class TextAnzeigeWidgetComponentModule {
}
