import { isDevMode } from '@angular/core';
import {
  ElementAttribute,
  ElementChild,
  ElementChildTextContent,
  ElementDef,
  ElementRequired,
} from '@rxap/xml-parser/decorators';
import { ParsedElement, RxapXmlParserValidateError } from '@rxap/xml-parser';
import { Widget } from '@digitaix/eurogard-utilities';
import { BodyElement } from './body.element';
import { CellElement } from './cell.element';
import type { LayoutElement } from './layout.element';

@ElementDef('segment')
export class SegmentElement implements ParsedElement {

  public __parent!: LayoutElement;

  @ElementAttribute({parseValue: Number})
  @ElementRequired()
  public rows!: number;

  @ElementAttribute({parseValue: Number})
  @ElementRequired()
  public columns!: number;

  @ElementChild(BodyElement)
  @ElementRequired()
  public body!: BodyElement;

  @ElementChildTextContent()
  public title!: string;

  public validate(): boolean {
    if (this.rows < 0) {
      throw new RxapXmlParserValidateError('The number is not positive or zero', 'dashboard', 'rows');
    }

    if (this.columns < 0) {
      throw new RxapXmlParserValidateError('The number is not positive or zero', 'dashboard', 'columns');
    }

    if (this.body.rowList.length !== this.rows) {
      throw new RxapXmlParserValidateError(`The defined row count '${ this.rows }' is not equal to the row element count '${ this.body.rowList.length }'`, 'dashboard');
    }

    const spanEffect: Array<Array<boolean>> = Array(this.rows).fill(null).map(() => Array(this.columns).fill(null).map(() => false));

    function FindNextCellInRow(rowIndex: number) {
      return spanEffect[rowIndex].findIndex(cell => !cell);
    }

    for (let rowIndex = 0; rowIndex < this.body.rowList.length; rowIndex++) {
      const row = this.body.rowList[rowIndex];

      for (let cellIndex = 0; cellIndex < row.cellList.length; cellIndex++) {
        const cell = row.cellList[cellIndex];

        const columnIndex = FindNextCellInRow(rowIndex);

        if (columnIndex === -1) {
          throw new Error(`The cell '${ cellIndex }' of row '${ rowIndex }' has not a free cell in the grid.`);
        }

        spanEffect[rowIndex][columnIndex] = true;

        if (cell.rowspan) {
          if (!spanEffect[cell.rowspan + rowIndex - 1]) {
            throw new Error(`The cell has a row span that reaches over the maximal row count.`);
          }
        }

        for (let rowspanOffset = 0; rowspanOffset < (cell.rowspan ?? 1); rowspanOffset++) {
          for (let colspanOffset = 0; colspanOffset < (cell.colspan ?? 1); colspanOffset++) {
            spanEffect[cell.y + rowspanOffset][cell.x + colspanOffset] = true;
          }
        }

      }

    }

    if (spanEffect.some(row => row.some(cell => !cell))) {
      if (isDevMode()) {
        console.log(spanEffect);
      }
      throw new Error('Ensure the each available cell has a defined item');
    }

    return true;
  }

  public setWidgetObject(widgetList: Widget[]) {
    this.body.setWidgetObject(widgetList);
  }

  public forEachCell(cb: (cell: CellElement) => void) {
    this.body.forEachCell(cb);
  }

}
