import { Inject, LOCALE_ID, NgModule, Pipe, PipeTransform } from '@angular/core';
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts';

@Pipe({
  name: 'humanizeDuration',
})
export class HumanizeDurationPipe implements PipeTransform {
  humanizer: HumanizeDuration = new HumanizeDuration(new HumanizeDurationLanguage());

  constructor(@Inject(LOCALE_ID) private readonly localId: string) {
  }

  transform(value: number, language?: string): any {
    if (!language) {
      language = this.localId.replace(/-[A-Z]+$/, '');
    }
    return this.humanizer.humanize(value, {language});
  }
}

@NgModule({
  declarations: [ HumanizeDurationPipe ],
  exports: [ HumanizeDurationPipe ],
})
export class HumanizeDurationPipeModule {
}
