import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { OptionsControllerGetConnectionTypesDetailsRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/options-controller-get-connection-types-details.remote-method';

@Pipe({
  name: 'connectionType',
})
export class ConnectionTypePipe implements PipeTransform {

  constructor(
    private readonly getConnectionTypesDetails: OptionsControllerGetConnectionTypesDetailsRemoteMethod,
  ) {
  }

  public async transform(value: number): Promise<string> {
    const options = await this.getConnectionTypesDetails.call();
    return options[value] ?? 'unknown';
  }
}

@NgModule({
  declarations: [ ConnectionTypePipe ],
  exports: [ ConnectionTypePipe ],
})
export class ConnectionTypePipeModule {
}
