import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { UserControllerFirebaseWebAppTokenParameter } from '../parameters/user-controller-firebase-web-app-token.parameter';
import { UserControllerFirebaseWebAppTokenRequestBody } from '../request-bodies/user-controller-firebase-web-app-token.request-body';
import { UserControllerFirebaseWebAppTokenResponse } from '../responses/user-controller-firebase-web-app-token.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'user-controller-firebaseWebAppToken',
    operation: '{"operationId":"user-controller-firebaseWebAppToken","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"requestBody":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/Update"}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/User"}}}}},"method":"put","path":"/user-controller/firebaseWebAppToken/{uuid}"}'
  })
export class UserControllerFirebaseWebAppTokenRemoteMethod extends OpenApiRemoteMethod<UserControllerFirebaseWebAppTokenResponse, UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody>): Promise<UserControllerFirebaseWebAppTokenResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardUserControllerFirebaseWebAppTokenRemoteMethod]',
    exportAs: 'eurogardUserControllerFirebaseWebAppTokenRemoteMethod'
  })
export class UserControllerFirebaseWebAppTokenRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<UserControllerFirebaseWebAppTokenResponse, OpenApiRemoteMethodParameter<UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerFirebaseWebAppTokenRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerFirebaseWebAppTokenRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerFirebaseWebAppTokenRemoteMethod) remoteMethod: UserControllerFirebaseWebAppTokenRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<UserControllerFirebaseWebAppTokenResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ UserControllerFirebaseWebAppTokenRemoteMethodTemplateDirective ],
    exports: [ UserControllerFirebaseWebAppTokenRemoteMethodTemplateDirective ]
  })
export class UserControllerFirebaseWebAppTokenRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardUserControllerFirebaseWebAppTokenRemoteMethod]',
    exportAs: 'eurogardUserControllerFirebaseWebAppTokenRemoteMethod'
  })
export class UserControllerFirebaseWebAppTokenRemoteMethodDirective extends RemoteMethodDirective<UserControllerFirebaseWebAppTokenResponse, OpenApiRemoteMethodParameter<UserControllerFirebaseWebAppTokenParameter, UserControllerFirebaseWebAppTokenRequestBody>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerFirebaseWebAppTokenRemoteMethod) remoteMethod: UserControllerFirebaseWebAppTokenRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ UserControllerFirebaseWebAppTokenRemoteMethodDirective ],
    exports: [ UserControllerFirebaseWebAppTokenRemoteMethodDirective ]
  })
export class UserControllerFirebaseWebAppTokenRemoteMethodDirectiveModule {
}
