import { Pipe, PipeTransform } from '@angular/core';
import { CellElement } from '@digitaix/eurogard-dashboard-xml-parser';

@Pipe({
  name: 'cellWidth',
})
export class CellWidthPipe implements PipeTransform {

  public transform(cell: CellElement, columns: number): string {
    const cellWidth = 100 / columns;
    return (cellWidth * cell.colspan).toPrecision(6) + '%';
  }

}
