import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ErrorMessageWidget, ISetWidgetDataMethod, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';

@Component({
  selector: 'eurogard-error-message-widget',
  templateUrl: './error-message-widget.component.html',
  styleUrls: [ './error-message-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-error-message-widget'},
})
export class ErrorMessageWidgetComponent implements WidgetComponent {


  @Input()
  public name: string | null = null;

  constructor(@Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>, @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod, @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo) {
  }

  private _content!: ErrorMessageWidget;

  @Input()
  public set content(content: ErrorMessageWidget) {
    this._content = content;
  }

}
