import { Inject, Injectable } from '@angular/core';
import {
  UserControllerGetApiKeyRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/user-controller-get-api-key.remote-method';
import { merge, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationEventType, RxapAuthenticationService } from '@rxap/authentication';
import { EurogardAuthenticationService } from '@digitaix/eurogard-authentication';
import { SERVICE_SERVER_API_KEY } from './tokens';

@Injectable()
export class ServiceServerApiKeyService {

  constructor(
    @Inject(RxapAuthenticationService)
    private readonly authService: EurogardAuthenticationService,
    private readonly getApiKey: UserControllerGetApiKeyRemoteMethod,
    @Inject(SERVICE_SERVER_API_KEY)
    private readonly apiKey: ReplaySubject<string>,
  ) {}

  start() {
    merge(
      this.authService.events$.pipe(
        map(event => event.type === AuthenticationEventType.OnAuthSuccess),
        filter(Boolean),
      ),
      this.authService.isAuthenticated$.pipe(
        filter(Boolean),
      ),
    ).pipe(
      switchMap(() => this.getApiKey.call()),
      map(response => response.api_key),
      filter(Boolean),
      tap(apiKey => this.apiKey.next(apiKey)),
    ).subscribe();
  }

}
