import { NgModule } from '@angular/core';
import { LayoutRendererComponent } from './layout-renderer.component';
import { CommonModule } from '@angular/common';
import { CellContentComponentModule } from '../cell-content/cell-content.component.module';
import { SetWidgetDataMethod } from '../set-widget-data.method';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { WIDGET_SET_DATA_VALUE_METHOD } from '@digitaix/eurogard-dashboard-widget';
import { ConfirmDialogComponentModule } from '@rxap/dialog';
import { WidgetDataDataSource } from '../widget-data.data-source';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SegmentComponentModule } from './segment/segment.component.module';


@NgModule({
  declarations: [
    LayoutRendererComponent,
  ],
  imports: [
    CommonModule,
    CellContentComponentModule,
    MatSnackBarModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    MatProgressBarModule,
    SegmentComponentModule,
  ],
  exports: [
    LayoutRendererComponent,
    ConfirmDialogComponentModule,
  ],
  providers: [
    WidgetDataDataSource,
    {
      provide: WIDGET_SET_DATA_VALUE_METHOD,
      useClass: SetWidgetDataMethod,
    },
  ],
})
export class LayoutRendererComponentModule {
}
