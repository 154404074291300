import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed',
})
export class ToFixedPipe implements PipeTransform {

  public transform(value: number, fractionDigits?: number): string {
    return value.toFixed(fractionDigits);
  }

}

@NgModule({
  declarations: [ ToFixedPipe ],
  exports: [ ToFixedPipe ],
})
export class ToFixedPipeModule {
}
