import { NgModule } from '@angular/core';
import { WidgetIconComponent } from './widget-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { IconDirectiveModule } from '@rxap/material-directives/icon';
import { GetWidgetIconPipeModule } from './get-widget-icon.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    WidgetIconComponent,
  ],
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    IconDirectiveModule,
    GetWidgetIconPipeModule,
    FlexLayoutModule,
  ],
  exports: [
    WidgetIconComponent,
  ],
})
export class WidgetIconComponentModule {
}
