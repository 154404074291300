import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { DemoWidget, ISetWidgetDataMethod, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';

@Component({
  selector: 'eurogard-demo-widget',
  templateUrl: './demo-widget.component.html',
  styleUrls: [ './demo-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-demo-widget'},
})
export class DemoWidgetComponent implements WidgetComponent {
  @Input()
  public name!: string;

  constructor(@Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>, @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod, @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo) {
  }

  private _content!: DemoWidget;

  @Input()
  public set content(content: DemoWidget) {
    this._content = content;
  }
}
