import { NgModule } from '@angular/core';
import { PopoverEditContainerComponent } from './popover-edit-container.component';
import { MatPopoverEditModule } from '@angular/material-experimental/popover-edit';
import { MatButtonModule } from '@angular/material/button';
import { PopoverEditContentDirective } from './popover-edit-content.directive';
import { RxapFormsModule } from '@rxap/forms';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [ PopoverEditContainerComponent, PopoverEditContentDirective ],
  imports: [
    MatPopoverEditModule,
    MatButtonModule,
    RxapFormsModule,
    PortalModule,
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [ PopoverEditContainerComponent, PopoverEditContentDirective ],
})
export class PopoverEditContainerComponentModule {
}
