import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Required } from '@rxap/utilities';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay, filter, startWith, tap } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import type { Navigation, NavigationDividerItem, NavigationItem } from '@rxap/layout';
import { SidenavComponentService } from '@rxap/layout';

@Component({
  selector: 'li[rxap-navigation-item]',
  templateUrl: './navigation-item.component.html',
  styleUrls: [ './navigation-item.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rxap-navigation-item',
  },
  animations: [
    trigger('sub-nav', [
      transition(':enter', [
        style({display: 'block', height: '0', overflow: 'hidden'}),
        animate(150, style({height: '*'})),
      ]),
      transition(':leave', [
        style({overflow: 'hidden'}),
        animate(300, style({height: '0'})),
        style({display: 'none'}),
      ]),
    ]),
  ],
})
export class NavigationItemComponent
  implements OnChanges, OnDestroy {

  private _isActive: boolean = false;

  @HostBinding('class.active')
  get isActive(): boolean {
    return this._isActive || this.open;
  }

  /**
   * Indicates that the sub-nav is expanded
   */
  public open: boolean = false;

  public children: Navigation | null = null;

  @ViewChild(RouterLinkActive, {static: true})
  public routerLinkActive!: RouterLinkActive;

  @Input()
  @Required
  public item!: NavigationItem;

  @Input()
  public level: number = 0;

  set isActive(value: boolean) {
    this._isActive = value;
  }

  private readonly _subscription = new Subscription();

  constructor(
    @Inject(Router)
    private readonly router: Router,
    @Inject(SidenavComponentService)
    public readonly sidenav: SidenavComponentService,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef,
    @Inject(Renderer2)
    private readonly renderer: Renderer2,
    @Inject(Overlay)
    private readonly overlay: Overlay,
    @Inject(ViewContainerRef)
    private readonly viewContainerRef: ViewContainerRef,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      const item: NavigationItem = changes['item'].currentValue;
      this.children =
        item.children && item.children.length ? item.children : null;
    }
  }

  public ngAfterViewInit() {
    this._subscription.add(
      this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(true),
        delay(100),
        tap(() => {
          if (this.routerLinkActive.isActive || this.open) {
            this.isActive = true;
            this.renderer.addClass(this.elementRef.nativeElement, 'active');
          } else {
            this.renderer.removeClass(
              this.elementRef.nativeElement,
              'active',
            );
          }
        }),
      )
      .subscribe(),
    );
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  // region type save item property

  // required to check the type of the item property in the ngFor loop

  public isNavigationDividerItem(
    item: NavigationItem | NavigationDividerItem,
  ): item is NavigationDividerItem {
    return (item as any)['divider'];
  }

  public isNavigationItem(
    item: NavigationItem | NavigationDividerItem,
  ): item is NavigationItem {
    return !this.isNavigationDividerItem(item);
  }

  public asNavigationItem(
    item: NavigationItem | NavigationDividerItem,
  ): NavigationItem {
    if (!this.isNavigationItem(item)) {
      throw new Error('The item is not a NavigationItem');
    }
    return item;
  }

  // endregion
}
