import { NgModule, Pipe, PipeTransform } from '@angular/core';
import {
  OptionsControllerGetDashboardTypesRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/options-controller-get-dashboard-types.remote-method';

@Pipe({
  name: 'dashboardType',
})
export class DashboardTypePipe implements PipeTransform {

  constructor(
    private readonly getType: OptionsControllerGetDashboardTypesRemoteMethod,
  ) {
  }

  public async transform(value: number | string): Promise<string> {
    const optionsMap = await this.getType.call();
    return optionsMap[value] ?? 'unknown';
  }

}

@NgModule({
  exports: [ DashboardTypePipe ],
  declarations: [ DashboardTypePipe ],
})
export class DashboardTypePipeModule {
}
