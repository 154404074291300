import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { UserControllerMeResponse } from '../responses/user-controller-me.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'user-controller-me',
    operation: '{"operationId":"user-controller-me","responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/User"}}}}},"method":"get","path":"/user-controller/me"}'
  })
export class UserControllerMeRemoteMethod extends OpenApiRemoteMethod<UserControllerMeResponse, void, void> {
  public override call(): Promise<UserControllerMeResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardUserControllerMeRemoteMethod]',
    exportAs: 'eurogardUserControllerMeRemoteMethod'
  })
export class UserControllerMeRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<UserControllerMeResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerMeRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerMeRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerMeRemoteMethod) remoteMethod: UserControllerMeRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<UserControllerMeResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ UserControllerMeRemoteMethodTemplateDirective ],
    exports: [ UserControllerMeRemoteMethodTemplateDirective ]
  })
export class UserControllerMeRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardUserControllerMeRemoteMethod]',
    exportAs: 'eurogardUserControllerMeRemoteMethod'
  })
export class UserControllerMeRemoteMethodDirective extends RemoteMethodDirective<UserControllerMeResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerMeRemoteMethod) remoteMethod: UserControllerMeRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ UserControllerMeRemoteMethodDirective ],
    exports: [ UserControllerMeRemoteMethodDirective ]
  })
export class UserControllerMeRemoteMethodDirectiveModule {
}
