import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  VertikalerBalkenWidget,
  WidgetComponent,
  WidgetData,
  WidgetFontSize,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { mostReadable, TinyColor } from '@ctrl/tinycolor';
import { Range } from '@rxap/utilities';
import { CellInfo } from '../cell-info';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'eurogard-vertikaler-balken-widget',
  templateUrl: './vertikaler-balken-widget.component.html',
  styleUrls: [ './vertikaler-balken-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-vertikaler-balken-widget'},
  animations: [
    trigger('grow', [
      state('*', style({height: '{{current}}%'}), {
        params: {last: 0, current: 0},
      }),
      transition(
        '* <=> *',
        [ style({height: '{{last}}%'}), animate('400ms ease-in-out') ],
        {
          params: {last: 0, current: 0},
        },
      ),
    ]),
  ],
})
export class VertikalerBalkenWidgetComponent implements WidgetComponent {

  public color: string = 'rgba(0,0,0,1)';

  public bgColor: string = 'rgba(0,0,0,0.5)';

  public invertColor: string = 'rgba(255,255,255,1)';

  public max: number = 100;

  public min: number = 0;
  public fractionDigits?: number;

  @Input()
  public name: string | null = null;
  public fontSize?: WidgetFontSize;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  private _content!: VertikalerBalkenWidget;

  @Input()
  public set content(content: VertikalerBalkenWidget) {
    this._content = content;
    this.color = this._content.color ?? this.color;
    this.bgColor = new TinyColor(this.color).setAlpha(0.5).toRgbString();
    this.invertColor = mostReadable(new TinyColor(this.color), [
      ...Range.Create(1, 5).toArray().map(x => new TinyColor(this.color).lighten(x * 10)),
      ...Range.Create(1, 5).toArray().map(x => new TinyColor(this.color).darken(x * 10)),
    ])!.toRgbString();
    this.fractionDigits = this._content.fractionDigits;
    this.fontSize = this._content.fontSize;
    this.max = this._content.max ?? this.max;
    this.min = this._content.min ?? this.min;
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  public trackBy(data: WidgetData | null) {
    return data?.uuid ?? uuid();
  }

}
