import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { GaugeWidget, ISetWidgetDataMethod, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import { Required } from '@rxap/utilities';
import { GaugeOptions } from './gauge/gauge';
import { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'eurogard-gauge-widget',
  templateUrl: './gauge-widget.component.html',
  styleUrls: [ './gauge-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-gauge-widget'},
})
export class GaugeWidgetComponent implements WidgetComponent {

  public value: number = 0;

  public options?: GaugeOptions;

  public fractionDigits: number = 0;

  public gaugeContainerClass: string = '';

  @Input()
  public name: string | null = null;

  constructor(
    @Inject(WIDGET_DATA_SOURCE)
    public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD)
    public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
    this.toLabel = this.toLabel.bind(this);
  }

  @Required
  private _content!: GaugeWidget;

  @Input()
  public set content(content: GaugeWidget) {
    this._content = content;
    this.value = this._content.defaultValue ?? this.value;
    this.options = this._content.options;
    this.fractionDigits = this._content.fractionDigits ?? this.fractionDigits;
    this.gaugeContainerClass = this._content.theme ?? this.gaugeContainerClass;
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  public trackBy(data: WidgetData | null) {
    return data?.uuid ?? uuid();
  }

  public toNumber(value: string | number): number {
    const num = Number(value);
    return isNaN(num) ? this.value : num;
  }

  public toLabel(value: number): string | null {
    return isNaN(value) ? null : value.toFixed(this.fractionDigits);
  }

}
