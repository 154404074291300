import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DashboardWidgetConfig, WidgetIcon } from '@digitaix/eurogard-utilities';
import { ConfigService } from '@rxap/config';

@Pipe({
  name: 'getWidgetIcon',
})
export class GetWidgetIconPipe implements PipeTransform {

  public static DEFAULT_WIDGET_ICON: WidgetIcon = {
    icon: 'bug_report',
  };

  constructor(private readonly config: ConfigService) {
  }

  public transform(widget?: { type: string, content?: { icon?: WidgetIcon } }): WidgetIcon {
    if (!widget) {
      return GetWidgetIconPipe.DEFAULT_WIDGET_ICON;
    }
    return widget?.content?.icon ?? this.loadWidgetConfig(widget.type)?.content?.icon ?? GetWidgetIconPipe.DEFAULT_WIDGET_ICON;
  }

  private loadWidgetConfig(type: string | undefined) {

    const widgetList = this.config.get<Array<DashboardWidgetConfig>>('dashboard.widgets');

    return widgetList?.find(w => w.type === type);

  }

}

@NgModule({
  declarations: [ GetWidgetIconPipe ],
  exports: [ GetWidgetIconPipe ],
})
export class GetWidgetIconPipeModule {
}
