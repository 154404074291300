import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageComponent } from './error-message.component';

@Injectable()
export class ErrorMessageInterceptor implements HttpInterceptor {

  constructor(
    @Inject(MatDialog)
    private readonly dialog: MatDialog,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(event => {

        if (event instanceof HttpErrorResponse) {

          const match = event.error && event.error.message && event.error.message.match(/e_(\d+)#/);

          if (match) {
            this.dialog.open(ErrorMessageComponent, {
              data: {
                message: event.error.message.replace(/e_\d+#/, ''),
                code: match[1],
              },
            });
          }
        }

        throw event;

      }),
    );
  }

}
