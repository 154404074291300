import { LOCALE_ID } from '@angular/core';

export function LocalIdFactory() {
  const langMatch = location.pathname.match(/\/(de|en)\//);

  if (langMatch) {
    switch (langMatch[1]) {
      case 'de':
        console.debug('detected language: de');
        return 'de-DE';

      case 'en':
        console.debug('detected language: en');
        return 'en-US';
    }
  } else {
    console.warn('Could not find matching language');
  }

  console.warn('use fallback language: de');

  return 'de-DE';
}

export const LOCAL_ID_PROVIDER = {
  provide: LOCALE_ID,
  useFactory: LocalIdFactory,
};
