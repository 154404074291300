import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GetDashboardByIdMethod } from './get-dashboard-by-id.method';

// TODO : mv to @digitaix/eurogard-*
@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {

  constructor(
    private readonly getDashboardByIdMethod: GetDashboardByIdMethod,
    private readonly router: Router,
  ) {
  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const dashboardId = route.paramMap.get('dashboardId');

    if (!dashboardId) {
      console.error('The dashboard id not defined!');
      return this.router.createUrlTree([ '/', 'dashboard', '404' ]);
    }

    try {
      await this.getDashboardByIdMethod.call(dashboardId);
    } catch (e: any) {
      console.error('Could not load the dashboard by id');
      return this.router.createUrlTree([ '/', 'dashboard', '404' ]);
    }

    return true;
  }

}
