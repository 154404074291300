import * as Joi from 'joi';

export const FirebaseConfigSchema = Joi.object({
  options: Joi.object({
    apiKey: Joi.string().required(),
    projectId: Joi.string().required(),
    messagingSenderId: Joi.string().required(),
    appId: Joi.string().required(),
    measurementId: Joi.string().required()
  }).required(),
  vapid: Joi.string().required(),
});
