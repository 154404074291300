import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutComponent, LayoutModule } from '@digitaix/eurogard-layout';
import { CloudDashboardComponent } from '@portal/shared/cloud-dashboard/cloud-dashboard.component';
import { CloudDashboardComponentModule } from '@portal/shared/cloud-dashboard/cloud-dashboard.component.module';
import { DashboardGuard } from '@portal/shared/dashboard.guard';
import { DashboardResolver } from '@portal/shared/dashboard.resolver';
import { Navigation } from '@rxap/layout';
import {
  AuthGuard,
  InsufficientPermissionsComponent,
  InsufficientPermissionsComponentModule,
  NavigationStatusService,
  PermissionGuard,
} from '@digitaix/eurogard-authentication';
import { StatusCheckGuard } from '@de/shared/status-check.guard';
import { I18nCheckGuard } from '@de/shared/i18n-check.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'error',
    loadChildren: () => import('@de/shared/error').then(m => m.FeatureErrorModule)
  },
  {
    path: 'dashboard/:dashboardId/cloud-dashboard',
    component: CloudDashboardComponent,
    resolve: {
      dashboard: DashboardResolver,
    },
    canActivate: [ DashboardGuard ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ StatusCheckGuard, AuthGuard, I18nCheckGuard ],
    canActivateChild: [ PermissionGuard, StatusCheckGuard ],
    children: [
      {
        path: 'insufficient-permissions',
        component: InsufficientPermissionsComponent,
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('../feature/overview').then((m) => m.FeatureOverviewModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('../feature/notifications').then(
            (m) => m.FeatureNotificationsModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const APP_ROUTER_CONFIG: ExtraOptions = {};

export const navigation: () => Navigation = () => [
  {
    routerLink: [ '/', 'overview' ],
    icon: {svgIcon: 'home'},
    label: $localize`Overview`,
    permissions: [],
    status: [ NavigationStatusService ],
  },
  {
    label: $localize`Messages`,
    routerLink: [ '/', 'notifications' ],
    icon: {svgIcon: 'bell'},
    permissions: [],
    status: [ NavigationStatusService ],
  },
];

@NgModule({
  exports: [ RouterModule ],
  imports: [
    RouterModule.forRoot(APP_ROUTES, APP_ROUTER_CONFIG),
    LayoutModule.withNavigation(navigation),
    InsufficientPermissionsComponentModule,
    CloudDashboardComponentModule,
  ],
})
export class AppRoutingModule {
}
