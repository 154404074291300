import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LanguageSelectorService } from '../../language-selector.service';

@Component({
  selector: 'rxap-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [ './language-selector.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'rxap-language-selector'},
})
export class LanguageSelectorComponent {
  constructor(public readonly language: LanguageSelectorService) {
  }

}
