import { NgModule } from '@angular/core';
import { LanguageSelectorComponent } from './language-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StopPropagationDirectiveModule } from '@rxap/directives';


@NgModule({
  declarations: [ LanguageSelectorComponent ],
  imports: [
    MatSelectModule,
    CommonModule,
    FormsModule,
    StopPropagationDirectiveModule,
  ],
  exports: [ LanguageSelectorComponent ],
})
export class LanguageSelectorComponentModule {
}
