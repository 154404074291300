<div [ngClass]="cellInfo.classList" class="container">
  <div [fxLayout]="cellInfo.aspectRatio > 1 ? 'row' : 'column'" class="inner" fxLayoutAlign="center center"
       fxLayoutGap="20px">
    <button
      #setWidgetData="eurogardSetWidgetData"
      *rxapDataSource="let data from dataSource"
      [ngStyle]="{
        backgroundColor: data?.value | toBoolean | bgColor:enabled:disabled
      }"
      [ref]="ref"
      [value]="!(data?.value | toBoolean)"
      class="button"
      eurogardSetWidgetData
      fxFlex="nogrow"
      i18n-message
      message="Should the data point be changed?">
      <span fxLayout="row" fxLayoutAlign="center center">
        <ng-template #icon>
          <mat-icon [ngStyle]="{ color: data?.value | toBoolean | bgColor:enabled:disabled:true }" class="icon" inline>power_settings_new</mat-icon>
        </ng-template>
        <mat-spinner *ngIf="setWidgetData.executing$ | async; else icon"
                     diameter="20"
                     mode="indeterminate">
        </mat-spinner>
      </span>
    </button>
<!--    <span *ngIf="name" class="name" fxFlex="nogrow">{{name}}</span>-->
  </div>
</div>
