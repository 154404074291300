import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VertikalerBalkenWidgetComponent } from './vertikaler-balken-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToNumberPipeModule } from '../utilities/to-number.pipe';
import { ToFixedPipeModule } from '../utilities/to-fixed.pipe';
import { PercentagePipeModule } from '../utilities/percentage-of-range.pipe';
import { AutoFontSizeDirectiveModule } from '../utilities/auto-font-size.directive';

@NgModule({
  declarations: [
    VertikalerBalkenWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    FlexLayoutModule,
    ToNumberPipeModule,
    ToFixedPipeModule,
    PercentagePipeModule,
    AutoFontSizeDirectiveModule,
  ],
  exports: [
    VertikalerBalkenWidgetComponent,
  ],
})
export class VertikalerBalkenWidgetComponentModule {
}
