import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { StatusControllerVersionResponse } from '../responses/status-controller-version.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'status-controller-version',
    operation: '{"operationId":"status-controller-version","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/status-controller/version"}'
  })
export class StatusControllerVersionRemoteMethod extends OpenApiRemoteMethod<StatusControllerVersionResponse, void, void> {
  public override call(): Promise<StatusControllerVersionResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardStatusControllerVersionRemoteMethod]',
    exportAs: 'eurogardStatusControllerVersionRemoteMethod'
  })
export class StatusControllerVersionRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<StatusControllerVersionResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardStatusControllerVersionRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardStatusControllerVersionRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(StatusControllerVersionRemoteMethod) remoteMethod: StatusControllerVersionRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<StatusControllerVersionResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ StatusControllerVersionRemoteMethodTemplateDirective ],
    exports: [ StatusControllerVersionRemoteMethodTemplateDirective ]
  })
export class StatusControllerVersionRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardStatusControllerVersionRemoteMethod]',
    exportAs: 'eurogardStatusControllerVersionRemoteMethod'
  })
export class StatusControllerVersionRemoteMethodDirective extends RemoteMethodDirective<StatusControllerVersionResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(StatusControllerVersionRemoteMethod) remoteMethod: StatusControllerVersionRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ StatusControllerVersionRemoteMethodDirective ],
    exports: [ StatusControllerVersionRemoteMethodDirective ]
  })
export class StatusControllerVersionRemoteMethodDirectiveModule {
}
