import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { UserControllerGetApiKeyResponse } from '../responses/user-controller-get-api-key.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'user-controller-getApiKey',
    operation: '{"operationId":"user-controller-getApiKey","responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/ApiKeyResponse"}}}}},"method":"get","path":"/user-controller/apikey"}'
  })
export class UserControllerGetApiKeyRemoteMethod extends OpenApiRemoteMethod<UserControllerGetApiKeyResponse, void, void> {
  public override call(): Promise<UserControllerGetApiKeyResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardUserControllerGetApiKeyRemoteMethod]',
    exportAs: 'eurogardUserControllerGetApiKeyRemoteMethod'
  })
export class UserControllerGetApiKeyRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<UserControllerGetApiKeyResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerGetApiKeyRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardUserControllerGetApiKeyRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerGetApiKeyRemoteMethod) remoteMethod: UserControllerGetApiKeyRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<UserControllerGetApiKeyResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ UserControllerGetApiKeyRemoteMethodTemplateDirective ],
    exports: [ UserControllerGetApiKeyRemoteMethodTemplateDirective ]
  })
export class UserControllerGetApiKeyRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardUserControllerGetApiKeyRemoteMethod]',
    exportAs: 'eurogardUserControllerGetApiKeyRemoteMethod'
  })
export class UserControllerGetApiKeyRemoteMethodDirective extends RemoteMethodDirective<UserControllerGetApiKeyResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(UserControllerGetApiKeyRemoteMethod) remoteMethod: UserControllerGetApiKeyRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ UserControllerGetApiKeyRemoteMethodDirective ],
    exports: [ UserControllerGetApiKeyRemoteMethodDirective ]
  })
export class UserControllerGetApiKeyRemoteMethodDirectiveModule {
}
