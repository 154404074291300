import { ParsedElement } from '@rxap/xml-parser';
import { ElementChildren, ElementDef } from '@rxap/xml-parser/decorators';
import { CellElement } from './cell.element';
import { BodyElement } from './body.element';
import { Widget } from '@digitaix/eurogard-utilities';

@ElementDef('row')
export class RowElement implements ParsedElement {

  __parent!: BodyElement;

  @ElementChildren(CellElement)
  public cellList: CellElement[] = [];

  public validate(): boolean {
    return true;
  }

  public setWidgetObject(widgetList: Widget[]) {
    this.cellList.forEach(cell => cell.setWidgetObject(widgetList));
  }

  public forEachCell(cb: (cell: CellElement) => void) {
    this.cellList.forEach(cb);
  }

}
