import { Inject, LOCALE_ID, NgModule, Pipe, PipeTransform } from '@angular/core';
import { TexteDetailsResponse } from '@eurogard/open-api-legacy/responses/texte-details.response';

@Pipe({
  name: 'getTexteLabel',
})
export class GetTexteLabelPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID)
    private readonly locale: string,
  ) {
  }

  public transform(value: Array<TexteDetailsResponse>, language?: number): string | undefined {
    if (language === undefined) {
      language = this.locale === 'en-US' ? 1 : 0;
    }
    return value.find(v => Number(v.language) === language)?.label;
  }

}

@NgModule({
  declarations: [ GetTexteLabelPipe ],
  exports: [ GetTexteLabelPipe ],
})
export class GetTexteLabelPipeModule {
}
