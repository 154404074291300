import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageState',
})
export class MessageStatePipe implements PipeTransform {

  public transform(value: number): string {
    return value === 0 ? '-N' : '-G';
  }

}

@NgModule({
  exports: [ MessageStatePipe ],
  declarations: [ MessageStatePipe ],
})
export class MessageStatePipeModule {
}
