import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PopoverEditContentDirective, PopoverEditContentDirectiveContext } from './popover-edit-content.directive';
import { Required } from '@rxap/utilities';
import { FormDirective, FormSubmitMethod, RXAP_FORM_SUBMIT_METHOD } from '@rxap/forms';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rxap-popover-edit-container',
  templateUrl: './popover-edit-container.component.html',
  styleUrls: [ './popover-edit-container.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {class: 'mfd-popover-edit-container'},
})
export class PopoverEditContainerComponent<Data extends Record<string, any> = Record<string, any>>
  implements AfterViewInit {

  public preservedValues = new WeakMap();

  @Input()
  @Required
  public context!: Data;

  @ContentChild(PopoverEditContentDirective)
  public editContent?: PopoverEditContentDirective;

  public portal: TemplatePortal<PopoverEditContentDirectiveContext> | null = null;

  @Input()
  public clickOutBehavior: 'noop' | 'close' | 'submit' = 'close';

  @ViewChild(FormDirective)
  public formDirective!: FormDirective;

  @Input()
  public submitMethod: FormSubmitMethod<any> | null;

  @Output()
  public update = new EventEmitter();

  constructor(
    @Inject(ViewContainerRef)
    private readonly viewContainerRef: ViewContainerRef,
    @Optional()
    @Inject(RXAP_FORM_SUBMIT_METHOD)
      submitMethod: FormSubmitMethod<any> | null = null,
  ) {
    this.submitMethod = submitMethod;
  }

  public onSubmit($event: any) {
    this.formDirective.onSubmit($event);
    this.update.emit(this.formDirective.value);
  }

  public ngAfterViewInit() {
    if (this.editContent) {
      this.formDirective.form.patchValue(this.context);
      this.portal = new TemplatePortal<PopoverEditContentDirectiveContext>(
        this.editContent.template,
        this.viewContainerRef,
        {$implicit: this.formDirective.form},
      );
    }
  }

}
