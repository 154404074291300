import { NgModule } from '@angular/core';
import { SignOutComponent } from './sign-out.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [ SignOutComponent ],
  imports: [
    MatButtonModule,
    MatIconModule,
  ],
  exports: [ SignOutComponent ],
})
export class SignOutComponentModule {
}
