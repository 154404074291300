import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BildanzeigeWidgetComponent } from './bildanzeige-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { BackgroundImageDirectiveModule } from '@rxap/directives';
import { DetermineImagePipe } from './determine-image.pipe';

@NgModule({
  declarations: [
    BildanzeigeWidgetComponent,
    DetermineImagePipe,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    BackgroundImageDirectiveModule,
  ],
  exports: [
    BildanzeigeWidgetComponent,
  ],
})
export class BildanzeigeWidgetComponentModule {
}
