<div class="container">
  <div class="inner">
    <div *rxapDataSource="let data from dataSource" class="content" fxLayout="column" fxLayoutAlign="center stretch">
      <mat-form-field appearance="outline" fxFlex="grow">
<!--        <mat-label>{{name}}</mat-label>-->
        <input [(ngModel)]="value" [disabled]="!!(setWidgetData.executing$ | async)" [ngStyle]="{ color: color }"
               matInput />
        <button #setWidgetData="eurogardSetWidgetData"
                [ref]="ref"
                [fxHide]="setWidgetData.executing$ | async"
                [value]="value"
                eurogardSetWidgetData
                i18n-message
                mat-icon-button
                matSuffix
                message="Should the data point be changed?">
          <mat-icon>send</mat-icon>
        </button>
        <mat-spinner *ngIf="setWidgetData.executing$ | async" color="accent" diameter="15" fxFlex="nogrow" matSuffix
                     mode="indeterminate"></mat-spinner>
      </mat-form-field>
    </div>
  </div>
</div>
