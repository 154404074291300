import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { ISetWidgetDataMethod, WebansichtWidget, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-webansicht-widget',
  templateUrl: './webansicht-widget.component.html',
  styleUrls: [ './webansicht-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-webansicht-widget'},
})
export class WebansichtWidgetComponent implements WidgetComponent {

  public url?: string;

  public text?: string;

  @Input()
  public name: string | null = null;
  public target: string = '_blank';

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: WebansichtWidget;

  @Input()
  public set content(content: WebansichtWidget) {
    this._content = content;
    this.url = this._content.url;
    this.text = this._content.text ?? 'Open Link';
    this.target = this._content.target ?? this.target;
  }
}
