import { ParsedElement } from '@rxap/xml-parser';
import { ElementAttribute, ElementChildren, ElementDef, ElementRequired } from '@rxap/xml-parser/decorators';
import { Widget } from '@digitaix/eurogard-utilities';
import { CellElement } from './cell.element';
import { SegmentElement } from './segment.element';

@ElementDef('layout')
export class LayoutElement implements ParsedElement {

  @ElementAttribute()
  @ElementRequired()
  public target?: string;

  @ElementChildren(SegmentElement, {group: 'segments'})
  public segments: SegmentElement[] = [];

  @ElementAttribute()
  @ElementRequired()
  public id!: string;

  @ElementAttribute({defaultValue: '1.0.0'})
  @ElementRequired()
  public version!: string;

  public setWidgetObject(widgetList: Widget[]) {
    this.segments.forEach(segment => segment.setWidgetObject(widgetList));
  }

  public forEachCell(cb: (cell: CellElement) => void) {
    this.segments.forEach(segment => segment.forEachCell(cb));
  }

  public validate(): boolean {
    return this.segments.every(segment => segment.validate());
  }

}
