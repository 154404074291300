import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { WIDGET_CELL_INFO } from '../../tokens';
import { CellInfo } from '../../cell-info';
import { Widget } from '@digitaix/eurogard-utilities';

@Component({
  selector: 'eurogard-widget-icon',
  templateUrl: './widget-icon.component.html',
  styleUrls: [ './widget-icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-widget-icon'},
})
export class WidgetIconComponent {

  @Input()
  public widget?: Widget;

  @Input()
  public cellInfo: CellInfo | null;

  constructor(
    @Optional()
    @Inject(WIDGET_CELL_INFO)
      cellInfo: CellInfo | null,
  ) {
    this.cellInfo = cellInfo;
  }

}
