import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Method } from '@rxap/utilities/rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import {
  VpnRouterControllerAccessRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/vpn-router-controller-access.remote-method';
import { ClickOnLink } from '@de/shared/click-on-link';

@Injectable()
export class DownloadVpnConfigMethod implements Method<void, boolean | undefined> {

  private static download(link: string) {
    ClickOnLink(link);
  }

  constructor(
    private readonly getVpnConfig: VpnRouterControllerAccessRemoteMethod,
    private readonly route: ActivatedRoute,
    private readonly deviceService: DeviceDetectorService,
  ) {
  }

  public async call(mobile?: boolean): Promise<void> {

    const uuid = await firstValueFrom(this.route.paramMap.pipe(
      map(paramMap => paramMap.get('uuid')),
      take(1),
    ));

    if (!uuid) {
      throw new Error('Could not extract the uuid from the ActivatedRoute');
    }

    const response = await this.getVpnConfig.call({parameters: {'thing-uuid': uuid, tun: mobile}});

    if (!response['link'] || typeof response['link'] !== 'string') {
      throw new Error('Could not extract the link from the response');
    }

    if (!mobile && this.deviceService.getDeviceInfo().os.toLowerCase() === 'windows') {
      await this.redirect(response['link']);
    } else {
      DownloadVpnConfigMethod.download(response['link']);
    }

  }

  private async redirect(link: string) {
    window.open('srconnect://' + link, '_blank');
  }

}
