<div [ngClass]="cellInfo.classList" class="container">
  <div *rxapDataSource="let dd from dataSource; trackBy: trackBy"
       [fxLayout]="aspectRatio > 1 ? 'row' : 'column'"
       class="inner"
       fxLayoutAlign="center center">
    <div [fxFlex]="aspectRatio > 1 ? '50' : 'nogrow'"
         [ngClass]="aspectRatio > 1 ? 'horizontal' : aspectRatio === 1 ? '' : 'vertical'"
         class="content">
      <rxap-gauge
        [gaugeContainerClass]="gaugeContainerClass"
        [label]="toLabel"
        [options]="options"
        [unit]="dd?.unit?.symbol"
        [value]="dd?.value ?? value | toNumber"
        class="gauge">
      </rxap-gauge>
    </div>
    <div [fxFlex]="aspectRatio > 1 ? '50' : '16px'"
         class="legend" fxLayout="column">
      <!--      <div fxFlex="nogrow"-->
      <!--           fxLayout="row"-->
      <!--           fxLayoutAlign="start center"-->
      <!--           fxLayoutGap="8px">-->
      <!--        <span class="color-info-container" fxFlex="16px">-->
      <!--          <span [ngStyle]="{ backgroundColor: options?.color ?? 'white' }" class="color-info"></span>-->
      <!--        </span>-->
      <!--        <div class="label-container" fxFlex="nogrow">-->
      <!--          <span class="label">{{name}}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="percent-container" fxFlex="nogrow">
        <span class="percent">{{((dd?.value | toNumber) / (options?.max ?? 1) * 100) | toFixed:2}}%</span>
      </div>
    </div>
  </div>
</div>
