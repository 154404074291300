import { InjectionToken } from '@angular/core';
import { WidgetComponent } from '@digitaix/eurogard-utilities';
import { Observable } from 'rxjs';
import { CellInfo } from './cell-info';

export const WIDGET_DATA_SOURCE = new InjectionToken<WidgetComponent['dataSource']>('widget-data-source');
export const WIDGET_SET_DATA_VALUE_METHOD = new InjectionToken<WidgetComponent['method']>('widget-set-data-value-method');
export const WIDGET_DATA_LAST_CHANGE = new InjectionToken<Observable<Date>>('widget-data-last-change');

export const WIDGET_CELL_INFO = new InjectionToken<CellInfo>('cell-info');
export const WIDGET_CELL = new InjectionToken<CellInfo>('cell');
