import { NgModule } from '@angular/core';
import { UserProfileIconComponent } from './user-profile-icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [ UserProfileIconComponent ],
  imports: [
    MatMenuModule,
    MatIconModule,
    CommonModule,
    FlexLayoutModule,
  ],
  exports: [ UserProfileIconComponent ],
})
export class UserProfileIconComponentModule {
}
