<div [ngClass]="cellInfo.classList" class="container">
  <div class="inner" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
    <span [fxHide]="cellInfo.size75 || cellInfo.size104" class="label" fxFlex="nogrow">{{name}}</span>
    <a *ngIf="url; else urlNotDefined"
       [href]="url | santization:'resourceUrl'"
       class="button"
       fxFlex="nogrow"
       [target]="target">
      <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span [fxHide]="cellInfo.size75 || cellInfo.size104">{{text}}</span>
        <mat-icon>arrow_forward</mat-icon>
      </span>
    </a>
    <ng-template #urlNotDefined>
      <span>ERROR: <span i18n>the widget url is not defined</span></span>
    </ng-template>
  </div>
</div>
