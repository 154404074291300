import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CopyToClipboardModule, JsonViewerModule } from '@rxap/components';
import { MessageDialogComponentModule } from '@rxap/dialog';
import { HttpErrorMessageComponent } from './http-error-message.component';
import { DataGridModule } from '@digitaix/eurogard-data-grid';

@NgModule({
  declarations: [ HttpErrorMessageComponent ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    DataGridModule,
    CommonModule,
    JsonViewerModule,
    CopyToClipboardModule,
    MessageDialogComponentModule,
  ],
  exports: [ HttpErrorMessageComponent, MatDialogModule ],
})
export class HttpErrorMessageModule {
}
