<mat-toolbar [color]="color" [ngClass]="{ open: sidenav?.opened }" class="mat-elevation-z3 header">
  <div
    class="content"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <div fxFlex="grow">
      <ng-content></ng-content>
    </div>
    <div fxFlex="grow">

    </div>
    <div fxFlex="nogrow" *ngIf="companySelectService.companyId" fxLayout="row" fxLayoutGap="12px">
      <span i18n class="company-filter-text">Company Filter</span>
      <span class="company-filter-name">{{(companySelectService.companyId | company | async)?.name}}</span>
    </div>
    <rxap-apps-button fxFlex="nogrow"></rxap-apps-button>
    <div *ngIf="menu" fxFlex="nogrow">
      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>settings</mat-icon>
      </button>
    </div>
    <rxap-user-profile-icon fxFlex="nogrow"></rxap-user-profile-icon>
  </div>
</mat-toolbar>

<rxap-navigation-progress-bar></rxap-navigation-progress-bar>
<mat-menu #menu="matMenu">
  <button (click)="theme.toggleDarkTheme()" mat-menu-item>
    <mat-icon *ngIf="theme.darkMode">brightness_2</mat-icon>
    <mat-icon *ngIf="!theme.darkMode">brightness_5</mat-icon>
    <span i18n>Mode</span>
  </button>
  <rxap-language-selector *ngIf="!isDevMode"></rxap-language-selector>
  <div mat-menu-item>
    <mat-form-field appearance="outline" class="company-filter" rxapStopPropagation>
      <mat-label i18n>Company Filter</mat-label>
      <mat-select (ngModelChange)="companySelectService.selectCompany($event)"
                  [ngModel]="companySelectService.companyId">
        <mat-option [value]="null"> ---</mat-option>
        <mat-option *rxapDataSourceCollection="let option from companyOptionsDataSource"
                    [value]="option.value">{{option.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-menu-item>
    <mat-slide-toggle (toggleChange)="toggleHideRouter()" [checked]="hideRouter$ | async">
      <ng-container i18n>Hide Router</ng-container>
    </mat-slide-toggle>
  </div>
</mat-menu>
