import { BaseDataSource, RxapDataSource } from '@rxap/data-source';
import { UserSettingsDto } from '@eurogard/open-api-service-user-settings/components/user-settings-dto';
import { Inject, Injectable, OnInit } from '@angular/core';
import { filter, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import {
  UserSettingsControllerGetByUserIdRemoteMethod,
} from '@eurogard/open-api-service-user-settings/remote-methods/user-settings-controller-get-by-user-id.remote-method';
import { RxapAuthenticationService } from '@rxap/authentication';
import { EurogardAuthenticationService } from '@digitaix/eurogard-authentication';
import { map } from 'rxjs/operators';
import { getFromObject } from '@rxap/utilities';

@RxapDataSource('user-settings')
@Injectable({providedIn: 'root'})
export class UserSettingsDataSource extends BaseDataSource<UserSettingsDto> implements OnInit {

  private readonly _refresh = new Subject<void>();

  constructor(
    @Inject(RxapAuthenticationService)
    private readonly auth: EurogardAuthenticationService,
    private readonly getAllSettings: UserSettingsControllerGetByUserIdRemoteMethod,
  ) {
    super()
  }

  override refresh() {
    this._refresh.next();
  }

  ngOnInit() {
    this._data$ = this._refresh.pipe(
      startWith(true),
      switchMap(() => this.auth.isAuthenticated$.pipe(
        filter(Boolean),
        switchMap(() => this.getAllSettings.call()),
        shareReplay(),
      )));
  }

  select<T = unknown>(propertyPath: string): Observable<T> {
    return this._data$.pipe(
      map(settings => getFromObject(settings, propertyPath)),
    );
  }

}
