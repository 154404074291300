import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LayoutElement, SegmentElement } from '@digitaix/eurogard-dashboard-xml-parser';
import { WidgetDataDataSource } from '../widget-data.data-source';
import { DashboardTranslationService } from '../dashboard-translation.service';

@Component({
  selector: 'eurogard-layout-renderer',
  templateUrl: './layout-renderer.component.html',
  styleUrls: [ './layout-renderer.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {class: 'eurogard-layout-renderer'},
  providers: [ DashboardTranslationService ],
})
export class LayoutRendererComponent implements OnChanges, OnInit {

  @Input()
  public layout!: LayoutElement;

  public segments: SegmentElement[] = [];

  @Input()
  public name?: string;

  @Input()
  public dashboardId?: string;

  constructor(
    public readonly widgetDataService: WidgetDataDataSource,
    private readonly dashboardTranslationService: DashboardTranslationService,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.layout) {
      const layout: LayoutElement | null = changes.layout.currentValue;
      this.applyTranslation(layout);
    }
  }

  public ngOnInit() {
    this.applyTranslation();
  }

  private applyTranslation(layout: LayoutElement | null = this.layout) {
    if (layout) {
      if (this.dashboardId) {
        this.dashboardTranslationService.translate(this.layout, this.dashboardId).then(() => {
          this.widgetDataService?.setLayout(layout);
          this.segments = layout.segments;
          this.cdr.detectChanges();
        }).catch((e) => console.error('translation failed', e));
      } else {
        this.widgetDataService?.setLayout(layout);
        this.segments = layout.segments;
      }
    }
  }

}
