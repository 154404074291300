import { Directive, Inject, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';


export interface PopoverEditContentDirectiveContext {
  $implicit: FormGroup;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[rxapPopoverEditContent]',
})
export class PopoverEditContentDirective {

  constructor(
    @Inject(TemplateRef)
    public readonly template: TemplateRef<PopoverEditContentDirectiveContext>,
  ) {
  }

}
