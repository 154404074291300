import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GetDashboardByIdMethod } from './get-dashboard-by-id.method';
import { Dashboard } from '@digitaix/eurogard-utilities';

// TODO : mv to @digitaix/eurogard-*
@Injectable({
  providedIn: 'root',
})
export class DashboardResolver implements Resolve<Dashboard> {

  constructor(private readonly getDashboardByIdMethod: GetDashboardByIdMethod) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Dashboard> | Dashboard {
    if (!route.paramMap.has('dashboardId')) {
      throw new Error('The dashboard id is not defined in the route parameters.');
    }
    const dashboardId = route.paramMap.get('dashboardId');

    if (!dashboardId) {
      throw new Error('The dashboard is is null or undefined');
    }

    return this.getDashboardByIdMethod.call(dashboardId);
  }

}
