<div class="container" fxLayout="column" fxLayoutGap="16px">
  <rxap-navigate-back-button *ngIf="isInline$ | async" fxFlex="nogrow">
    <h1>{{dashboard?.name}}</h1>
  </rxap-navigate-back-button>
  <mat-divider fxFlex="nogrow"></mat-divider>
  <div fxFlex="nogrow" fxLayout="row" fxLayoutAlign="center start">
    <div class="container" fxFlex="40vw">
      <eurogard-layout-renderer [dashboardId]="dashboard?.uuid" [layout]="layout"
                                [name]="dashboard?.name"></eurogard-layout-renderer>
    </div>
  </div>
</div>

