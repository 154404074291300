import { Pipe, PipeTransform } from '@angular/core';
import { TextEntry, TextListWidget, WidgetData } from '@digitaix/eurogard-utilities';

@Pipe({
  name: 'determineText',
  pure: false,
})
export class DetermineTextPipe implements PipeTransform {

  transform(data: WidgetData | null, content: TextListWidget): TextEntry | null {
    if (content.textList.length == 1) return content.textList[0]; //Return only entry if only entry
    let found = content.default ? content.textList.find(text => text.value === content.default) ?? null : null;
    let value: number = Number(data?.value);
    const textList = content.textList.filter(text => !isNaN(Number(text.value))).sort((a, b) => a.value - b.value);
    if (isNaN(value)) {
      value = 0;
    }
    if (value > Math.max(...textList.map(text => text.value))) {
      return textList.sort((a, b) => b.value - a.value)[0];
    }
    if (value < Math.min(...textList.map(text => text.value))) {
      return textList.sort((a, b) => a.value - b.value)[0];
    }
    for (const text of textList) {
      if (text.value > value) {
        return found;
      }
      if (text.value <= value) {
        found = text;
      }
    }
    return found;
  }

}
