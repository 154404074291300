/**
 * Holds general information about the cell where the widget is placed
 */
export class CellInfo {

  constructor(
    /**
     * The column span of the cell
     */
    public colspan: number,
    /**
     * The row span of the cell
     */
    public rowspan: number,
    /**
     * The top left coordinate position
     */
    public readonly x: number,
    /**
     * The top left coordinate position
     */
    public readonly y: number,
    /**
     * The cell width in pixel
     */
    public width: number,
    /**
     * The cell height in pixel
     */
    public height: number,
  ) {
  }

  public get size75(): boolean {
    return this.width < 104;
  }

  public get size104(): boolean {
    return !this.size75 && this.width < 160;
  }

  public get size160(): boolean {
    return !this.size104 && this.width < 330;
  }

  public get size330(): boolean {
    return !this.size160;
  }

  public get classList(): string[] {
    const aspectRatioClass = this.aspectRatio > 1 ? 'horizontal' : this.aspectRatio === 1 ? '' : 'vertical';
    if (this.size75) {
      return [ 'size75', aspectRatioClass ];
    }
    if (this.size104) {
      return [ 'size104', aspectRatioClass ];
    }
    if (this.size160) {
      return [ 'size160', aspectRatioClass ];
    }
    if (this.size330) {
      return [ 'size330', aspectRatioClass ];
    }
    return [ aspectRatioClass ];
  }

  public get aspectRatio(): number {
    return this.colspan / this.rowspan;
  }

  public get fontSize(): number {
    if (this.size75) {
      return 8;
    }
    if (this.size104) {
      return 10;
    }
    if (this.size160) {
      return 12;
    }
    return 16;
  }

}
