import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable, isDevMode, LOCALE_ID } from '@angular/core';
import { ConfigService } from '@rxap/config';
import {
  UserControllerMeRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/user-controller-me.remote-method';
import { I18nService } from '@de/shared/i18n.service';

@Injectable({providedIn: 'root'})
export class I18nCheckGuard implements CanActivate {

  constructor(
    @Inject(ConfigService)
    private readonly config: ConfigService,
    @Inject(LOCALE_ID)
    private readonly localId: string,
    private readonly getUserProfile: UserControllerMeRemoteMethod,
    private readonly i18nService: I18nService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (isDevMode()) {
      return true;
    }
    const profile = await this.getUserProfile.call();
    const selectedLanguage = Number(profile.userLanguage) === 0 ? 'de' : 'en';
    if (this.i18nService.currentLanguage !== selectedLanguage) {
      await this.i18nService.redirect(selectedLanguage);
      return false;
    }
    return true;
  }

}
