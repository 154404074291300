import { Directive, Inject, NgModule, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[eurogardWidgetContainer],eurogard-widget-container',
})
export class WidgetContainerDirective {

  constructor(
    @Inject(ViewContainerRef)
    public readonly vcr: ViewContainerRef,
  ) {
  }

}

@NgModule({
  declarations: [WidgetContainerDirective],
  exports: [WidgetContainerDirective]
})
export class WidgetContainerDirectiveModule {}
