<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
  <div fxFlex="nogrow">
    <button
      [disableRipple]="true"
      [ngClass]="cellInfo?.classList ?? []"
      [ngStyle]="{ backgroundColor: (widget | getWidgetIcon)?.bgColor }"
      class="button"
      mat-mini-fab>
      <mat-icon [inline]="true" [rxapIcon]="(widget | getWidgetIcon) ?? null" class="icon"></mat-icon>
    </button>
  </div>
  <span [fxHide]="widget?.hideName" class="name" fxFlex="nogrow">{{widget?.name}}</span>
</div>
