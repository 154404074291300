import { ParsedElement, RxapXmlParserValidateError } from '@rxap/xml-parser';
import { ElementAttribute, ElementDef, ElementRequired, ElementTextContent } from '@rxap/xml-parser/decorators';
import { RowElement } from './row.element';
import { Widget } from '@digitaix/eurogard-utilities';

@ElementDef('cell')
export class CellElement implements ParsedElement {

  __parent!: RowElement;

  @ElementAttribute({parseValue: Number, defaultValue: 1})
  public rowspan!: number;

  @ElementAttribute({parseValue: Number, defaultValue: 1})
  public colspan!: number;

  @ElementTextContent()
  public widgetId?: string;

  public widget?: Widget;

  @ElementAttribute({parseValue: Number})
  @ElementRequired()
  public y!: number;

  @ElementAttribute({parseValue: Number})
  @ElementRequired()
  public x!: number;

  public validate(): boolean {
    if (this.rowspan !== undefined && this.rowspan < 1) {
      throw new RxapXmlParserValidateError('The rowspan must be empty of at least one', 'cell', 'rowspan');
    }

    if (this.colspan !== undefined && this.colspan < 1) {
      throw new RxapXmlParserValidateError('The colspan must be empty of at least one', 'cell', 'colspan');
    }

    if (this.x < 0) {
      throw new RxapXmlParserValidateError('The x pos must be positive', 'cell', 'x');
    }

    if (this.y < 0) {
      throw new RxapXmlParserValidateError('The y pos must be positive', 'cell', 'y');
    }

    return true;
  }

  public setWidgetObject(widgetList: Widget[]) {
    this.widget = widgetList.find(widget => widget.internalId === this.widgetId);
  }

}

export interface CellElementWithColspan extends CellElement {
  colspan: number;
}

export function IsCellElementWithColspan(cellElement: CellElement): cellElement is CellElementWithColspan {
  return !!cellElement.colspan;
}
