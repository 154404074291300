import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  SchalterWidget,
  WidgetComponent,
  WidgetData,
  WidgetRef,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-schalter-widget',
  templateUrl: './schalter-widget.component.html',
  styleUrls: [ './schalter-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-schalter-widget'},
})
export class SchalterWidgetComponent implements WidgetComponent {

  public enabled: string | null = null;
  public disabled: string | null = null;
  public enabledText: string | null = null;
  public disabledText: string | null = null;

  public ref?: WidgetRef;

  @Input()
  public name: string | null = null;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: SchalterWidget;

  @Input()
  public set content(content: SchalterWidget) {
    this._content = content;
    this.disabled = this._content.disabled;
    this.disabledText = this._content.disabledText ?? 'OFF';
    this.enabled = this._content.enabled;
    this.enabledText = this._content.enabledText ?? 'ON';
    this.ref = this._content.ref;
  }

}
