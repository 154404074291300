import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SignOutComponentModule } from './sign-out/sign-out.component.module';
import { UserProfileIconComponentModule } from './user-profile-icon/user-profile-icon.component.module';
import {
  NavigationProgressBarComponentModule,
} from './navigation-progress-bar/navigation-progress-bar.component.module';
import { ResetButtonComponentModule } from './reset-button/reset-button.component.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AppsButtonComponentModule } from './apps-button/apps-button.component.module';
import { LanguageSelectorComponentModule } from './language-selector/language-selector.component.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { DataSourceCollectionDirectiveModule } from '@rxap/data-source/directive';
import { StopPropagationDirectiveModule } from '@rxap/directives';
import { CompanyPipeModule } from '@digitaix/eurogard-pipes';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
  declarations: [ HeaderComponent ],
  imports: [
    MatToolbarModule,
    SignOutComponentModule,
    UserProfileIconComponentModule,
    NavigationProgressBarComponentModule,
    ResetButtonComponentModule,
    FlexLayoutModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    AppsButtonComponentModule,
    LanguageSelectorComponentModule,
    MatSelectModule,
    FormsModule,
    DataSourceCollectionDirectiveModule,
    StopPropagationDirectiveModule,
    CompanyPipeModule,
    MatSlideToggleModule,
  ],
  exports: [ HeaderComponent ],
})
export class HeaderModule {
}
