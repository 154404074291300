import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  TextAnzeigeWidget,
  WidgetComponent,
  WidgetData,
  WidgetFontSize,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-text-anzeige-widget',
  templateUrl: './text-anzeige-widget.component.html',
  styleUrls: [ './text-anzeige-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-text-anzeige-widget'},
})
export class TextAnzeigeWidgetComponent implements WidgetComponent {

  public color: string | null = null;

  @Input()
  public name: string | null = null;

  public fontSize?: WidgetFontSize;

  public fontWeight?: string;

  public fontStyle?: string;

  public textAlign?: string;

  public alignItems?: string;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: TextAnzeigeWidget;

  @Input()
  public set content(content: TextAnzeigeWidget) {
    this._content = content;
    this.color = this._content.color;
    this.fontSize = this._content.fontSize;
    this.fontWeight = this._content.bold ? 'bold' : 'normal';
    this.fontStyle = this._content.italic ? 'italic' : 'normal';
    this.textAlign = this._content.align;
    this.alignItems = this._content.vertical;
  }
}
