<ng-template [ngForOf]="items" let-item ngFor>
  <ng-template [ngIf]="isNavigationDividerItem(item)">
    <mat-divider fxFlex="nogrow"></mat-divider>
    <div *ngIf="!(sidenav.collapsed$ | async)" class="divider-title" fxFlex="nogrow" fxLayout="row" fxLayoutAlign="start end">
      <span fxFlex="nogrow" *ngIf="item.title">{{item.title}}</span>
    </div>
  </ng-template>
  <ng-template [ngIf]="isNavigationItem(item)">
    <li class="item" rxap-navigation-item [level]="level" [item]="asNavigationItem(item)"></li>
  </ng-template>
</ng-template>
