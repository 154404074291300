import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanyInterceptor } from './company-interceptor';


@NgModule({})
export class CompanySelectModule {

  public static forRoot(): ModuleWithProviders<CompanySelectModule> {
    return {
      ngModule: CompanySelectModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CompanyInterceptor,
          multi: true,
        },
      ],
    };
  }

}
