import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  StaticTextWidgetContent,
  WidgetComponent,
  WidgetFontSize,
} from '@digitaix/eurogard-utilities';
import { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-static-text-widget',
  templateUrl: './static-text-widget.component.html',
  styleUrls: [ './static-text-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-static-text-widget'},
})
export class StaticTextWidgetComponent implements WidgetComponent {

  public value!: string;

  public color: string | null = null;

  @Input()
  public name: string | null = null;
  public fontSize?: WidgetFontSize;
  public fontWeight?: string;
  public fontStyle?: string;
  public textAlign?: string;
  public alignItems?: string;

  constructor(
    @Inject(WIDGET_DATA_SOURCE)
    public readonly dataSource: BaseDataSource<null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD)
    public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  private _content!: StaticTextWidgetContent;

  @Input()
  public set content(content: StaticTextWidgetContent) {
    this._content = content;
    this.value = this._content.value;
    this.color = this._content.color;
    this.fontSize = this._content.fontSize;
    this.fontWeight = this._content.bold ? 'bold' : 'normal';
    this.fontStyle = this._content.italic ? 'italic' : 'normal';
    this.textAlign = this._content.align;
    this.alignItems = this._content.vertical;
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

}
