<div [ngClass]="cellInfo.classList" [ngStyle]="{ alignItems: alignItems }" class="container">
  <div class="inner" fxLayout="column">
    <div [ngStyle]="{ textAlign: textAlign }" class="overflow">
    <span *rxapDataSource="let data from dataSource"
          [ngStyle]="{
      color: color,
      fontWeight: fontWeight,
      fontStyle: fontStyle }"
          [min]="cellInfo.fontSize"
          [target]="fontSize"
          eurogardAutoFontSize
          [innerText]="data?.value"
          class="value">
    </span>
    </div>
  </div>
</div>
