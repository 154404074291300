import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RxapFormsModule } from '@rxap/forms';
import { EscapeQuotationMarkPipeModule, GetFromObjectPipeModule, ReplacePipeModule } from '@rxap/pipes';
import { DataGridCellDefDirective } from './data-grid-cell-def.directive';
import { DataGridEditCellDefDirective } from './data-grid-edit-cell-def.directive';
import { DataGridComponent } from './data-grid.component';
import { DataGridHeaderCellDefDirective } from './data-grid-header-cell-def.directive';
import { DataGridRowDefDirective } from './data-grid-row-def.directive';
import { DataGridValuePipeModule } from './data-grid-value.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    GetFromObjectPipeModule,
    ReplacePipeModule,
    EscapeQuotationMarkPipeModule,
    RxapFormsModule,
    DataGridValuePipeModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDividerModule,
  ],
  exports: [
    DataGridComponent,
    DataGridRowDefDirective,
    DataGridHeaderCellDefDirective,
    DataGridCellDefDirective,
    DataGridEditCellDefDirective,
  ],
  declarations: [
    DataGridComponent,
    DataGridRowDefDirective,
    DataGridHeaderCellDefDirective,
    DataGridCellDefDirective,
    DataGridEditCellDefDirective,
  ],
})
export class DataGridModule {
}
