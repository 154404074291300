import { Injectable } from '@angular/core';
import { AuthenticationEvent, AuthenticationEventType } from '@rxap/authentication';
import { KeycloakEventType, KeycloakService } from '@rxap/keycloak';
import { from, merge, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class EurogardAuthenticationService {

  public readonly events$: Observable<AuthenticationEvent>;
  public readonly isAuthenticated$: Observable<boolean | null>;

  constructor(private readonly keycloak: KeycloakService) {
    this.events$ = this.keycloak.keycloakEvents$.pipe(
      filter(event => [
        KeycloakEventType.OnAuthError,
        KeycloakEventType.OnAuthLogout,
        KeycloakEventType.OnAuthSuccess,
      ].includes(event.type)),
      map(event => {

        switch (event.type) {

          case KeycloakEventType.OnAuthError:
            return {type: AuthenticationEventType.OnAuthError};

          case KeycloakEventType.OnAuthLogout:
            this.jsBridgeSignOut();
            return {type: AuthenticationEventType.OnLogout};

          case KeycloakEventType.OnAuthSuccess:
            return {type: AuthenticationEventType.OnAuthSuccess};

        }

        return {type: AuthenticationEventType.OnAuthError};

      }),
      shareReplay(),
    );
    this.isAuthenticated$ = merge(
      from(this.keycloak.isLoggedIn()),
      this.events$.pipe(switchMap(() => this.keycloak.isLoggedIn()))
    ).pipe(
      distinctUntilChanged(),
      shareReplay(),
    );
  }

  public signInWithEmailAndPassword() {
    throw new Error('Not supported. currently in keycloak mode');
  }

  public async signOut() {
    if (this.hasJsBridge()) {
      console.log('use js bridge to sign out');
      this.jsBridgeSignOut();
    } else {
      await this.keycloak.logout();
    }
  }

  private hasJsBridge() {
    return (window as any)['jsBridge'] !== undefined;
  }

  private jsBridgeSignOut() {
    const jsBridge = (window as any)['jsBridge'];
    if (jsBridge) {
      jsBridge.signOut();
    } else {
      console.error('js bridge is not present');
    }
  }

  public signInWithProvider() {
    throw new Error('Not supported. currently in keycloak mode');
  }

  public isAuthenticated() {
    return this.keycloak.isLoggedIn();
  }

}
