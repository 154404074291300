import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CellInfo } from '../../cell-info';
import { WIDGET_CELL_INFO } from '../../tokens';

@Component({
  selector: 'eurogard-cell-emulator',
  templateUrl: './cell-emulator.component.html',
  styleUrls: [ './cell-emulator.component.scss' ],
  changeDetection: ChangeDetectionStrategy.Default,
  host: {class: 'eurogard-cell-emulator'},
})
export class CellEmulatorComponent implements AfterViewInit {

  @ViewChild('cell')
  public cell!: ElementRef;
  private _viewInit = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    @Optional()
    @Inject(WIDGET_CELL_INFO)
    public readonly cellInfo: CellInfo | null,
  ) {
  }

  private _width: number = 256;

  public get width(): number {
    return this._width;
  }

  @Input()
  public set width(value: number) {
    this._width = value;
    if (this.cellInfo) {
      this.cellInfo.width = this._width;
      this.cellInfo.height = this._width;
    }
    this.updateCell();
  }

  private _column: number = 1;

  public get column(): number {
    return this._column;
  }

  @Input()
  public set column(value: number) {
    this._column = value;
    if (this.cellInfo) {
      this.cellInfo.colspan = this._column;
    }
    this.updateCell();
  }

  private _row: number = 1;

  public get row(): number {
    return this._row;
  }

  @Input()
  public set row(value: number) {
    this._row = value;
    if (this.cellInfo) {
      this.cellInfo.rowspan = this._row;
    }
    this.updateCell();
  }

  public ngAfterViewInit() {
    this._viewInit = true;
    this.updateCell();
  }

  public updateCell() {
    if (!this._viewInit) {
      // skip render update if view is not yet initialized
      return;
    }
    this.renderer.setStyle(this.cell.nativeElement, 'width', (this.cellInfo ? this.cellInfo.width : this.width * this.column) + 'px');
    this.renderer.setStyle(this.cell.nativeElement, 'height', (this.cellInfo ? this.cellInfo.height : this.width * this.row) + 'px');
    this.cdr.detectChanges();
  }

}
