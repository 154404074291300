import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { getFromObject } from '@rxap/utilities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'dataGridValue',
})
export class DataGridValuePipe<T extends Record<string, any>> implements PipeTransform {

  public transform(data: Observable<T>, path: string): Observable<any> {
    return data.pipe(map(source => getFromObject(source, path)));
  }

}

@NgModule({
  declarations: [ DataGridValuePipe ],
  exports: [ DataGridValuePipe ],
})
export class DataGridValuePipeModule {
}
