import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { RXAP_LAYOUT_APPS_GRID } from '@rxap/layout';
import { AppUrlService, ExternalApps } from '@digitaix/eurogard-utilities';

@Component({
  selector: 'rxap-apps-button',
  templateUrl: './apps-button.component.html',
  styleUrls: [ './apps-button.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'rxap-apps-button'},
})
export class AppsButtonComponent {
  public isOpen = false;
  public readonly appList: Array<ExternalApps> = [];

  constructor(
    @Optional()
    @Inject(RXAP_LAYOUT_APPS_GRID)
      grid: any,
    private readonly appUrlService: AppUrlService,
  ) {
    this.appList = this.appUrlService.getAppList();
  }

}
