import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { MachineControllerGetByUuidParameter } from '../parameters/machine-controller-get-by-uuid.parameter';
import { MachineControllerGetByUuidResponse } from '../responses/machine-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'machine-controller-getByUuid',
    operation: '{"operationId":"machine-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/Machine"}}}}},"method":"get","path":"/machine-controller/get/{uuid}"}'
  })
export class MachineControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<MachineControllerGetByUuidResponse, MachineControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<MachineControllerGetByUuidParameter, void>): Promise<MachineControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardMachineControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardMachineControllerGetByUuidRemoteMethod'
  })
export class MachineControllerGetByUuidRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<MachineControllerGetByUuidResponse, OpenApiRemoteMethodParameter<MachineControllerGetByUuidParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardMachineControllerGetByUuidRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<MachineControllerGetByUuidParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardMachineControllerGetByUuidRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(MachineControllerGetByUuidRemoteMethod) remoteMethod: MachineControllerGetByUuidRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<MachineControllerGetByUuidResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ MachineControllerGetByUuidRemoteMethodTemplateDirective ],
    exports: [ MachineControllerGetByUuidRemoteMethodTemplateDirective ]
  })
export class MachineControllerGetByUuidRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardMachineControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardMachineControllerGetByUuidRemoteMethod'
  })
export class MachineControllerGetByUuidRemoteMethodDirective extends RemoteMethodDirective<MachineControllerGetByUuidResponse, OpenApiRemoteMethodParameter<MachineControllerGetByUuidParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(MachineControllerGetByUuidRemoteMethod) remoteMethod: MachineControllerGetByUuidRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ MachineControllerGetByUuidRemoteMethodDirective ],
    exports: [ MachineControllerGetByUuidRemoteMethodDirective ]
  })
export class MachineControllerGetByUuidRemoteMethodDirectiveModule {
}
