import { Injectable } from '@angular/core';
import { Method } from '@rxap/utilities/rxjs';
import {
  TranslationControllerGetTranslationMapRemoteMethod,
} from '@eurogard/open-api-service-i18n/remote-methods/translation-controller-get-translation-map.remote-method';
import {
  TranslationControllerHasTranslationRemoteMethod,
} from '@eurogard/open-api-service-i18n/remote-methods/translation-controller-has-translation.remote-method';

@Injectable({ providedIn: 'root' })
export class GetTranslationByIdAndLangMethod<Data = Record<string, unknown>> implements Method<Data | null> {

  constructor(
    private readonly translationControllerGetTranslationMapRemoteMethod: TranslationControllerGetTranslationMapRemoteMethod,
    private readonly hasTranslationMethod: TranslationControllerHasTranslationRemoteMethod,
  ) {
  }

  async call({ dashboardId, lang }: { dashboardId: string, lang: string }): Promise<Data | null> {

    const { value: exists } = await this.hasTranslationMethod.call({ parameters: { identifier: dashboardId } });

    if (!exists) {
      return null;
    }

    return this.translationControllerGetTranslationMapRemoteMethod.call({
      parameters: {
        identifier: dashboardId,
        lang,
      },
    }) as any;
  }

}
