import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ISetWidgetDataMethod, TextListWidget, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import { BaseDataSource } from '@rxap/data-source';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eurogard-text-list-widget',
  templateUrl: './text-list-widget.component.html',
  styleUrls: [ './text-list-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-text-list-widget'},
})
export class TextListWidgetComponent implements WidgetComponent<TextListWidget>, OnInit, OnDestroy {

  @Input()
  public name!: string | null;

  public data?: WidgetData;
  @Input()
  public content!: TextListWidget;
  private _subscription?: Subscription;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit() {
    this._subscription = this.dataSource.connect({id: 'eurogard-text-list-widget'}).pipe(
      tap(data => {
        this.data ??= {} as any;
        Object.assign(this.data, data);
        this.cdr.detectChanges();
      }),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

}
