import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DataDefinitionGuiControllerGetByUuidParameter } from '../parameters/data-definition-gui-controller-get-by-uuid.parameter';
import { DataDefinitionGuiControllerGetByUuidResponse } from '../responses/data-definition-gui-controller-get-by-uuid.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'data-definition-gui-controller-getByUuid',
    operation: '{"operationId":"data-definition-gui-controller-getByUuid","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/DataDefinitionDetails"}}}}},"method":"get","path":"/data-definition-gui-controller/get/{uuid}"}'
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethod extends OpenApiRemoteMethod<DataDefinitionGuiControllerGetByUuidResponse, DataDefinitionGuiControllerGetByUuidParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DataDefinitionGuiControllerGetByUuidParameter, void>): Promise<DataDefinitionGuiControllerGetByUuidResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDataDefinitionGuiControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardDataDefinitionGuiControllerGetByUuidRemoteMethod'
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DataDefinitionGuiControllerGetByUuidResponse, OpenApiRemoteMethodParameter<DataDefinitionGuiControllerGetByUuidParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataDefinitionGuiControllerGetByUuidRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<DataDefinitionGuiControllerGetByUuidParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataDefinitionGuiControllerGetByUuidRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataDefinitionGuiControllerGetByUuidRemoteMethod) remoteMethod: DataDefinitionGuiControllerGetByUuidRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DataDefinitionGuiControllerGetByUuidResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataDefinitionGuiControllerGetByUuidRemoteMethodTemplateDirective ],
    exports: [ DataDefinitionGuiControllerGetByUuidRemoteMethodTemplateDirective ]
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDataDefinitionGuiControllerGetByUuidRemoteMethod]',
    exportAs: 'eurogardDataDefinitionGuiControllerGetByUuidRemoteMethod'
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethodDirective extends RemoteMethodDirective<DataDefinitionGuiControllerGetByUuidResponse, OpenApiRemoteMethodParameter<DataDefinitionGuiControllerGetByUuidParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataDefinitionGuiControllerGetByUuidRemoteMethod) remoteMethod: DataDefinitionGuiControllerGetByUuidRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataDefinitionGuiControllerGetByUuidRemoteMethodDirective ],
    exports: [ DataDefinitionGuiControllerGetByUuidRemoteMethodDirective ]
  })
export class DataDefinitionGuiControllerGetByUuidRemoteMethodDirectiveModule {
}
