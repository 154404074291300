<div fxLayout="column" fxLayoutGap="16px">

  <div class="info-container" fxLayout="row" fxLayoutGap="32px">

    <div class="controls" fxFlex="nogrow" fxLayout="row wrap" fxLayoutGap="8px">

      <div fxFlex="nogrow" fxLayout="column">
        <div fxFlex="nogrow" fxLayout="row" fxLayoutGap="32px">
          <span>Width</span>
          <span>{{width}}px</span>
        </div>
        <input [(ngModel)]="width" fxFlex="nogrow" max="512" min="64" type="range">
      </div>

      <div fxFlex="nogrow" fxLayout="column">
        <div fxFlex="nogrow" fxLayout="row" fxLayoutGap="32px">
          <span>Column</span>
          <span>{{column}}</span>
        </div>
        <input [(ngModel)]="column" fxFlex="nogrow" max="6" min="1" type="range">
      </div>

      <div fxFlex="nogrow" fxLayout="column">
        <div fxFlex="nogrow" fxLayout="row" fxLayoutGap="32px">
          <span>Row</span>
          <span>{{row}}</span>
        </div>
        <input [(ngModel)]="row" fxFlex="nogrow" max="6" min="1" type="range">
      </div>

    </div>

    <div *ngIf="cellInfo" class="cell-info">
      <table>
        <tr>
          <th>Width</th>
          <td>{{cellInfo.width}}px</td>
        </tr>
        <tr>
          <th>Height</th>
          <td>{{cellInfo.height}}px</td>
        </tr>
        <tr>
          <th>Colspan</th>
          <td>{{cellInfo.colspan}}</td>
        </tr>
        <tr>
          <th>Rowspan</th>
          <td>{{cellInfo.rowspan}}</td>
        </tr>
      </table>
    </div>

  </div>

  <div class="cell-container" fxFlex="nogrow" fxLayout="row">
    <div #cell class="cell eurogard-cell-content mat-elevation-z12">
      <ng-content></ng-content>
    </div>
    <eurogard-widget-icon></eurogard-widget-icon>
  </div>

</div>

