import { Pipe, PipeTransform } from '@angular/core';
import { mostReadable, TinyColor } from '@ctrl/tinycolor';
import { Range } from '@rxap/utilities';

@Pipe({
  name: 'bgColor',
})
export class BgColorPipe implements PipeTransform {

  /**
   *
   * @param value
   * @param enabled
   * @param disabled
   * @param invert picks the most readable color for the resulting color
   */
  public transform(value: boolean | null, enabled: string | null, disabled: string | null, invert?: boolean): unknown {
    let color: string | null = null;

    switch (value) {

      case true:
        color = enabled;
        break;

      case false:
        color = disabled;
        break;

    }

    if (invert && color) {
      const sourceColor = color;
      color = mostReadable(new TinyColor(sourceColor), [
        ...Range.Create(1, 5).toArray().map(x => new TinyColor(sourceColor).lighten(x * 10)),
        ...Range.Create(1, 5).toArray().map(x => new TinyColor(sourceColor).darken(x * 10)),
      ])!.toRgbString();
    }

    return color;
  }

}
