import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoWidgetComponent } from './demo-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';

@NgModule({
  declarations: [
    DemoWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
  ],
  exports: [
    DemoWidgetComponent,
  ],
})
export class DemoWidgetComponentModule {
}
