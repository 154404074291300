import { Inject, Injectable, LOCALE_ID, OnDestroy, OnInit, Optional } from '@angular/core';
import { LayoutElement } from '@digitaix/eurogard-dashboard-xml-parser';
import {
  DashboardWidgetControllerSocketConnectRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/dashboard-widget-controller-socket-connect.remote-method';
import { WidgetData, WidgetRef } from '@digitaix/eurogard-utilities';
import { BaseDataSource, RxapDataSource } from '@rxap/data-source';
import { WrappedSocket } from '@rxap/socket-io';
import { ToggleSubject } from '@rxap/utilities/rxjs';
import { formatDistanceToNowStrict } from 'date-fns';
import { combineLatest, interval, Observable, ReplaySubject, Subject, Subscription, TeardownLogic } from 'rxjs';
import { finalize, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import {
  DashboardWidgetControllerGetWidgetDataRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/dashboard-widget-controller-get-widget-data.remote-method';

@RxapDataSource('widget-data')
@Injectable()
export class WidgetDataDataSource
  extends BaseDataSource<WidgetData[]>
  implements OnInit, OnDestroy {
  public readonly lastChange$ = new ReplaySubject<Date>(1);
  public readonly dataLastChange$ = new ReplaySubject<Date>(1);
  public readonly distanceToNow$!: Observable<string>;
  public readonly dataChangeDistanceToNow$!: Observable<string>;
  public override readonly loading$ = new ToggleSubject();
  protected override _data$ = new ReplaySubject<WidgetData[]>(1);
  private _layout$ = new ReplaySubject<LayoutElement>(1);
  private readonly _refresh$ = new Subject<void>();
  private _subscription?: Subscription;

  constructor(
    @Inject(DashboardWidgetControllerGetWidgetDataRemoteMethod)
    private readonly getWidgetData: DashboardWidgetControllerGetWidgetDataRemoteMethod,
    @Inject(WrappedSocket)
    @Optional()
    private readonly socket: WrappedSocket | null,
    private readonly createSocketConnection: DashboardWidgetControllerSocketConnectRemoteMethod,
    @Inject(LOCALE_ID)
    private readonly localeId: string,
  ) {
    super();

    const formatDistanceLocale: Record<string, string> = {
      lessThanXSeconds: 'weniger als {{count}} Sekunden',
      xSeconds: '{{count}} Sekunden',
      halfAMinute: 'eine halbe Minute',
      lessThanXMinutes: 'weniger als {{count}} Minuten',
      xMinutes: '{{count}} Minuten',
      aboutXHours: 'ungefähr {{count}} Stunden',
      xHours: '{{count}} Stunden',
      xDays: '{{count}} Tage',
      aboutXWeeks: 'ungefähr {{count}} Wochen',
      xWeeks: '{{count}} Wochen',
      aboutXMonths: 'ungefär {{count}} Monate',
      xMonths: '{{count}} Monate',
      aboutXYears: 'ungefähr {{count}} Jahre',
      xYears: '{{count}} Jahre',
      overXYears: 'mehr als {{count}} Jahre',
      almostXYears: 'fast {{count}} Jahre',
    };

    function formatDistance(token: string, count: number) {
      return formatDistanceLocale[token].replace('{{count}}', count.toFixed(0));
    }

    this.distanceToNow$ = this.lastChange$.pipe(
      switchMap((lastChange) =>
        interval(500).pipe(
          map(() =>
            formatDistanceToNowStrict(lastChange, {
              locale: this.localeId !== 'en-US' ? {
                code: this.localeId,
                formatDistance: formatDistance,
              } : undefined,
              roundingMethod: 'ceil',
              unit: 'second',
            }),
          ),
        ),
      ),
      shareReplay(1),
    );
    this.dataChangeDistanceToNow$ = this.dataLastChange$.pipe(
      switchMap((lastChange) =>
        interval(500).pipe(
          map(() =>
            formatDistanceToNowStrict(lastChange, {
              locale: this.localeId !== 'en-US' ? {
                code: this.localeId,
                formatDistance: formatDistance,
              } : undefined,
              roundingMethod: 'ceil',
              // unit: 'second',
            }),
          ),
        ),
      ),
      shareReplay(1),
    );
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription?.unsubscribe();
  }

  public ngOnInit() {
    this.loading$.enable();
    this._subscription = new Subscription();

    const refList$ = this._layout$.pipe(
      map((layout) => {
        const refList: WidgetRef[] = [];

        layout.forEachCell((cell) => {
          if (cell.widget) {
            const ref = cell.widget.content.ref;
            if (ref) {
              if (Array.isArray(ref)) {
                refList.push(...ref);
              } else {
                refList.push(ref);
              }
            }
          }
        });

        const clearRefList = refList.filter(
          (ref, index, self) =>
            self.findIndex(
              (r) =>
                r.dataDefinition === ref.dataDefinition &&
                r.machine == ref.machine,
            ) === index,
        );

        return clearRefList;
      }),
      shareReplay(1),
    );

    const triggerRequest$ = combineLatest([
      this._refresh$.pipe(
        startWith(null),
        tap(() => this.loading$.enable()),
      ),
      refList$,
    ]).pipe(map(([ _, refList ]) => refList));

    this._subscription.add(triggerRequest$.pipe(
      switchMap(async (refList) => {
        let data: WidgetData[] = [];

        try {
          data = (await this.getWidgetData.call({
            requestBody: refList,
          })) as any as WidgetData[];
        } catch (e: any) {
          console.error('failed to load widget data: ' + e.message);
        }

        return data;
      }),
      tap(data => this._data$.next(data)),
    ).subscribe());

    this._subscription.add(this.connectToSocket(refList$));

    this._subscription.add(this._data$.pipe(
      tap(data => {
        const lastChange = new Date(data.map((item: any) => item.timestamp).filter(Boolean).sort().reverse()[0] ?? Date.now());
        this.dataLastChange$.next(lastChange);
        this.lastChange$.next(new Date());
      }),
      tap(() => this.loading$.disable()),
    ).subscribe());

  }

  private connectToSocket(refList$: Observable<WidgetRef[]>): TeardownLogic {
    if (!this.socket) {
      return;
    }

    return refList$.pipe(
      tap(async refList => {

        const response = await this.createSocketConnection.call({requestBody: refList});

        this.socket?.on(
          'widget-data',
          (data: WidgetData[]) => this._data$.next(data),
        );

        this.socket?.emit('widget-data', (response as any).jwt);

      }),
      finalize(() =>
        this.socket?.removeAllListeners('widget-data'),
      ),
    ).subscribe();

  }

  public setLayout(layout: LayoutElement) {
    this._layout$.next(layout);
  }

  public override refresh() {
    this._refresh$.next();
  }
}
