import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  isDevMode,
  NgModule,
  NgZone,
  OnChanges,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CountUp, CountUpOptions } from './count-up';

@Directive({
  selector: '[countUp]',
})
export class CountUpDirective implements OnChanges {

  countUp!: CountUp;
  // the value you want to count to
  @Input('countUp') endVal!: number;
  // previous end val enables us to count from last endVal
  // when endVal is changed
  previousEndVal!: number;

  @Input() options: CountUpOptions = {};
  @Input() reanimateOnClick = true;
  @Output() complete = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  // Re-animate if preference is set.
  @HostListener('click')
  onClick() {
    if (this.reanimateOnClick) {
      this.animate();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // don't animate server-side (universal)
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (changes.endVal && changes.endVal.currentValue !== undefined) {
      if (this.previousEndVal !== undefined) {
        this.options = {
          ...this.options,
          startVal: this.previousEndVal,
        };
      }
      if (isDevMode()) {
        console.log('count up options: ', this.options);
      }
      this.countUp = new CountUp(this.el.nativeElement, this.endVal, this.options);
      this.animate();
      this.previousEndVal = this.endVal;
    }
  }

  private animate() {
    this.zone.runOutsideAngular(() => {
      this.countUp.reset();
      this.countUp.start(() => {
        this.zone.run(() => {
          this.complete.emit();
        });
      });
    });
  }
}

@NgModule({
  declarations: [ CountUpDirective ],
  exports: [ CountUpDirective ],
})
export class CountUpDirectiveModule {
}
