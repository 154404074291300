import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerLanguageResponse } from '../responses/options-controller-language.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-language',
    operation: '{"operationId":"options-controller-language","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/language"}'
  })
export class OptionsControllerLanguageRemoteMethod extends OpenApiRemoteMethod<OptionsControllerLanguageResponse, void, void> {
  public override call(): Promise<OptionsControllerLanguageResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerLanguageRemoteMethod]',
    exportAs: 'eurogardOptionsControllerLanguageRemoteMethod'
  })
export class OptionsControllerLanguageRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerLanguageResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerLanguageRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerLanguageRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerLanguageRemoteMethod) remoteMethod: OptionsControllerLanguageRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerLanguageResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerLanguageRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerLanguageRemoteMethodTemplateDirective ]
  })
export class OptionsControllerLanguageRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerLanguageRemoteMethod]',
    exportAs: 'eurogardOptionsControllerLanguageRemoteMethod'
  })
export class OptionsControllerLanguageRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerLanguageResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerLanguageRemoteMethod) remoteMethod: OptionsControllerLanguageRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerLanguageRemoteMethodDirective ],
    exports: [ OptionsControllerLanguageRemoteMethodDirective ]
  })
export class OptionsControllerLanguageRemoteMethodDirectiveModule {
}
