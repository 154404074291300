import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetDataDefinitionTypeResponse } from '../responses/options-controller-get-data-definition-type.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getDataDefinitionType',
    operation: '{"operationId":"options-controller-getDataDefinitionType","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/data-definition-type"}'
  })
export class OptionsControllerGetDataDefinitionTypeRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetDataDefinitionTypeResponse, void, void> {
  public override call(): Promise<OptionsControllerGetDataDefinitionTypeResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetDataDefinitionTypeRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDataDefinitionTypeRemoteMethod'
  })
export class OptionsControllerGetDataDefinitionTypeRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetDataDefinitionTypeResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDataDefinitionTypeRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDataDefinitionTypeRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDataDefinitionTypeRemoteMethod) remoteMethod: OptionsControllerGetDataDefinitionTypeRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetDataDefinitionTypeResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDataDefinitionTypeRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetDataDefinitionTypeRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetDataDefinitionTypeRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetDataDefinitionTypeRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDataDefinitionTypeRemoteMethod'
  })
export class OptionsControllerGetDataDefinitionTypeRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetDataDefinitionTypeResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDataDefinitionTypeRemoteMethod) remoteMethod: OptionsControllerGetDataDefinitionTypeRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDataDefinitionTypeRemoteMethodDirective ],
    exports: [ OptionsControllerGetDataDefinitionTypeRemoteMethodDirective ]
  })
export class OptionsControllerGetDataDefinitionTypeRemoteMethodDirectiveModule {
}
