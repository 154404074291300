import { Inject, Injectable, OnDestroy } from '@angular/core';
import { KeycloakService } from '@rxap/keycloak';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserSettingsDataSource } from '@de/shared/user-settings.data-source';
import { BaseDataSourceViewer } from '@rxap/data-source';
import { ToggleDarkModeMethod } from './toggle-dark-mode.method';

@Injectable()
export class EurogardThemeService implements OnDestroy {

  private _subscription?: Subscription;

  private readonly viewer: BaseDataSourceViewer = { id: 'eurogard-theme-service' };

  public baseFontSize = 62.5;

  public darkMode: boolean = true;

  public readonly darkMode$: Observable<boolean>;

  constructor(
    private readonly userSettingsDataSource: UserSettingsDataSource,
    private readonly toggleDarkMode: ToggleDarkModeMethod,
    @Inject(KeycloakService)
    private readonly keycloak: KeycloakService,
  ) {
    this.darkMode$ = this.userSettingsDataSource.connect(this.viewer).pipe(
      map(settings => settings.darkMode),
      tap(darkMode => this.applyThemeMode(darkMode))
    );

    this.userSettingsDataSource.connect(this.viewer).pipe(
      map(settings => settings.darkMode),
      tap(darkMode => this.darkMode = darkMode),
      tap(darkMode => this.toggleDarkTheme(darkMode)),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  private applyThemeMode(darkMode: boolean) {
    if (darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  public toggleDarkTheme(darkMode: boolean = !this.darkMode) {
    if (this.darkMode !== darkMode) {
      this.toggleDarkMode.call();
    }
    this.darkMode = darkMode;
    this.applyThemeMode(darkMode);
  }

  public setBaseFontSize(value: number) {};

}
