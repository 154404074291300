import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { OpenApiConfigService } from '@rxap/open-api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EurogardAuthenticationService } from './eurogard-authentication.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  constructor(
    private readonly openApiService: OpenApiConfigService,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.openApiService.getBaseUrl())) {
      return next.handle(req).pipe(
        catchError(error => {

          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.authenticationService.signOut();
            }
          }

          return throwError(error);

        }),
      );
    }
    return next.handle(req);
  }


}
