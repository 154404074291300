import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetModbusFunctionsResponse } from '../responses/options-controller-get-modbus-functions.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getModbusFunctions',
    operation: '{"operationId":"options-controller-getModbusFunctions","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/modbus-functions"}'
  })
export class OptionsControllerGetModbusFunctionsRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetModbusFunctionsResponse, void, void> {
  public override call(): Promise<OptionsControllerGetModbusFunctionsResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetModbusFunctionsRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetModbusFunctionsRemoteMethod'
  })
export class OptionsControllerGetModbusFunctionsRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetModbusFunctionsResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetModbusFunctionsRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetModbusFunctionsRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetModbusFunctionsRemoteMethod) remoteMethod: OptionsControllerGetModbusFunctionsRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetModbusFunctionsResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetModbusFunctionsRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetModbusFunctionsRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetModbusFunctionsRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetModbusFunctionsRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetModbusFunctionsRemoteMethod'
  })
export class OptionsControllerGetModbusFunctionsRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetModbusFunctionsResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetModbusFunctionsRemoteMethod) remoteMethod: OptionsControllerGetModbusFunctionsRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetModbusFunctionsRemoteMethodDirective ],
    exports: [ OptionsControllerGetModbusFunctionsRemoteMethodDirective ]
  })
export class OptionsControllerGetModbusFunctionsRemoteMethodDirectiveModule {
}
