import { ConnectedPosition } from '@angular/cdk/overlay/position/flexible-connected-position-strategy';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { random, TinyColor } from '@ctrl/tinycolor';
import {
  DashboardWidgetControllerGetWidgetSetResponse,
} from '@eurogard/open-api-legacy/responses/dashboard-widget-controller-get-widget-set.response';
import {
  ChartType,
  ChartWidget,
  ISetWidgetDataMethod,
  WidgetComponent,
  WidgetData,
  WidgetRef,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { coerceArray, unique } from '@rxap/utilities';
import {
  CategoryScale,
  Chart,
  ChartConfiguration,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import format from 'date-fns/format';
import { interval, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL, WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import {
  DashboardWidgetControllerGetWidgetSetRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/dashboard-widget-controller-get-widget-set.remote-method';
import * as Sentry from '@sentry/angular-ivy';
import { CellElement } from '@digitaix/eurogard-dashboard-xml-parser';

@Component({
  selector: 'eurogard-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: [ './chart-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-chart-widget'},
})
export class ChartWidgetComponent implements WidgetComponent<ChartWidget>, AfterViewInit, OnInit, OnDestroy {

  public config: ChartConfiguration = {
    type: 'line',
    data: {
      datasets: [],
    },
    options: {},
  };
  public condition: string = '30m';
  public interval: number = 60;
  public connectedPosition: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'center',
      overlayY: 'top',
      overlayX: 'end',
    },
  ];
  @Input()
  public name: string | null = null;
  public isOpen: boolean = false;

  /**
   * true - an error was thrown while loading chart data
   */
  public hasError: boolean = false;

  /**
   * true - the initial chart data loading is successfully completed
   */
  public isReady: boolean = false;

  private _ref: WidgetRef[] = [];
  @ViewChild('chart', {static: true})
  private canvas!: ElementRef;
  private _chart: Chart | null = null;
  private _hiddenIndexes: number[] = [];
  private _colorList: TinyColor[] | null = null;
  private _data: Array<DashboardWidgetControllerGetWidgetSetResponse & { color?: string }> = [];
  private _autoRefreshSubscription?: Subscription;

  /**
   * holds the timestamp of the last end timestamp used for the auto refresh request.
   *
   * Used to prevent that the request as a timespan from 0 to Date.now() and crash the influx database
   *
   * Initial the value is set to that timestamp of the loadChartData. This is required bc if the chart does not have
   * any data then the "0 to Date.now()"-bug occurs.
   * @private
   */
  private _lastAutoRefreshEndTimestamp: number[] = [];

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData[]>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    private readonly getWidgetSet: DashboardWidgetControllerGetWidgetSetRemoteMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
    @Inject(WIDGET_CELL) private readonly cell: CellElement,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (isDevMode()) {
      console.log('create new chart widget instance');
    }
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: ChartWidget;

  @Input()
  public set content(content: ChartWidget) {
    if (isDevMode()) {
      console.log('set content', content);
    }
    this._content = content;
    this.config.options = content.options ?? {};
    this.forceOptions();
    this.interval = content.interval ?? this.interval;
    this.condition = content.condition ?? this.condition;
    this._ref = coerceArray(content.ref);
  }

  public ngOnDestroy() {
    this._autoRefreshSubscription?.unsubscribe();
  }

  public ngOnInit() {
    Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Legend, Tooltip);
  }

  public ngAfterViewInit() {
    this.forceOptions();
    this._chart = new Chart(this.canvas.nativeElement.getContext('2d')!, this.config);
    this.loadChartData();
    // this.initSocket(this._ref);
  }

  public async onLegendClick(event: any, legendItem: any, legend: any) {
    if (isDevMode()) {
      console.log(`onLegendClick`, event, legendItem, legend);
    }
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
      ci.hide(index);
      legendItem.hidden = true;
      this._hiddenIndexes.push(index);
    } else {
      ci.show(index);
      legendItem.hidden = false;
      this._hiddenIndexes = this._hiddenIndexes.filter(i => i !== index);
    }
  }

  public async loadChartData(options: { interval?: number, condition?: string } = {}) {
    try {
      this._data = await Promise.all(this._ref.map(async ref => {
        const response = await this.getWidgetSet.call({
          parameters: {
            'machine-uuid': ref.machine,
            'data-definition-uuid': ref.dataDefinition,
            start: 0,
            end: 0,
            interval: this.interval,
            condition: this.condition,
            ...options,
          },
        });
        return {...response, color: ref.color};
      }));
      // set the last auto refresh end timestamp to ensure that it is not 0
      this._lastAutoRefreshEndTimestamp = Array(this._data.length).fill(Date.now());
    } catch (e: any) {
      Sentry.captureMessage(
        'Widget Chart data loading failed: ' + e.message,
        {
          level: 'error',
          contexts: {
            cell: {
              widgetId: this.cell.widgetId ?? 'unknown',
              y: this.cell.y,
              x: this.cell.x,
            },
            refList: this._ref.map((ref, index) => ({[index]: ref})).reduce((acc, item) => ({...acc, ...item}), {}),
          },
        },
      );
      this.enterErrorState();
      return;
    }

    this.setChartData();

    this.isReady = true;

    if (this._content.refresh) {
      this.autoRefresh(this._content.refresh);
    }
  }

  private forceOptions() {
    this.config.options!.aspectRatio = this.aspectRatio;
    if ((this.config.options as any).spanGaps === undefined) {

    }
    (this.config.options as any).spanGaps = this._content?.type !== ChartType.FILL_NULL;
    // (this.config.options! as any).showLine = this._content?.type === ChartType.FILL_NULL;
    this.config.options!.plugins ??= {};
    this.config.options!.plugins.legend ??= {};
    this.config.options!.plugins.legend.position ??= 'bottom';
    this.config.options!.plugins.legend.align ??= 'start';
    this.config.options!.plugins.legend.onClick = this.onLegendClick.bind(this);
  }

  /**
   *
   * @param autoRefresh true - disable animation on chart update
   * @private
   */
  private setChartData(autoRefresh: boolean = false) {
    function formatDateToString(value: number | undefined): string | null {
      return value ? format(value, 'HH:mm:ss') : null;
    }

    if (!this._chart) {
      throw new Error('The chart is not defined');
    }

    const xPointList = this._data
    .map(d => d.set?.valueList ?? [])
    .map(list => list.map(item => Number(item.timestamp)).filter(tp => !isNaN(tp) && !!tp))
    .reduce((a, b) => [ ...a, ...b ], [])
    .filter(unique())
    .sort((a, b) => a - b);
    this._chart.data.labels = xPointList.map(formatDateToString);
    const colorList = this.getColorList(this._data.length);
    this._chart.data.datasets = this._data.filter(d => d.set).map((d, index) => {
      const color = d.color ? new TinyColor(d.color) : colorList[index];
      return ({
        label: (d as any).name,
        backgroundColor: color?.toRgbString(),
        borderColor: color?.lighten(10).toRgbString(),
        pointRadius: 1,
        data: (d.set?.valueList ?? [])
        // .filter(item => this._content.type === ChartType.FILL_0 || item.value === 0 || item.value)
        .sort((a, b) => a.timestamp! - b.timestamp!)
        .map(item => ({
          x: formatDateToString(item.timestamp) as any,
          y: item.value ?? (this._content?.type === ChartType.FILL_0 ? 0 : item.value as any),
        })),
        hidden: this._hiddenIndexes.includes(index),
      });
    });
    this._chart.update(autoRefresh ? 'none' : undefined);
  }

  retry() {
    this.hasError = false;
    this.loadChartData();
  }

  private autoRefresh(refresh: number) {
    this._autoRefreshSubscription?.unsubscribe();
    this._autoRefreshSubscription = interval(refresh).pipe(
      filter(() => !this.hasError && this.isReady),
      map(() => Date.now()),
      tap(async (end) => {
        try {
          await Promise.all(this._data.map(async (data, index) => {

            if (!data.machine || !data.uuid || !data.timestamp) {
              throw new Error('The widget data is invalid!');
            }

            const lastAutoRefreshEndTimestamp = this._lastAutoRefreshEndTimestamp[index] ?? end - this.interval * 1000;

            let start = data.set!.valueList!.sort((a, b) => b.timestamp! - a.timestamp!)[0]?.timestamp ?? 0;

            // prevent the "0 to Date.now()"-bug (see docs of the this._lastAutoRefreshEndTimestamp property)
            if (start === 0) {
              start = lastAutoRefreshEndTimestamp;
            }

            if (lastAutoRefreshEndTimestamp - start > this.interval * 1000) {
              // prevent that the auto refresh interval size is larger the 2 * this.interval
              start = lastAutoRefreshEndTimestamp;
            }

            if (start === 0) {
              throw new Error('PANIC: the auto refresh start time is 0');
            }

            const next = await this.getWidgetSet.call({
              parameters: {
                'machine-uuid': data.machine,
                'data-definition-uuid': data.uuid,
                start,
                end,
                interval: this.interval,
              },
            });

            this._lastAutoRefreshEndTimestamp[index] = end;


            const valueList = [ ...next.set!.valueList!, ...data.set!.valueList! ]
            .filter((value, index, self) => self.findIndex(point => point.timestamp === value.timestamp) === index)
            .sort((a, b) => a.timestamp! - b.timestamp!);

            valueList.splice(0, valueList!.length - data.set!.valueList!.length);

            data.set!.valueList = valueList;

          }));
        } catch (e: any) {
          Sentry.captureMessage(
            'Widget Chart data AUTO refresh failed: ' + e.message,
            {
              level: 'error',
              contexts: {
                cell: {
                  widgetId: this.cell.widgetId ?? 'unknown',
                  y: this.cell.y,
                  x: this.cell.x,
                },
                refList: this._ref.map((ref, index) => ({[index]: ref})).reduce((acc, item) => ({...acc, ...item}), {}),
              },
            },
          );
          this.enterErrorState();
          return;
        }

        this.setChartData(true);
      }),
    ).subscribe();
  }

  private getColorList(count: number): TinyColor[] {
    if (!this._colorList || this._colorList.length !== count) {
      this._colorList = [];
      for (let i = 0; i < count; i++) {
        this._colorList.push(random({seed: i * 8}));
      }
    }
    return this._colorList.slice().map(color => color.clone());
  }

  private enterErrorState() {
    this.hasError = true;
    this.cdr.detectChanges();
  }
}
