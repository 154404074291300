<div [ngClass]="cellInfo.classList" [ngStyle]="{ alignItems: (data | determineText:content)?.vertical }"
     class="container">
  <div class="inner">
    <div [ngStyle]="{ textAlign: (data | determineText:content)?.align }" class="overflow">
      <span *ngIf="content.label">{{content.label}}<br><br></span>
      <span [innerText]="(data | determineText:content)?.text"
            [min]="cellInfo.fontSize"
            [ngStyle]="{
      color: (data | determineText:content)?.color,
      fontWeight: (data | determineText:content)?.bold,
      fontStyle: (data | determineText:content)?.italic }"
            [target]="(data | determineText:content)?.fontSize"
            class="value"
            eurogardAutoFontSize>
      </span>
    </div>
  </div>
</div>
