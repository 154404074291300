import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetScalingModeResponse } from '../responses/options-controller-get-scaling-mode.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getScalingMode',
    operation: '{"operationId":"options-controller-getScalingMode","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/scaling-mode"}'
  })
export class OptionsControllerGetScalingModeRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetScalingModeResponse, void, void> {
  public override call(): Promise<OptionsControllerGetScalingModeResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetScalingModeRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetScalingModeRemoteMethod'
  })
export class OptionsControllerGetScalingModeRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetScalingModeResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetScalingModeRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetScalingModeRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetScalingModeRemoteMethod) remoteMethod: OptionsControllerGetScalingModeRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetScalingModeResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetScalingModeRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetScalingModeRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetScalingModeRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetScalingModeRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetScalingModeRemoteMethod'
  })
export class OptionsControllerGetScalingModeRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetScalingModeResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetScalingModeRemoteMethod) remoteMethod: OptionsControllerGetScalingModeRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetScalingModeRemoteMethodDirective ],
    exports: [ OptionsControllerGetScalingModeRemoteMethodDirective ]
  })
export class OptionsControllerGetScalingModeRemoteMethodDirectiveModule {
}
