import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DashboardWidgetControllerGetWidgetSetParameter } from '../parameters/dashboard-widget-controller-get-widget-set.parameter';
import { DashboardWidgetControllerGetWidgetSetResponse } from '../responses/dashboard-widget-controller-get-widget-set.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'dashboard-widget-controller-getWidgetSet',
    operation: '{"operationId":"dashboard-widget-controller-getWidgetSet","parameters":[{"name":"machine-uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}},{"name":"data-definition-uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}},{"name":"start","in":"query","required":false,"schema":{"type":"integer","format":"int64"}},{"name":"end","in":"query","required":false,"schema":{"type":"integer","format":"int64"}},{"name":"interval","in":"query","required":true,"schema":{"type":"integer","format":"int32"}},{"name":"condition","in":"query","required":false,"schema":{"type":"string"}}],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/WidgetData"}}}}},"method":"get","path":"/dashboard-widget-controller/widget-set/machine/{machine-uuid}/data-definition/{data-definition-uuid}"}'
  })
export class DashboardWidgetControllerGetWidgetSetRemoteMethod extends OpenApiRemoteMethod<DashboardWidgetControllerGetWidgetSetResponse, DashboardWidgetControllerGetWidgetSetParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DashboardWidgetControllerGetWidgetSetParameter, void>): Promise<DashboardWidgetControllerGetWidgetSetResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerGetWidgetSetRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerGetWidgetSetRemoteMethod'
  })
export class DashboardWidgetControllerGetWidgetSetRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DashboardWidgetControllerGetWidgetSetResponse, OpenApiRemoteMethodParameter<DashboardWidgetControllerGetWidgetSetParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetSetRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<DashboardWidgetControllerGetWidgetSetParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetSetRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerGetWidgetSetRemoteMethod) remoteMethod: DashboardWidgetControllerGetWidgetSetRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DashboardWidgetControllerGetWidgetSetResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerGetWidgetSetRemoteMethodTemplateDirective ],
    exports: [ DashboardWidgetControllerGetWidgetSetRemoteMethodTemplateDirective ]
  })
export class DashboardWidgetControllerGetWidgetSetRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerGetWidgetSetRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerGetWidgetSetRemoteMethod'
  })
export class DashboardWidgetControllerGetWidgetSetRemoteMethodDirective extends RemoteMethodDirective<DashboardWidgetControllerGetWidgetSetResponse, OpenApiRemoteMethodParameter<DashboardWidgetControllerGetWidgetSetParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerGetWidgetSetRemoteMethod) remoteMethod: DashboardWidgetControllerGetWidgetSetRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerGetWidgetSetRemoteMethodDirective ],
    exports: [ DashboardWidgetControllerGetWidgetSetRemoteMethodDirective ]
  })
export class DashboardWidgetControllerGetWidgetSetRemoteMethodDirectiveModule {
}
