import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DataTriggerControllerGetTriggerTypesParameter } from '../parameters/data-trigger-controller-get-trigger-types.parameter';
import { DataTriggerControllerGetTriggerTypesResponse } from '../responses/data-trigger-controller-get-trigger-types.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'data-trigger-controller-getTriggerTypes',
    operation: '{"operationId":"data-trigger-controller-getTriggerTypes","parameters":[{"name":"abc","in":"path","required":true,"schema":{"type":"integer","format":"int32"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/data-trigger-controller/value-translation/{abc}"}'
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethod extends OpenApiRemoteMethod<DataTriggerControllerGetTriggerTypesResponse, DataTriggerControllerGetTriggerTypesParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<DataTriggerControllerGetTriggerTypesParameter, void>): Promise<DataTriggerControllerGetTriggerTypesResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDataTriggerControllerGetTriggerTypesRemoteMethod]',
    exportAs: 'eurogardDataTriggerControllerGetTriggerTypesRemoteMethod'
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DataTriggerControllerGetTriggerTypesResponse, OpenApiRemoteMethodParameter<DataTriggerControllerGetTriggerTypesParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataTriggerControllerGetTriggerTypesRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<DataTriggerControllerGetTriggerTypesParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataTriggerControllerGetTriggerTypesRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataTriggerControllerGetTriggerTypesRemoteMethod) remoteMethod: DataTriggerControllerGetTriggerTypesRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DataTriggerControllerGetTriggerTypesResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataTriggerControllerGetTriggerTypesRemoteMethodTemplateDirective ],
    exports: [ DataTriggerControllerGetTriggerTypesRemoteMethodTemplateDirective ]
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDataTriggerControllerGetTriggerTypesRemoteMethod]',
    exportAs: 'eurogardDataTriggerControllerGetTriggerTypesRemoteMethod'
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethodDirective extends RemoteMethodDirective<DataTriggerControllerGetTriggerTypesResponse, OpenApiRemoteMethodParameter<DataTriggerControllerGetTriggerTypesParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataTriggerControllerGetTriggerTypesRemoteMethod) remoteMethod: DataTriggerControllerGetTriggerTypesRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataTriggerControllerGetTriggerTypesRemoteMethodDirective ],
    exports: [ DataTriggerControllerGetTriggerTypesRemoteMethodDirective ]
  })
export class DataTriggerControllerGetTriggerTypesRemoteMethodDirectiveModule {
}
