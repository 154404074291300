import { NgModule } from '@angular/core';
import { CloudDashboardComponent } from './cloud-dashboard.component';
import { CommonModule } from '@angular/common';
import { LayoutRendererComponentModule } from '@digitaix/eurogard-dashboard-render-engine';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigateBackButtonComponentModule } from '@rxap/components';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [
    CloudDashboardComponent,
  ],
  imports: [
    CommonModule,
    LayoutRendererComponentModule,
    FlexLayoutModule,
    NavigateBackButtonComponentModule,
    MatDividerModule,
  ],
  exports: [
    CloudDashboardComponent,
  ],
})
export class CloudDashboardComponentModule {
}
