import { Dashboard } from '@digitaix/eurogard-utilities';
import { Inject, Injectable } from '@angular/core';
import { Method } from '@rxap/utilities/rxjs';
import {
  DashboardControllerGetByUuidRemoteMethod,
} from '@eurogard/open-api-legacy/remote-methods/dashboard-controller-get-by-uuid.remote-method';

// TODO : mv to @digitaix/eurogard-*
@Injectable({providedIn: 'root'})
export class GetDashboardByIdMethod implements Method<Dashboard, string> {

  constructor(
    @Inject(DashboardControllerGetByUuidRemoteMethod)
    private readonly getDashboard: DashboardControllerGetByUuidRemoteMethod,
  ) {
  }

  public async call(dashboardId: string): Promise<Dashboard> {

    const response = await this.getDashboard.call({parameters: {uuid: dashboardId}});

    if (!response) {
      throw new Error(`Dashboard with id '${ dashboardId }' does not exists!`);
    }

    return {
      uuid: response.uuid,
      name: response.name ?? null,
      locationUuid: response.location?.uuid ?? null,
      companyUuid: response.company?.uuid ?? null,
      widgetList: response.widgetList?.map(widget => ({
        uuid: widget.uuid!,
        name: widget.name!,
        content: JSON.parse(widget.content ?? '{}'),
        internalId: widget.internalId!,
        type: widget.type!,
        color: widget.color!,
      })) ?? [],
      layoutList: response.layoutList?.map(layout => ({
        uuid: layout.uuid!,
        name: layout.name!,
        target: layout.target as any,
        xml: layout.xml!,
      })) ?? [],
    };

  }

}
