import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { GrafanaPanelWidget, ISetWidgetDataMethod, WidgetComponent, WidgetData } from '@digitaix/eurogard-utilities';
import { BaseDataSource } from '@rxap/data-source';
import { CellInfo } from '../cell-info';
import { WIDGET_CELL_INFO, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { ConfigService } from '@rxap/config';

@Component({
  selector: 'eurogard-grafana-panel-widget',
  templateUrl: './grafana-panel-widget.component.html',
  styleUrls: [ './grafana-panel-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-grafana-panel-widget'},
})
export class GrafanaPanelWidgetComponent implements WidgetComponent {

  name: string | null = null;

  url: string | null = null;

  grafanaDomain: string | null = null;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
    private readonly config: ConfigService,
  ) {
  }

  private _content!: GrafanaPanelWidget;

  @Input()
  public set content(content: GrafanaPanelWidget) {
    this._content = content;
    this.grafanaDomain = content.grafanaDomain ?? this.config.getOrThrow('grafana.domain');
    this.url = 'https://' + this.grafanaDomain + content.url;
  }

}
