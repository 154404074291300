import { Injectable, isDevMode } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { ApiStatusService } from '@de/shared/api-status.service';
import { firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class StatusCheckGuard implements CanActivate, CanActivateChild {

  constructor(
    private readonly apiStatusService: ApiStatusService,
    private readonly router: Router,
  ) {
  }

  async canActivate(): Promise<boolean | UrlTree> {
    if (isDevMode()) {
      return true;
    }
    const isHealthy = await firstValueFrom(this.apiStatusService.isHealthy$);
    if (!isHealthy) {
      return this.router.createUrlTree([ '/', 'error', 'api-status' ]);
    }
    return true;
  }

  canActivateChild(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }

}
