import { isDevMode } from '@angular/core';
import { DeleteEmptyProperties } from '@rxap/utilities';

export interface AnimationOptions {
  duration: number;
  start?: number;
  end: number;
  step: (value: number, currentIteration: number) => void;
  easing?: (pos: number) => number;
}

function easeInOutCubic(pos: number) {
  // https://github.com/danro/easing-js/blob/master/easing.js
  if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 3);
  return 0.5 * (Math.pow((pos - 2), 3) + 2);
}

/**
 * Simplistic animation function for animating the gauge. That's all!
 * Options are:
 * {
 *  duration: 1,    // In seconds
 *  start: 0,       // The start value
 *  end: 100,       // The end value
 *  step: function, // REQUIRED! The step function that will be passed the value and does something
 *  easing: function // The easing function. Default is easeInOutCubic
 * }
 */
function Animation(options: AnimationOptions) {
  let duration = options.duration;
  let currentIteration = 1;
  let iterations = 60 * duration;
  let start = options.start || 0;
  let end = options.end;
  let change = end - start;
  let step = options.step;
  let easing = options.easing ?? easeInOutCubic;

  function animate() {
    let progress = currentIteration / iterations;
    let value = change * easing(progress) + start;
    // console.log(progress + ", " + value);
    step(value, currentIteration);
    currentIteration += 1;

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  // start!
  requestAnimationFrame(animate);
}

export interface GaugeOptions {
  dialRadius?: number;
  dialStartAngle?: number;
  dialEndAngle?: number;
  value?: number;
  max?: number;
  min?: number;
  valueDialClass?: string;
  valueClass?: string;
  dialClass?: string;
  gaugeClass?: string;
  showValue?: boolean;
  unit?: string | null;
  label?: ((val: number) => string | null) | null;
  valueLabelClass?: string | null;
  color?: ((val: number) => string) | null | string;
  viewBox?: string | null;
}

export class Gauge {

  static SVG_NS: 'http://www.w3.org/2000/svg' = 'http://www.w3.org/2000/svg';
  static GaugeDefaults = {
    centerX: 50,
    centerY: 50,
  };
  static defaultOptions: Required<GaugeOptions> = {
    dialRadius: 40,
    dialStartAngle: 135,
    dialEndAngle: 45,
    value: 0,
    max: 100,
    min: 0,
    valueDialClass: 'value',
    valueClass: 'value-text',
    dialClass: 'dial',
    gaugeClass: 'gauge',
    showValue: true,
    label: (val: number) => Math.round(val).toFixed(0),
    valueLabelClass: null,
    viewBox: null,
    color: null,
    unit: null,
  };

  static shallowCopy<V>(defaults: Required<V>, value: V): Required<V> {
    return Object.assign({}, defaults, value);
  }

  /**
   * A utility function to create SVG dom tree
   * @param {String} name The SVG element name
   * @param {Object} attrs The attributes as they appear in DOM e.g. stroke-width and not strokeWidth
   * @param {Array} children An array of children (can be created by this same function)
   * @return The SVG element
   */
  static svg(name: string, attrs: Record<string, string>, children: Element[] = []): SVGElement {
    const elem = document.createElementNS(Gauge.SVG_NS, name);
    for (const attrName in attrs) {
      if (attrs.hasOwnProperty(attrName)) {
        elem.setAttribute(attrName, attrs[attrName]);
      }
    }

    if (children) {
      children.forEach((c) => {
        elem.appendChild(c);
      });
    }
    return elem;
  }

  /**
   * Translates percentage value to angle. e.g. If gauge span angle is 180deg, then 50%
   * will be 90deg
   */
  static getAngle(percentage: number, gaugeSpanAngle: number) {
    return percentage * gaugeSpanAngle / 100;
  }

  static normalize(value: number, min: number, limit: number) {
    const val = Number(value);
    if (val > limit) return limit;
    if (val < min) return min;
    return val;
  }

  static getValueInPercentage(value: number, min: number, max: number) {
    const newMax = max - min, newVal = value - min;
    return 100 * newVal / newMax;
    // var absMin = Math.abs(min);
    // return 100 * (absMin + value) / (max + absMin);
  }

  /**
   * Gets cartesian co-ordinates for a specified radius and angle (in degrees)
   * @param cx {Number} The center x co-oriinate
   * @param cy {Number} The center y co-ordinate
   * @param radius {Number} The radius of the circle
   * @param angle {Number} The angle in degrees
   * @return An object with x,y co-ordinates
   */
  static getCartesian(cx: number, cy: number, radius: number, angle: number) {
    const rad = angle * Math.PI / 180;
    return {
      x: Math.round((cx + radius * Math.cos(rad)) * 1000) / 1000,
      y: Math.round((cy + radius * Math.sin(rad)) * 1000) / 1000,
    };
  }

  // Returns start and end points for dial

  // REMEMBER!! angle=0 starts on X axis and then increases clockwise
  static getDialCoords(radius: number, startAngle: number, endAngle: number) {
    const cx = Gauge.GaugeDefaults.centerX;
    const cy = Gauge.GaugeDefaults.centerY;
    return {
      end: this.getCartesian(cx, cy, radius, endAngle),
      start: this.getCartesian(cx, cy, radius, startAngle),
    };
  }

  static pathString(radius: number, startAngle: number, endAngle: number, largeArc: number = 1) {
    let coords = Gauge.getDialCoords(radius, startAngle, endAngle);
    let start = coords.start;
    let end = coords.end;

    return [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArc, 1, end.x, end.y,
    ].join(' ');
  }

  private gaugeContainer: HTMLElement;
  private limit: number;
  private min: number;
  private value: number;
  private radius: number;
  private displayValue: boolean;
  // i.e. starts at 135deg ends at 45deg with large arc flag
  private startAngle: number;
  private endAngle: number;
  private valueDialClass: string;
  private valueTextClass: string;
  private valueLabelClass: string | null;
  private dialClass: string;
  private gaugeClass: string;
  private color: ((val: number) => string) | null | string;
  private gaugeValueElem!: SVGElement;
  private gaugeValuePath!: SVGElement;
  private gaugeUnitElem!: SVGElement;
  private label: ((val: number) => string | null) | null;
  private viewBox: string | null;
  private unit: string | null;

  /**
   * Creates a Gauge object. This should be called without the 'new' operator. Various options
   * can be passed for the gauge:
   * {
   *    dialStartAngle: The angle to start the dial. MUST be greater than dialEndAngle. Default 135deg
   *    dialEndAngle: The angle to end the dial. Default 45deg
   *    dialRadius: The gauge's radius. Default 400
   *    max: The maximum value of the gauge. Default 100
   *    value: The starting value of the gauge. Default 0
   *    label: The function on how to render the center label (Should return a value)
   * }
   * @param {Element} elem The DOM into which to render the gauge
   * @param {Object} opts The gauge options
   * @return a Gauge object
   */
  constructor(elem: HTMLElement, private opts: GaugeOptions) {
    const options = Gauge.shallowCopy(Gauge.defaultOptions, DeleteEmptyProperties(this.opts));
    this.gaugeContainer = elem;
    this.limit = options.max;
    this.min = options.min;
    this.value = Gauge.normalize(options.value, this.min, this.limit);
    this.radius = options.dialRadius;
    this.displayValue = options.showValue;
    this.startAngle = options.dialStartAngle;
    this.endAngle = options.dialEndAngle;
    this.valueDialClass = options.valueDialClass;
    this.valueTextClass = options.valueClass;
    this.valueLabelClass = options.valueLabelClass;
    this.dialClass = options.dialClass;
    this.gaugeClass = options.gaugeClass;
    this.color = options.color;
    this.label = options.label;
    this.viewBox = options.viewBox;
    this.unit = options.unit;

    if (this.startAngle < this.endAngle) {
      if (isDevMode()) {
        console.log('WARN! startAngle < endAngle, Swapping');
      }
      let tmp = this.startAngle;
      this.startAngle = this.endAngle;
      this.endAngle = tmp;
    }

    this.initializeGauge(this.gaugeContainer);
    this.setValue(this.value);
  }

  public setMaxValue(max: number) {
    this.limit = max;
  }

  public setValue(val: number) {
    this.value = Gauge.normalize(val, this.min, this.limit);
    if (this.color !== null) {
      this.setGaugeColor(this.value, 0);
    }
    this.updateGauge(this.value);
  }

  public setValueAnimated(val: number, duration?: number) {
    const oldVal = this.value;
    this.value = Gauge.normalize(val, this.min, this.limit);
    if (oldVal === this.value) {
      return;
    }

    if (this.color !== null) {
      this.setGaugeColor(this.value, duration ?? 1);
    }

    Animation({
      start: oldVal ?? 0,
      end: this.value,
      duration: duration ?? 1,
      step: (val, frame) => {
        this.updateGauge(val, frame);
      },
    });
  }

  public getValue(): number {
    return this.value;
  }

  private initializeGauge(elem: HTMLElement) {
    this.gaugeValueElem = Gauge.svg('text', {
      x: '50',
      y: '50',
      fill: '#999',
      'class': this.valueTextClass,
      'font-size': '100%',
      'font-family': 'sans-serif',
      'font-weight': 'normal',
      'text-anchor': 'middle',
      'alignment-baseline': 'middle',
      'dominant-baseline': 'central',
    });

    if (this.unit) {
      this.gaugeUnitElem = Gauge.svg('text', {
        x: '50',
        y: '65',
        fill: '#999',
        'class': this.valueTextClass,
        'font-size': '100%',
        'font-family': 'sans-serif',
        'font-weight': 'normal',
        'text-anchor': 'middle',
        'alignment-baseline': 'middle',
        'dominant-baseline': 'central',
      });
      this.gaugeUnitElem.textContent = this.unit;
    }

    this.gaugeValuePath = Gauge.svg('path', {
      'class': this.valueDialClass,
      fill: 'none',
      stroke: '#666',
      'stroke-width': '2.5',
      d: Gauge.pathString(this.radius, this.startAngle, this.startAngle), // value of 0
    });

    let angle = Gauge.getAngle(100, 360 - Math.abs(this.startAngle - this.endAngle));
    let flag = angle <= 180 ? 0 : 1;
    let gaugeElement = Gauge.svg('svg', {'viewBox': this.viewBox ?? '0 0 100 100', 'class': this.gaugeClass}, [
      Gauge.svg('path', {
        'class': this.dialClass,
        fill: 'none',
        stroke: '#eee',
        'stroke-width': '2',
        d: Gauge.pathString(this.radius, this.startAngle, this.endAngle, flag),
      }),
      Gauge.svg('g', {'class': 'text-container'}, [
        this.gaugeValueElem,
        this.gaugeUnitElem,
      ].filter(Boolean)),
      this.gaugeValuePath,
      this.gaugeUnitElem,
    ].filter(Boolean));
    elem.appendChild(gaugeElement);
  }

  private updateGauge(value: number, frame?: number) {
    let val = Gauge.getValueInPercentage(value, this.min, this.limit);
    // angle = getAngle(val, 360 - Math.abs(endAngle - startAngle)),
    let angle = Gauge.getAngle(val, 360 - Math.abs(this.startAngle - this.endAngle));
    // this is because we are using arc greater than 180deg
    let flag = angle <= 180 ? 0 : 1;
    if (this.displayValue) {
      this.gaugeValueElem.textContent = this.label?.call(this.opts, value) ?? null;
    }
    this.gaugeValuePath.setAttribute('d', Gauge.pathString(this.radius, this.startAngle, angle + this.startAngle, flag));
  }

  private setGaugeColor(value: number, duration: number) {
    let color: string | null;
    if (typeof this.color === 'function') {
      color = this.color.call(this.opts, value) ?? null;
    } else if (typeof this.color === 'string') {
      color = this.color;
    } else {
      throw new Error(`The color option as a unhallowed type: '${ typeof this.color }'`);
    }
    let dur = duration * 1000;
    let pathTransition = 'stroke ' + dur + 'ms ease';
    // textTransition = "fill " + dur + "ms ease";

    if (color !== null) {
      this.gaugeValuePath.style.stroke = color;
    }
    (this.gaugeValuePath.style as any)['-webkit-transition'] = pathTransition;
    (this.gaugeValuePath.style as any)['-moz-transition'] = pathTransition;
    this.gaugeValuePath.style.transition = pathTransition;
    /*
     gaugeValueElem.style = [
     "fill: " + c,
     "-webkit-transition: " + textTransition,
     "-moz-transition: " + textTransition,
     "transition: " + textTransition,
     ].join(";");
     */
  }

}
