import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrafanaPanelWidgetComponent } from './grafana-panel-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { SantizationModule } from '@rxap/pipes/santization';

@NgModule({
  declarations: [
    GrafanaPanelWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    SantizationModule,
  ],
  exports: [
    GrafanaPanelWidgetComponent,
  ],
})
export class GrafanaPanelWidgetComponentModule {
}
