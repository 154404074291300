import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'eurogard-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'portal'},
})
export class AppComponent {
}
