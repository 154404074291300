import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import {
  UserSettingsControllerGetByUserIdResponse,
} from '../responses/user-settings-controller-get-by-user-id.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'service-user-settings',
    operationId: 'UserSettingsController_getByUserId',
  operation: '{"operationId":"UserSettingsController_getByUserId","parameters":[],"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/UserSettingsDto"}}}}},"method":"get","path":"/user"}',
  })
export class UserSettingsControllerGetByUserIdRemoteMethod extends OpenApiRemoteMethod<UserSettingsControllerGetByUserIdResponse, void, void> {
  public override call(): Promise<UserSettingsControllerGetByUserIdResponse> {
    return super.call();
  }
}
