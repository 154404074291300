<div [ngClass]="cellInfo.classList" class="container">
  <div [fxLayout]="cellInfo.aspectRatio > 1 ? 'row' : 'column'" class="inner" fxLayoutAlign="center center"
       fxLayoutGap="20px">
    <button
      [ngStyle]="{ backgroundColor: color }"
      [ref]="ref"
      [value]="value"
      class="button"
      eurogardSetWidgetData
      fxFlex="nogrow"
      i18n-message
      message="Should the data point be changed?">
      {{label}}
    </button>
    <ng-container *ngIf="showResponse">
    <span *rxapDataSource="let data from dataSource"
          fxLayoutAlign="center center"
          fxLayoutGap="20px">
      <span i18n>response:</span>
      <span>{{data.value | json}}</span>
    </span>
    </ng-container>
  </div>
</div>
