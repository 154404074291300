import { ParsedElement } from '@rxap/xml-parser';
import { ElementChildren, ElementDef } from '@rxap/xml-parser/decorators';
import { RowElement } from './row.element';
import { Widget } from '@digitaix/eurogard-utilities';
import { CellElement } from './cell.element';

@ElementDef('body')
export class BodyElement implements ParsedElement {

  @ElementChildren(RowElement)
  public rowList: RowElement[] = [];

  public validate(): boolean {
    return true;
  }

  public setWidgetObject(widgetList: Widget[]) {
    this.rowList.forEach(row => row.setWidgetObject(widgetList));
  }

  public forEachCell(cb: (cell: CellElement) => void) {
    this.rowList.forEach(row => row.forEachCell(cb));
  }

}
