<div class="container" fxLayout="column" fxLayoutGap="8px">
  <mat-progress-bar *ngIf="widgetDataService.loading$ | async" mode="indeterminate"></mat-progress-bar>
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
    <div class="banner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
      <mat-icon class="icon">schedule</mat-icon>
      <div class="text" fxLayout="row" fxLayoutGap="4px">
        <span i18n>Before</span>
        <span>{{ widgetDataService.distanceToNow$ | async }}</span>
        <span i18n>aktualisiert</span>
      </div>
    </div>
    <span class="text" fxFlex="nogrow">{{name}}</span>
    <button (click)="widgetDataService.refresh()" mat-icon-button>
      <mat-icon class="icon">autorenew</mat-icon>
    </button>
  </div>
  <div class="inner" fxLayout="column">
    <eurogard-segment *ngFor="let segment of segments" [segment]="segment" fxFlex="nogrow"></eurogard-segment>
  </div>
</div>
