import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EingabefeldSetpointsWidgetComponent } from './eingabefeld-setpoints-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SetWidgetDataDirectiveModule } from '../set-widget-data.directive';
import { FormsModule } from '@angular/forms';
import { InputClearButtonDirectiveModule } from '@rxap/material-form-system';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    EingabefeldSetpointsWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    MatInputModule,
    InputClearButtonDirectiveModule,
    MatIconModule,
    SetWidgetDataDirectiveModule,
    FormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    EingabefeldSetpointsWidgetComponent,
  ],
})
export class EingabefeldSetpointsWidgetComponentModule {
}
