import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetDashboardTypesResponse } from '../responses/options-controller-get-dashboard-types.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getDashboardTypes',
    operation: '{"operationId":"options-controller-getDashboardTypes","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/dashboard-types"}'
  })
export class OptionsControllerGetDashboardTypesRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetDashboardTypesResponse, void, void> {
  public override call(): Promise<OptionsControllerGetDashboardTypesResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetDashboardTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDashboardTypesRemoteMethod'
  })
export class OptionsControllerGetDashboardTypesRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetDashboardTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDashboardTypesRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDashboardTypesRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDashboardTypesRemoteMethod) remoteMethod: OptionsControllerGetDashboardTypesRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetDashboardTypesResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDashboardTypesRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetDashboardTypesRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetDashboardTypesRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetDashboardTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDashboardTypesRemoteMethod'
  })
export class OptionsControllerGetDashboardTypesRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetDashboardTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDashboardTypesRemoteMethod) remoteMethod: OptionsControllerGetDashboardTypesRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDashboardTypesRemoteMethodDirective ],
    exports: [ OptionsControllerGetDashboardTypesRemoteMethodDirective ]
  })
export class OptionsControllerGetDashboardTypesRemoteMethodDirectiveModule {
}
