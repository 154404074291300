import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebansichtWidgetComponent } from './webansicht-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { SantizationModule } from '@rxap/pipes/santization';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    WebansichtWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    SantizationModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  exports: [
    WebansichtWidgetComponent,
  ],
})
export class WebansichtWidgetComponentModule {
}
