import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { ThingControllerGetByUuidRemoteMethod } from '@eurogard/open-api-legacy/remote-methods/thing-controller-get-by-uuid.remote-method';
import { ThingControllerGetByUuidResponse } from '@eurogard/open-api-legacy/responses/thing-controller-get-by-uuid.response';

@Pipe({
  name: 'thing',
})
export class ThingPipe implements PipeTransform {

  constructor(private readonly getByUuid: ThingControllerGetByUuidRemoteMethod) {
  }

  public async transform(uuid: string | null): Promise<null | ThingControllerGetByUuidResponse> {
    if (uuid) {
      return this.getByUuid.call({parameters: {uuid}});
    }
    return null;
  }

}

@NgModule({
  declarations: [ ThingPipe ],
  exports: [ ThingPipe ],
})
export class ThingPipeModule {
}
