import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { DataDefinitionControllerPostSetPointParameter } from '../parameters/data-definition-controller-post-set-point.parameter';
import { DataDefinitionControllerPostSetPointRequestBody } from '../request-bodies/data-definition-controller-post-set-point.request-body';
import { DataDefinitionControllerPostSetPointResponse } from '../responses/data-definition-controller-post-set-point.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'data-definition-controller-postSetPoint',
    operation: '{"operationId":"data-definition-controller-postSetPoint","parameters":[{"name":"uuid","in":"path","required":true,"schema":{"type":"string","format":"uuid"}}],"requestBody":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/SetPointRequest"}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"$ref":"#/components/schemas/DataDefinitionKeyItem"}}}}},"method":"post","path":"/data-definition-controller/{uuid}/set-point"}'
  })
export class DataDefinitionControllerPostSetPointRemoteMethod extends OpenApiRemoteMethod<DataDefinitionControllerPostSetPointResponse, DataDefinitionControllerPostSetPointParameter, DataDefinitionControllerPostSetPointRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<DataDefinitionControllerPostSetPointParameter, DataDefinitionControllerPostSetPointRequestBody>): Promise<DataDefinitionControllerPostSetPointResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDataDefinitionControllerPostSetPointRemoteMethod]',
    exportAs: 'eurogardDataDefinitionControllerPostSetPointRemoteMethod'
  })
export class DataDefinitionControllerPostSetPointRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DataDefinitionControllerPostSetPointResponse, OpenApiRemoteMethodParameter<DataDefinitionControllerPostSetPointParameter, DataDefinitionControllerPostSetPointRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataDefinitionControllerPostSetPointRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<DataDefinitionControllerPostSetPointParameter, DataDefinitionControllerPostSetPointRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDataDefinitionControllerPostSetPointRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataDefinitionControllerPostSetPointRemoteMethod) remoteMethod: DataDefinitionControllerPostSetPointRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DataDefinitionControllerPostSetPointResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataDefinitionControllerPostSetPointRemoteMethodTemplateDirective ],
    exports: [ DataDefinitionControllerPostSetPointRemoteMethodTemplateDirective ]
  })
export class DataDefinitionControllerPostSetPointRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDataDefinitionControllerPostSetPointRemoteMethod]',
    exportAs: 'eurogardDataDefinitionControllerPostSetPointRemoteMethod'
  })
export class DataDefinitionControllerPostSetPointRemoteMethodDirective extends RemoteMethodDirective<DataDefinitionControllerPostSetPointResponse, OpenApiRemoteMethodParameter<DataDefinitionControllerPostSetPointParameter, DataDefinitionControllerPostSetPointRequestBody>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DataDefinitionControllerPostSetPointRemoteMethod) remoteMethod: DataDefinitionControllerPostSetPointRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DataDefinitionControllerPostSetPointRemoteMethodDirective ],
    exports: [ DataDefinitionControllerPostSetPointRemoteMethodDirective ]
  })
export class DataDefinitionControllerPostSetPointRemoteMethodDirectiveModule {
}
