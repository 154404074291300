import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, IterableDiffers, NgModule, NgZone, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateCollectionDirective, RemoteMethodTemplateCollectionDirectiveContext, RemoteMethodTemplateCollectionDirectiveErrorContext, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { ArrayElement } from '@rxap/utilities';
import { DashboardWidgetControllerGetWidgetDataRequestBody } from '../request-bodies/dashboard-widget-controller-get-widget-data.request-body';
import { DashboardWidgetControllerGetWidgetDataResponse } from '../responses/dashboard-widget-controller-get-widget-data.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'dashboard-widget-controller-getWidgetData',
    operation: '{"operationId":"dashboard-widget-controller-getWidgetData","requestBody":{"content":{"application/json":{"schema":{"type":"array","items":{"$ref":"#/components/schemas/WidgetDataRequest"}}}},"required":true},"responses":{"200":{"content":{"application/json":{"schema":{"type":"array","items":{"$ref":"#/components/schemas/WidgetData"}}}}}},"method":"post","path":"/dashboard-widget-controller/widget-data"}'
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethod extends OpenApiRemoteMethod<DashboardWidgetControllerGetWidgetDataResponse, void, DashboardWidgetControllerGetWidgetDataRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>): Promise<DashboardWidgetControllerGetWidgetDataResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerGetWidgetDataCollectionRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerGetWidgetDataCollectionRemoteMethod'
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateCollectionDirective extends RemoteMethodTemplateCollectionDirective<ArrayElement<DashboardWidgetControllerGetWidgetDataResponse>, OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetDataCollectionRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetDataCollectionRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateCollectionDirectiveErrorContext>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetDataCollectionRemoteMethodEmpty')
  public emptyTemplate?: TemplateRef<void>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerGetWidgetDataRemoteMethod) remoteMethod: DashboardWidgetControllerGetWidgetDataRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateCollectionDirectiveContext<ArrayElement<DashboardWidgetControllerGetWidgetDataResponse>>>, @Inject(IterableDiffers) differs: IterableDiffers, @Inject(NgZone) zone: NgZone, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr, differs, zone);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateCollectionDirective ],
    exports: [ DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateCollectionDirective ]
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateCollectionDirectiveModule {
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerGetWidgetDataRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerGetWidgetDataRemoteMethod'
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<DashboardWidgetControllerGetWidgetDataResponse, OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetDataRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardDashboardWidgetControllerGetWidgetDataRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerGetWidgetDataRemoteMethod) remoteMethod: DashboardWidgetControllerGetWidgetDataRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<DashboardWidgetControllerGetWidgetDataResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateDirective ],
    exports: [ DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateDirective ]
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardDashboardWidgetControllerGetWidgetDataRemoteMethod]',
    exportAs: 'eurogardDashboardWidgetControllerGetWidgetDataRemoteMethod'
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodDirective extends RemoteMethodDirective<DashboardWidgetControllerGetWidgetDataResponse, OpenApiRemoteMethodParameter<void, DashboardWidgetControllerGetWidgetDataRequestBody>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(DashboardWidgetControllerGetWidgetDataRemoteMethod) remoteMethod: DashboardWidgetControllerGetWidgetDataRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ DashboardWidgetControllerGetWidgetDataRemoteMethodDirective ],
    exports: [ DashboardWidgetControllerGetWidgetDataRemoteMethodDirective ]
  })
export class DashboardWidgetControllerGetWidgetDataRemoteMethodDirectiveModule {
}
