import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RxapAuthenticationService } from '@rxap/authentication';
import { tap } from 'rxjs/operators';
import { CorsInterceptor } from './cors-interceptor';
import { EurogardAuthenticationService } from './eurogard-authentication.service';
import { EurogardAuthorizationService } from './eurogard-authorization.service';
import { InitializeKeycloak } from './initialize-keycloak';
import { KeycloakService } from '@rxap/keycloak';
import { ConfigService } from '@rxap/config';
import { AuthorizationService } from '@rxap/authorization';
import { AuthHttpInterceptor } from './auth.http-interceptor';
import { ApiInterceptor } from './api-interceptor';
import { ServiceServerApiKeyHttpInterceptor } from './service-server-api-key.http-interceptor';
import { ServiceServerApiKeyService } from './service-server-api-key.service';
import { SERVICE_SERVER_API_KEY, SERVICE_SERVER_ENABLED } from './tokens';
import { ReplaySubject } from 'rxjs';
import { ConfirmDialogComponentModule } from '@rxap/dialog';

@NgModule({
  imports: [
    ConfirmDialogComponentModule,
  ],
})
export class AuthenticationModule {

  public static forRoot({serviceServer}: { serviceServer?: boolean } = {}): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        serviceServer ?
          [
            {
              provide: SERVICE_SERVER_API_KEY,
              useValue: new ReplaySubject<string>(),
            },
            {
              provide: HTTP_INTERCEPTORS,
              multi: true,
              useClass: ServiceServerApiKeyHttpInterceptor,
            },
            ServiceServerApiKeyService,
          ] : [],
        {
          provide: SERVICE_SERVER_ENABLED,
          useValue: serviceServer ?? false,
        },
        {
          // TODO : mv to detected HttpInterceptorModule
          provide: HTTP_INTERCEPTORS,
          useClass: CorsInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true,
        },
        {
          // TODO : mv to detected HttpInterceptorModule
          provide: HTTP_INTERCEPTORS,
          useClass: ApiInterceptor,
          multi: true,
        },
        {
          provide: AuthorizationService,
          useClass: EurogardAuthorizationService,
        },

        {
          provide: APP_INITIALIZER,
          useFactory: InitializeKeycloak,
          multi: true,
          deps: [ KeycloakService, ConfigService ],
        },
        {
          provide: RxapAuthenticationService,
          useClass: EurogardAuthenticationService,
        },
      ],
    };
  }

  constructor(
    private readonly keycloak: KeycloakService,
    @Optional()
      serviceServerApiKeyService: ServiceServerApiKeyService | null,
  ) {
    this.keycloak.keycloakEvents$.pipe(
      tap(() => {
        // console.log('clear token storage');
        // localStorage.removeItem('__refreshToken__');
        // localStorage.removeItem('__accessToken__');
        // localStorage.removeItem('__idToken__');
      }),
    ).subscribe();
    serviceServerApiKeyService?.start();
  }

}
