import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigService } from '@rxap/config';
import { UpdateEnvironment } from '@rxap/environment';
import { OpenApiConfigService } from '@rxap/open-api';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { SentryInit, UnregisterServiceWorker } from '@eurogard/setup';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// eslint-disable-next-line no-restricted-syntax
console.debug(`environment: '${ environment.name }'`, environment);

if (environment.production) {
  enableProdMode();
}

UnregisterServiceWorker(environment);

UpdateEnvironment(environment, '/build.json')
.then(() => Promise.all([
  ConfigService.Load({ url: `/api/configuration/${environment.tag ?? 'latest'}/portal` }),
  OpenApiConfigService.Load()
]))
.then(() => {
    SentryInit('https://28dda2634d7e445689ed1f27aadbdb77@sentry.eurogard.cloud/6', environment);
    return platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(error => {
      console.error(`application bootstrap failed: ${ error?.message ? error.message : '' }`);
      throw error;
    });
  },
);
