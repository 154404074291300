import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { EnvironmentComponentModule } from '@rxap/environment';
import {
  FooterModule,
  NavigationWithInserts,
  RXAP_NAVIGATION_CONFIG,
  SidenavContentModule,
  ToggleWindowSidenavButtonModule,
  WindowContainerSidenavModule,
} from '@rxap/layout';
import { RemoteMethodTemplateDirectiveModule } from '@rxap/remote-method/directive';
import { WindowTaskBarModule } from '@rxap/window-system';
import { HeaderModule } from '../header/header.component.module';
import { NavigationComponentModule } from '../navigation/navigation.component.module';
import { LayoutComponent } from './layout.component';

@NgModule({
  declarations: [ LayoutComponent ],
  imports: [
    MatSidenavModule,
    FooterModule,
    HeaderModule,
    CommonModule,
    SidenavContentModule,
    WindowContainerSidenavModule,
    ToggleWindowSidenavButtonModule,
    FlexLayoutModule,
    RouterModule,
    EnvironmentComponentModule,
    WindowTaskBarModule,
    RemoteMethodTemplateDirectiveModule,
    MatMenuModule,
    MatIconModule,
    NavigationComponentModule,
    MatDividerModule,
    MatButtonModule,
  ],
  exports: [ LayoutComponent ],
})
export class LayoutModule {

  public static withNavigation(navigation: NavigationWithInserts | (() => NavigationWithInserts)): ModuleWithProviders<LayoutModule> {

    return {
      ngModule: LayoutModule,
      providers: [
        {
          provide: RXAP_NAVIGATION_CONFIG,
          useValue: navigation,
        },
      ],
    };
  }

}
