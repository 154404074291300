<rxap-header [color]="undefined" [sidenav]="sidenav" class="header">
</rxap-header>
<mat-sidenav-container autosize class="container">
  <mat-sidenav
    [fixedBottomGap]="layoutComponentService.fixedBottomGap$ | async"
    [fixedInViewport]="true"
    [fixedTopGap]="40"
    (mouseenter)="sidenavMode === 'over' && matSidenav.open()"
    (mouseleave)="sidenavMode === 'over' && matSidenav.close()"
    [mode]="sidenavMode"
    [opened]="false"
    #matSidenav="matSidenav"
    class="sidenav"
  >
    <div class="container">
      <div class="inner"
           fxLayout="column"
           fxLayoutAlign="space-between stretch">

        <div fxFlex="grow" class="nav-container">

          <div (click)="toggleSidenavMode()" class="title-container" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title" i18n>Navigation</span>
            <button mat-icon-button>
              <mat-icon *ngIf="sidenavMode === 'over'">radio_button_unchecked</mat-icon>
              <mat-icon *ngIf="sidenavMode === 'side'">radio_button_checked</mat-icon>
            </button>
          </div>

          <ul fxLayout="column" root rxap-navigation></ul>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-between stretch">
          <img
            [routerLink]="['/']"
            [src]="layoutComponentService.logo.src ?? 'https://via.placeholder.com/256x100'"
            alt="logo"
            class="logo"
          />
          <div *ngIf="release" class="version" fxLayout="row" fxLayoutAlign="center center">
            <span>{{release}}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="content" [ngClass]="{ opened: matSidenav.opened, side: sidenavMode === 'side' }">
    <rxap-sidenav-content [sidenav]="sidenav"></rxap-sidenav-content>
  </mat-sidenav-content>
</mat-sidenav-container>
<rxap-footer class="footer"></rxap-footer>
<!--<rxap-window-task-bar-container class="task-bar"></rxap-window-task-bar-container>-->

<mat-menu #menu="matMenu">
  <button (click)="theme.toggleDarkTheme()" mat-menu-item>
    <mat-icon *ngIf="theme.darkMode">brightness_2</mat-icon>
    <mat-icon *ngIf="!theme.darkMode">brightness_5</mat-icon>
    <span i18n>Mode</span>
  </button>
</mat-menu>
