import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorMessageComponent } from './error-message.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorMessageInterceptor } from './error-message.interceptor';


@NgModule({
  declarations: [
    ErrorMessageComponent,
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    ErrorMessageComponent,
  ],
})
export class ErrorMessageComponentModule {
  public static forRoot(): ModuleWithProviders<ErrorMessageComponentModule> {
    return {
      ngModule: ErrorMessageComponentModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorMessageInterceptor,
          multi: true,
        },
      ],
    };
  }
}
