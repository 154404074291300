import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetTriggerTypesResponse } from '../responses/options-controller-get-trigger-types.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getTriggerTypes',
    operation: '{"operationId":"options-controller-getTriggerTypes","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/trigger-types"}'
  })
export class OptionsControllerGetTriggerTypesRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetTriggerTypesResponse, void, void> {
  public override call(): Promise<OptionsControllerGetTriggerTypesResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetTriggerTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetTriggerTypesRemoteMethod'
  })
export class OptionsControllerGetTriggerTypesRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetTriggerTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetTriggerTypesRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetTriggerTypesRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetTriggerTypesRemoteMethod) remoteMethod: OptionsControllerGetTriggerTypesRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetTriggerTypesResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetTriggerTypesRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetTriggerTypesRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetTriggerTypesRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetTriggerTypesRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetTriggerTypesRemoteMethod'
  })
export class OptionsControllerGetTriggerTypesRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetTriggerTypesResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetTriggerTypesRemoteMethod) remoteMethod: OptionsControllerGetTriggerTypesRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetTriggerTypesRemoteMethodDirective ],
    exports: [ OptionsControllerGetTriggerTypesRemoteMethodDirective ]
  })
export class OptionsControllerGetTriggerTypesRemoteMethodDirectiveModule {
}
