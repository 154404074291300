import { NgModule } from '@angular/core';
import { SegmentComponent } from './segment.component';
import { CellWidthPipe } from '../cell-width.pipe';
import { CommonModule } from '@angular/common';
import { CellContentComponentModule } from '../../cell-content/cell-content.component.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    SegmentComponent,
    CellWidthPipe,
  ],
  imports: [
    CellContentComponentModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
  ],
  exports: [
    SegmentComponent,
  ],
})
export class SegmentComponentModule {
}
