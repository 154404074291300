import { Injectable } from '@angular/core';
import {
  OpenApiRemoteMethod,
  OpenApiRemoteMethodParameter,
  RxapOpenApiRemoteMethod,
} from '@rxap/open-api/remote-method';
import {
  TranslationControllerGetTranslationMapParameter,
} from '../parameters/translation-controller-get-translation-map.parameter';
import {
  TranslationControllerGetTranslationMapResponse,
} from '../responses/translation-controller-get-translation-map.response';

@Injectable({
  providedIn: 'root',
})
@RxapOpenApiRemoteMethod({
  serverId: 'service-i18n',
  operationId: 'TranslationController_getTranslationMap',
  operation: '{"operationId":"TranslationController_getTranslationMap","parameters":[{"name":"identifier","required":true,"in":"path","schema":{"type":"string"}},{"name":"lang","required":true,"in":"path","schema":{"type":"string"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{}}}}}},"method":"get","path":"/translation/{identifier}/{lang}"}',
})
export class TranslationControllerGetTranslationMapRemoteMethod
  extends OpenApiRemoteMethod<TranslationControllerGetTranslationMapResponse, TranslationControllerGetTranslationMapParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<TranslationControllerGetTranslationMapParameter, void>): Promise<TranslationControllerGetTranslationMapResponse> {
    return super.call(parameters);
  }
}
