import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanySelectService } from './company-select.service';

@Injectable()
export class CompanyInterceptor {
  constructor(public readonly companySelectService: CompanySelectService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.companySelectService && this.companySelectService.companyId) {
      if (!request.url.match(/\/company-controller\/getAll/)) {
        request = request.clone({
          setParams: {
            company: this.companySelectService.companyId,
          },
        });
      }
    }
    return next.handle(request);
  }
}
