import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
  ISetWidgetDataMethod,
  WidgetComponent,
  WidgetData,
  WidgetFontSize,
  ZeitstempelanzeigeWidget,
} from '@digitaix/eurogard-utilities';
import type { BaseDataSource } from '@rxap/data-source';
import { WIDGET_CELL_INFO, WIDGET_DATA_LAST_CHANGE, WIDGET_DATA_SOURCE, WIDGET_SET_DATA_VALUE_METHOD } from '../tokens';
import { Observable } from 'rxjs';
import { CellInfo } from '../cell-info';

@Component({
  selector: 'eurogard-zeitstempelanzeige-widget',
  templateUrl: './zeitstempelanzeige-widget.component.html',
  styleUrls: [ './zeitstempelanzeige-widget.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-zeitstempelanzeige-widget'},
})
export class ZeitstempelanzeigeWidgetComponent implements WidgetComponent {

  public color: string | null = null;

  @Input()
  public name: string | null = null;

  public fontSize?: WidgetFontSize;

  constructor(
    @Inject(WIDGET_DATA_SOURCE) public readonly dataSource: BaseDataSource<WidgetData | null>,
    @Inject(WIDGET_SET_DATA_VALUE_METHOD) public readonly method: ISetWidgetDataMethod,
    @Inject(WIDGET_DATA_LAST_CHANGE) public readonly lastChange$: Observable<Date>,
    @Inject(WIDGET_CELL_INFO) public readonly cellInfo: CellInfo,
  ) {
  }

  public get aspectRatio(): number {
    return this.cellInfo.aspectRatio;
  }

  private _content!: ZeitstempelanzeigeWidget;

  @Input()
  public set content(content: ZeitstempelanzeigeWidget) {
    this._content = content;
    this.color = this._content.color;
    this.fontSize = this._content.fontSize;
  }
}
