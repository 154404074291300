import { ChangeDetectionStrategy, Component, Inject, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mfd-http-error-message',
  templateUrl: './http-error-message.component.html',
  styleUrls: [ './http-error-message.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpErrorMessageComponent {
  public isProduction = !isDevMode();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

}
