import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '@rxap/config';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ConfirmDialogService } from '@rxap/dialog';
import { isDefined } from '@rxap/utilities/rxjs';
import { KeycloakService } from '@rxap/keycloak';
import { DebounceCall } from '@rxap/utilities';
import { SERVICE_SERVER_API_KEY } from './tokens';

declare var $localize: any;

@Injectable()
export class ServiceServerApiKeyHttpInterceptor implements HttpInterceptor {

  constructor(
    @Inject(SERVICE_SERVER_API_KEY)
    private readonly apiKey: ReplaySubject<string | null>,
    private readonly config: ConfigService,
    private readonly confirmDialog: ConfirmDialogService,
    private readonly auth: KeycloakService,
  ) {}

  @DebounceCall(500)
  private async refreshApiToken() {
    const response = await this.confirmDialog.open($localize`Your API key has expired. Do you want to request a new one?`);
    if (response) {
      location.reload();
    } else {
      this.auth.logout();
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.match(new RegExp(`^${ this.config.get('api.serviceServerBaseUrl') }`)) ||
      req.url.match(new RegExp(`^${location.origin}${ this.config.get('api.service-server.baseUrl') }`)) ||
      req.url.match(/\/api\/feature\/remote-connection/) ||
      req.url.match(/\/api\/app\/service-server/)
    ) {
      return this.apiKey.pipe(
        isDefined(),
        take(1),
        switchMap(apiKey => next.handle(req.clone({
          setHeaders: {
            'x-api-key': apiKey,
          },
          withCredentials: true,
        }))),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              const message = error.error.message;
              if (message === 'Invalid API key!' || message === 'Unauthorized') {
                this.apiKey.next(null);
                this.refreshApiToken();
              }
            }
          }
          throw error;
        })
      );
    }
    return next.handle(req);
  }

}
