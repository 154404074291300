import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberButtonWidgetComponent } from './number-button-widget.component';
import { DataSourceDirectiveModule } from '@rxap/data-source/directive';
import { RemoteMethodDirectiveModule } from '@rxap/remote-method/directive';
import { SetWidgetDataDirectiveModule } from '../set-widget-data.directive';
import { ToBooleanPipeModule } from '../utilities/to-boolean.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    NumberButtonWidgetComponent,
  ],
  imports: [
    DataSourceDirectiveModule,
    RemoteMethodDirectiveModule,
    CommonModule,
    SetWidgetDataDirectiveModule,
    ToBooleanPipeModule,
    FlexLayoutModule,
  ],
  exports: [
    NumberButtonWidgetComponent,
  ],
})
export class NumberButtonWidgetComponentModule {
}
