import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageOfRange',
})
export class PercentageOfRangePipe implements PipeTransform {

  public transform(value: number | null | undefined, min: number, max: number): number {

    if (typeof value !== 'number') {
      return 0;
    }

    const normalizedMax = min >= 0 ? max - min : max + Math.abs(min);
    const normalizedValue = min >= 0 ? value - min : value + Math.abs(min);
    if (normalizedValue <= 0) {
      return 0;
    }
    if (normalizedValue >= normalizedMax) {
      return 100;
    }
    return (normalizedValue / normalizedMax) * 100;
  }

}

@NgModule({
  declarations: [ PercentageOfRangePipe ],
  exports: [ PercentageOfRangePipe ],
})
export class PercentagePipeModule {
}
