import { OpenApiConfigService } from '@rxap/open-api';
import { ConfigService } from '@rxap/config';

export function OpenApiInit(openApiConfig: OpenApiConfigService, configService: ConfigService) {
  const api = configService.get<Record<string, { baseUrl?: string }> & { baseUrl: string }>('api', {} as any);
  if (api.baseUrl) {
    openApiConfig.insertServer({
      url: api.baseUrl.match(/^https?:\/\//) ? api.baseUrl : location.origin + api.baseUrl,
    })
  }
  for (const [serverId, config] of Object.entries(api)) {
    if (config && typeof config === 'object') {
      const baseUrl = config.baseUrl;
      if (baseUrl) {
        const url = baseUrl.match(/^https?:\/\//) ? baseUrl : location.origin + baseUrl;
        console.log(`Add server '${ serverId }' with url '${ url }'`);
        openApiConfig.insertServer({
          url,
        }, undefined, serverId);
      }
    }
  }
}
