import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { HttpErrorMessageModule } from './http-error-message/http-error-message.component.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorMessageComponentModule } from './error-message/error-message.component.module';
import { ErrorDialogInterceptor } from './error-dialog.interceptor';
import { EurogardErrorHandler } from './eurogard-error.handler';
import { ConfigService } from '@rxap/config';
import { Environment, RXAP_ENVIRONMENT } from '@rxap/environment';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

export function ErrorHandlerFactory(environment: Environment | null, config: ConfigService) {
  return new EurogardErrorHandler(environment, config);
}

@NgModule({
  exports: [
    ErrorMessageComponentModule,
    HttpErrorMessageModule,
  ],
})
export class ErrorDialogModule {

  public static forRoot(): ModuleWithProviders<ErrorDialogModule> {
    return {
      ngModule: ErrorDialogModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorDialogInterceptor,
          multi: true,
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
        {
          provide: ErrorHandler,
          useFactory: ErrorHandlerFactory,
          deps: [ [ new Optional(), RXAP_ENVIRONMENT ], ConfigService ],
        },
      ],
    };
  }

}
