import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CellElement, RowElement, SegmentElement } from '@digitaix/eurogard-dashboard-xml-parser';
import { Required } from '@rxap/utilities';

@Component({
  selector: 'eurogard-segment',
  templateUrl: './segment.component.html',
  styleUrls: [ './segment.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {class: 'eurogard-segment'},
})
export class SegmentComponent implements OnChanges {

  @Input()
  @Required
  public segment!: SegmentElement;

  public columnWidth!: string;

  public get identifier(): string {
    return [ 'dashboard-render-engine', this.segment.__parent.id, this.segment.__parent.version, this.segment.__parent.segments.findIndex(segment => segment === this.segment) ].join('-');
  }

  private _expanded: boolean = true;

  public get expanded(): boolean {
    return this._expanded;
  }

  public set expanded(value: boolean) {
    this._expanded = value;
    localStorage.setItem(this.identifier, value ? 'true' : 'false');
  }

  public rowListTrackBy(index: number, item: RowElement) {
    return item.cellList.map(cell => cell.widgetId).join('_');
  }

  public cellListTrackBy(index: number, item: CellElement) {
    return item.widgetId;
  }

  public ngOnChanges(changes: SimpleChanges) {
    const segmentChange = changes.segment;
    if (segmentChange) {
      const segment: SegmentElement = segmentChange.currentValue;
      this.columnWidth = `${ (100 / segment.columns).toPrecision(4) }%`;
      const isExpandedCache = localStorage.getItem(this.identifier);
      if (isExpandedCache === 'false') {
        this._expanded = false;
      }
    }
  }

  public toggleExpand() {
    this.expanded = !this.expanded;
  }
}
