import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetMachineTypeDefinitionStatusResponse } from '../responses/options-controller-get-machine-type-definition-status.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getMachineTypeDefinitionStatus',
    operation: '{"operationId":"options-controller-getMachineTypeDefinitionStatus","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/machine-type-defintion-status"}'
  })
export class OptionsControllerGetMachineTypeDefinitionStatusRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetMachineTypeDefinitionStatusResponse, void, void> {
  public override call(): Promise<OptionsControllerGetMachineTypeDefinitionStatusResponse> {
    return super.call();
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethod'
  })
export class OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetMachineTypeDefinitionStatusResponse, OpenApiRemoteMethodParameter<void, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<void, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetMachineTypeDefinitionStatusRemoteMethod) remoteMethod: OptionsControllerGetMachineTypeDefinitionStatusRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetMachineTypeDefinitionStatusResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
    this.withoutParameters = true;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetMachineTypeDefinitionStatusRemoteMethod'
  })
export class OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetMachineTypeDefinitionStatusResponse, OpenApiRemoteMethodParameter<void, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetMachineTypeDefinitionStatusRemoteMethod) remoteMethod: OptionsControllerGetMachineTypeDefinitionStatusRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodDirective ],
    exports: [ OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodDirective ]
  })
export class OptionsControllerGetMachineTypeDefinitionStatusRemoteMethodDirectiveModule {
}
