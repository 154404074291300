import { ChangeDetectorRef, Directive, Inject, Injectable, INJECTOR, Injector, Input, NgModule, TemplateRef, ViewContainerRef } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { RemoteMethodLoader } from '@rxap/remote-method';
import { RemoteMethodDirective, RemoteMethodTemplateDirective, RemoteMethodTemplateDirectiveContext, RemoteMethodTemplateDirectiveErrorContext } from '@rxap/remote-method/directive';
import { OptionsControllerGetDataTypesForProtocolParameter } from '../parameters/options-controller-get-data-types-for-protocol.parameter';
import { OptionsControllerGetDataTypesForProtocolResponse } from '../responses/options-controller-get-data-types-for-protocol.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: undefined,
    operationId: 'options-controller-getDataTypesForProtocol',
    operation: '{"operationId":"options-controller-getDataTypesForProtocol","parameters":[{"name":"abc","in":"path","required":true,"schema":{"type":"integer","format":"int32"}}],"responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/options-controller/data-types/{abc}"}'
  })
export class OptionsControllerGetDataTypesForProtocolRemoteMethod extends OpenApiRemoteMethod<OptionsControllerGetDataTypesForProtocolResponse, OptionsControllerGetDataTypesForProtocolParameter, void> {
  public override call(parameters: OpenApiRemoteMethodParameter<OptionsControllerGetDataTypesForProtocolParameter, void>): Promise<OptionsControllerGetDataTypesForProtocolResponse> {
    return super.call(parameters);
  }
}

@Directive({
    selector: '[eurogardOptionsControllerGetDataTypesForProtocolRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDataTypesForProtocolRemoteMethod'
  })
export class OptionsControllerGetDataTypesForProtocolRemoteMethodTemplateDirective extends RemoteMethodTemplateDirective<OptionsControllerGetDataTypesForProtocolResponse, OpenApiRemoteMethodParameter<OptionsControllerGetDataTypesForProtocolParameter, void>> {
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDataTypesForProtocolRemoteMethodParameters')
  public override parameters?: OpenApiRemoteMethodParameter<OptionsControllerGetDataTypesForProtocolParameter, void>;
  // tslint:disable-next-line:no-input-rename
  @Input('eurogardOptionsControllerGetDataTypesForProtocolRemoteMethodError')
  public override errorTemplate?: TemplateRef<RemoteMethodTemplateDirectiveErrorContext>;

  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDataTypesForProtocolRemoteMethod) remoteMethod: OptionsControllerGetDataTypesForProtocolRemoteMethod, @Inject(TemplateRef) template: TemplateRef<RemoteMethodTemplateDirectiveContext<OptionsControllerGetDataTypesForProtocolResponse>>, @Inject(ViewContainerRef) viewContainerRef: ViewContainerRef, @Inject(ChangeDetectorRef) cdr: ChangeDetectorRef) {
    super(template, remoteMethodLoader, injector, viewContainerRef, cdr);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDataTypesForProtocolRemoteMethodTemplateDirective ],
    exports: [ OptionsControllerGetDataTypesForProtocolRemoteMethodTemplateDirective ]
  })
export class OptionsControllerGetDataTypesForProtocolRemoteMethodTemplateDirectiveModule {
}

@Directive({
    selector: '[eurogardOptionsControllerGetDataTypesForProtocolRemoteMethod]',
    exportAs: 'eurogardOptionsControllerGetDataTypesForProtocolRemoteMethod'
  })
export class OptionsControllerGetDataTypesForProtocolRemoteMethodDirective extends RemoteMethodDirective<OptionsControllerGetDataTypesForProtocolResponse, OpenApiRemoteMethodParameter<OptionsControllerGetDataTypesForProtocolParameter, void>> {
  constructor(@Inject(RemoteMethodLoader) remoteMethodLoader: RemoteMethodLoader, @Inject(INJECTOR) injector: Injector, @Inject(OptionsControllerGetDataTypesForProtocolRemoteMethod) remoteMethod: OptionsControllerGetDataTypesForProtocolRemoteMethod) {
    super(remoteMethodLoader, injector);
    this.remoteMethodOrIdOrToken = remoteMethod;
  }
}

@NgModule({
    declarations: [ OptionsControllerGetDataTypesForProtocolRemoteMethodDirective ],
    exports: [ OptionsControllerGetDataTypesForProtocolRemoteMethodDirective ]
  })
export class OptionsControllerGetDataTypesForProtocolRemoteMethodDirectiveModule {
}
