import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { LayoutElement } from '@digitaix/eurogard-dashboard-xml-parser';
import { GetTranslationByIdAndLangMethod } from '@de/shared/get-translation-by-id-and-lang.method';
import { DashboardTranslationItem } from './types';
import { IterateOverPropertyPaths } from '@de/shared/property-path.iterator';
import { SetToObject } from '@rxap/utilities';

@Injectable()
export class DashboardTranslationService {

  constructor(
    private readonly getTranslationByIdAndLangMethod: GetTranslationByIdAndLangMethod<DashboardTranslationItem>,
    @Inject(LOCALE_ID)
    private readonly localId: string,
  ) {
  }

  async translate(layout: LayoutElement, dashboardId: string, lang: string = this.localId) {

    const translation: DashboardTranslationItem | null = await this.getTranslationByIdAndLangMethod.call({ dashboardId, lang });

    if (!translation) {
      return;
    }

    layout.forEachCell(cell => {
      if (cell.widgetId && cell.widget?.content && translation.items) {
        const item = translation.items[cell.widgetId];
        if (item) {
          cell.widget.name = item.name ?? cell.widget.name;
          if (item.content) {
            for (const [ path, value ] of IterateOverPropertyPaths(item.content, true)) {
              if (value) {
                SetToObject(cell.widget.content, path, value);
              }
            }
          }
        }
      }
    });

    for (const [key,value] of Object.entries(translation.segments ?? {})) {
      layout.segments[Number(key)].title = value;
    }

  }

}
