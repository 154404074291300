import { Environment } from '@rxap/environment';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { ConfigService } from '@rxap/config';
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import { DetermineSentryEnvironment } from './determine-sentry-environment';
import { DetermineSentryRelease } from './determine-sentry-release';

export function SentryInit(dsn: string, environment: Environment) {
  Sentry.init({
    dsn,
    enabled: ConfigService.Get('sentry.enabled', environment['sentry'].enabled, ConfigService.Config),
    debug: ConfigService.Get('sentry.debug', environment['sentry'].debug, ConfigService.Config),
    environment: DetermineSentryEnvironment(environment),
    release: DetermineSentryRelease(environment),
    tunnel: ConfigService.Get('sentry.tunnel', '/tunnel', ConfigService.Config),
    initialScope: {
      user: {ip_address: '{{auto}}'},
      tags: {
        origin: location.origin,
        host: location.host,
      },
    },
    replaysSessionSampleRate: ConfigService.Get('sentry.replaysSessionSampleRate', 1.0, ConfigService.Config),
    replaysOnErrorSampleRate: ConfigService.Get('sentry.replaysOnErrorSampleRate', 1.0, ConfigService.Config),
    autoSessionTracking: ConfigService.Get('sentry.autoSessionTracking', true, ConfigService.Config),
    maxValueLength: ConfigService.Get('sentry.maxValueLength', Number.MAX_SAFE_INTEGER, ConfigService.Config),
    integrations: [
      new HttpClientIntegration({
        failedRequestTargets: [
          new RegExp(`^${location.origin}/backend`),
          new RegExp(`^${location.origin}/vpn`),
        ],
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
        tracePropagationTargets: [
          new RegExp(`^${location.origin}/api`),
          new RegExp(`^${location.origin}/backend`),
          new RegExp(`^${location.origin}/vpn`),
        ]
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],
    tracesSampleRate: ConfigService.Get('sentry.tracesSampleRate', 1.0, ConfigService.Config),
    ignoreErrors: [
      'Non-Error exception captured',
    ],
  });
}
