import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler as AngularErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  isDevMode,
  Optional,
} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Environment, RXAP_ENVIRONMENT } from '@rxap/environment';
import { ConfigService } from '@rxap/config';

export interface ErrorHandlerOptions {
  logErrors?: boolean;
  showDialog?: boolean;
  dialogOptions?: Sentry.ReportDialogOptions;
}

export const RXAP_ERROR_HANDLER_OPTIONS = new InjectionToken<ErrorHandlerOptions>('rxap-error-handler-options');

export interface HttpErrorResponseMap {
  url: string | null;
  ok: boolean;
  status: number;
  statusText: string;
  message: string;
  headers: Record<string, string | null | undefined>;
  error: unknown
}

@Injectable()
export class RxapErrorHandler implements AngularErrorHandler {

  protected readonly options: ErrorHandlerOptions

  constructor(
    @Optional()
    @Inject(RXAP_ERROR_HANDLER_OPTIONS)
      options: ErrorHandlerOptions | null = null,
  ) {
    this.options = options ?? {}
    this.options.logErrors ??= true;
    this.options.showDialog ??= false;
    this.options.dialogOptions ??= {};
  }

  /**
   * Method called for every value captured through the ErrorHandler
   */
  public handleError(errorCandidate: unknown): void {

    let error = errorCandidate;

    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    const extractedError = this.extractError(error);

    // Capture handled exception and send it to Sentry.
    const eventId = Sentry.captureException(extractedError, {
      level: 'error',
      contexts: this.extractContext(error),
      extra: this.extractExtra(error),
      tags: this.extractTags(error)
    });

    this.printError(error);

    if (this.options.showDialog) {
      if (!(error instanceof HttpErrorResponse)) {
        Sentry.showReportDialog({ ...(this.options.dialogOptions ?? {}), eventId });
      }
    }

  }

  protected printError(errorCandidate: unknown) {

    if (errorCandidate instanceof HttpErrorResponse) {
      console.groupCollapsed(errorCandidate.message);
      const { headers, error, ...map } = this.httpErrorResponseToMap(errorCandidate);
      if (typeof error === 'object' && (error as any)?.message) {
        console.log((error as any)?.message);
      }
      console.log(error);
      console.table(map);
      console.groupCollapsed('Headers');
      console.table(headers);
      console.groupEnd();
      console.groupEnd();
    } else if (errorCandidate instanceof Error) {
      console.error(errorCandidate);
    } else if (typeof errorCandidate === 'string') {
      console.error(errorCandidate);
    }

  }

  protected httpErrorResponseToMap(error: HttpErrorResponse): HttpErrorResponseMap {
    const headers = error.headers.keys().map(key => ({ [key]: error.headers.getAll(key)?.length ?? 0 > 1 ? error.headers.getAll(key)?.join(';') : error.headers.get(key) })).reduce((map, item) => ({ ...map, ...item }), {});
    return {
      url: error.url,
      ok: error.ok,
      status: error.status,
      statusText: error.statusText,
      message: error.message,
      headers,
      error: error.error,
    };
  }

  protected extractContext(error: unknown): Record<string, Record<string, unknown>> {

    const context: Record<string, Record<string, unknown>> = {}

    if (error instanceof HttpErrorResponse) {
      context['response'] = this.httpErrorResponseToMap(error) as any;
    }

    return context;
  }

  protected extractExtra(error: unknown): Record<string, unknown> {
    return {};
  }

  protected extractTags(error: unknown): Record<string, number | string | boolean | bigint | symbol | null | undefined> {
    return {};
  }

  protected extractError(error: unknown): Error | string {

    if (error instanceof HttpErrorResponse) {
      return error.message;
    }

    if (error instanceof Error) {
      return error;
    }

    if (typeof error === 'string') {
      return error;
    }

    return 'Handled unknown error';

  }


}

@Injectable()
export class EurogardErrorHandler extends RxapErrorHandler implements AngularErrorHandler {

  constructor(
    @Optional()
    @Inject(RXAP_ENVIRONMENT)
    private readonly environment: Environment | null,
    private readonly config: ConfigService,
  ) {
    super({
      showDialog: (environment ? environment.production : !isDevMode()) && config.get('sentry.showDialog', false),
      dialogOptions: {user: {}},
    });
  }

  public override handleError(error: unknown) {
    super.handleError(error);
    if (error instanceof HttpErrorResponse) {
      const match = error.error && error.error.message && error.error.message.match(/e_(\d+)#/);

      if (match) {
        const message = error.error.message;
        Sentry.captureException(message, {
          level: 'warning',
          contexts: this.extractContext(error),
          extra: this.extractExtra(error),
          tags: this.extractTags(error)
        });
      }
    }
  }

}
